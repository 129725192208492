import React, { ErrorInfo } from 'react'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

interface IState {
  hasError?: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends React.Component<any, IState> {
  state: IState
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error, errorInfo)
    /*LogRocket.captureException(error)
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })*/
    this.setState({ hasError: true, error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h3>Errore di sistema.</h3>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
