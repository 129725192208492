import React, { useState } from 'react'
import { Query } from '../'
import { GraphqlContext } from './context'
import { Cache } from '../types'

export const GraphqlProvider: React.FC<{}> = props => {
  let [cache, setCache] = useState<Cache<Partial<Query>>>({})
  let value = { cache, setCache }

  return <GraphqlContext.Provider value={value}>{props.children}</GraphqlContext.Provider>
}

export default GraphqlContext
