import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from 'antd'
import { Visit } from '../../../lib/graphql'
import { formatName } from '../../../components/format/Name/Name'

export interface ListItemTitleProps {
  visit?: Visit | null
}

export const ListItemTitle: React.FC<ListItemTitleProps> = observer(({ visit }) => {
  const formattedName = useMemo(() => formatName(visit?.visitor!), [visit?.visitor])

  return (
    <>
      <Typography.Title level={5} style={{ margin: 0, fontWeight: 'normal' }}>
        Numero: <b>{visit?.ticketCode}</b>
        {formattedName ? ` - ${formattedName}` : null}
      </Typography.Title>
    </>
  )
})
