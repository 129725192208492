import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { BgLayout } from '../../components/layout/BgLayout'
import logo from '../../assets/images/gaudia-logo.png'
import { useRequest } from '../../lib/graphql'
import { useInject } from '../../store'
import { observer } from 'mobx-react-lite'
import { Box } from '../../components/layout/Box'
import Loader from '../../components/Loader/Loader'
import { useFetchIdentity } from '../../components/useFetchIdentity'
import { Result } from 'antd'

const LogoWrapper = styled.div`
  margin-bottom: 50px;
  text-align: center;
  img {
    max-width: 300px;
  }
`

export const VisitorTicket = observer(() => {
  const { authStore } = useInject(({ authStore }) => ({ authStore }))
  const identityQuery = useFetchIdentity()

  const journeyForVisitorQuery = useRequest(
    {
      journeyForVisitor: {
        id: true,
        ticketCode: true,
        currentTicketCode: true,
        locationShortName: true,
      },
    },
    { enabled: authStore?.identity?.id, refetchInterval: 4000 },
  )

  const journeyForVisitor = useMemo(() => journeyForVisitorQuery.data?.journeyForVisitor, [
    journeyForVisitorQuery.data,
  ])

  return (
    <BgLayout>
      <Box>
        <LogoWrapper>
          <img src={logo} />
        </LogoWrapper>

        <Loader isLoading={identityQuery.isLoading || journeyForVisitorQuery.isLoading}>
          {!!(identityQuery?.data && journeyForVisitorQuery.data?.journeyForVisitor) && (
            <Box textAlign="center">
              <Box fontSize={['1.4em', '1.4em', '1.4em', '1.6em']}>Sei il numero</Box>

              <Box fontWeight="bold" fontSize={['2.8em', '2.8em', '2.8em', '3em']} mb={50}>
                {journeyForVisitor?.locationShortName} {journeyForVisitor?.ticketCode}
              </Box>

              {journeyForVisitor?.currentTicketCode && (
                <Box fontSize={['1em', '1em', '1em', '1.2em']}>
                  Attualmente il numero <b>{journeyForVisitor?.currentTicketCode}</b> è in visita
                </Box>
              )}
            </Box>
          )}

          {(identityQuery?.error || journeyForVisitorQuery.error) && (
            <Result
              status="404"
              title="Ci dispiace, il contenuto che cerchi non esiste o non è piú valido"
            />
          )}
        </Loader>
      </Box>
    </BgLayout>
  )
})

export default VisitorTicket
