import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import LogRocket from 'logrocket'
import { matchPath } from 'react-router'
import { setUser } from '@sentry/react'
// import * as Sentry from '@sentry/react'
import { httpClient } from '../../lib/httpClient'
import { useFetchIdentity } from '../../components/useFetchIdentity'

export const MeObserver = observer(() => {
  const identityQuery = useFetchIdentity()
  const history = useHistory()
  const urlParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  )

  const pathToken = useMemo(() => {
    const match = matchPath<{ id: string }>(history.location.pathname, { path: '/visitor/:id' })
    if (match) return match.params.id
  }, [history.location.pathname])
  const queryToken = useMemo(() => urlParams.get('token'), [urlParams])

  const mustRemove = useMemo(() => !pathToken && queryToken, [pathToken, queryToken])

  useEffect(() => {
    if (queryToken || pathToken) {
      httpClient.addMiddleware('pre', ({ headers }) => {
        headers.authorization = pathToken ?? queryToken
      })
      if (mustRemove) {
        urlParams.delete('token')
        const newSearchParams = urlParams.toString()
        history.replace({
          ...history.location,
          search: newSearchParams,
        })
      }
    }
  }, [history, urlParams, queryToken, mustRemove, pathToken])

  useEffect(() => {
    if (identityQuery.loading) return
    const identity = identityQuery.data?.identity

    let username = null
    let traits = {}

    if (identity?.__typename === 'User') {
      const fullName = `${identity.firstname} ${identity.lastname}`
      username = `${identity.email}-${identity.id}`

      traits = {
        name: fullName,
        email: identity.email,
      }
    }

    if (identity?.__typename === 'Monitor') {
      const name = identity.name
      username = `monitor-${identity.id}`

      traits = {
        name,
      }
    }

    if (identity?.__typename === 'CheckinKiosk') {
      const name = identity.name
      username = `checkinKiosk-${identity.id}`

      traits = {
        name,
      }
    }

    if (identity?.__typename === 'Visitor') {
      const fullName = `${identity.firstname} ${identity.lastname}`
      username = `visitor-${identity.id}`

      traits = {
        name: fullName,
      }
    }

    if (username) {
      LogRocket.identify(username, traits)
      if (identity?.id) {
        setUser({ id: identity?.id, username: username })
      }

      // @ts-ignore
      const gtag = window.gtag
      gtag('config', process.env.REACT_APP_GTAG, {
        user_id: username,
      })
    }
    // eslint-disable-next-line
  }, [identityQuery?.data])

  return null
})
