import React, { useEffect } from 'react'
import { Spin } from 'antd'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import kioskBg from '../../assets/images/kiosk-bg.svg'
import { useFetchIdentity } from '../../components/useFetchIdentity'
import { config } from '../../config'

export const Wrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${kioskBg});
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`

const Home: React.FC<{}> = observer(() => {
  const history = useHistory()
  const identityQuery = useFetchIdentity()

  useEffect(() => {
    if (identityQuery.loading) return

    if (identityQuery.data?.identity?.__typename === 'User') {
      history.push('/queues')
    }

    if (identityQuery.data?.identity?.__typename === 'Monitor') {
      const monitorId = identityQuery.data?.identity?.id
      window.location.href = `${config.signageBaseUrl}/monitor/${monitorId}`
    }

    if (identityQuery.data?.identity?.__typename === 'CheckinKiosk') {
      const kioskId = identityQuery.data?.identity?.id
      window.location.href = `${config.signageBaseUrl}/checkin-kiosk/${kioskId}`
    }
  }, [identityQuery, history])

  return (
    <Wrapper>
      <Spin size="large" />
    </Wrapper>
  )
})

export default Home
