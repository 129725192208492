import { Button, Col, DatePicker, Input, List, Modal, Row } from 'antd'
import React, { useCallback, useContext, useMemo } from 'react'
import * as yup from 'yup'
import { Box } from '../../components/layout/Box'
import moment from 'moment'
import { Form as FormikForm } from 'formik'
import { useMutation, useRequest, Booking, Visitor } from '../../lib/graphql'
import { FormActions } from '../../components/form/FormActions'
import { SubmitButton } from '../../components/form/SubmitButton'
import { useForm } from '../../components/form/useForm'
import { FormInput } from '../../components/form/FormInput'
import { LocationLabelsContext } from './LocationQueue/locationLabelsContext'
import { LocationSelect } from '../../components/form/LocationSelect/LocationSelect'

interface Props {
  locationId?: string
  visible: boolean
  onCancel: () => void
  onCreate: () => void
}

interface FormValues {
  visitor: Partial<Visitor>
  booking: Partial<Booking>
}

const validationSchema = yup
  .object<FormValues>({
    visitor: yup
      .object<FormValues['visitor']>({
        firstname: yup.string().required().min(2),
        lastname: yup.string().required().min(2),
        fiscalCode: yup.string(),
      })
      .required(),
    booking: yup
      .object<FormValues['booking']>({
        locationId: yup.string().required(),
        prescriptionCode: yup.string(),
        date: yup.date(),
      })
      .required(),
  })
  .required()

export const AddBookingModal: React.FC<Props> = (props) => {
  const { locationId, visible, onCreate, onCancel } = props
  const createVisitMutation = useMutation()
  const labels = useContext(LocationLabelsContext)

  const { Form } = useForm<FormValues>({ successText: 'Visita creata' })
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { visitor: visitorData, booking: bookingData } = values
      const { data } =
        (await createVisitMutation.mutate({
          addBooking: [{ bookingData, visitorData }, { id: true }],
        })) ?? {}

      await onCreate()
    },
    [createVisitMutation.mutate, onCreate],
  )

  const initialValues = useMemo<FormValues>(
    () => ({
      visitor: {
        firstname: '',
        lastname: '',
        fiscalCode: '',
      },
      booking: {
        locationId,
      },
    }),
    [locationId],
  )

  return (
    <Modal
      title={'Aggiungi visita'}
      destroyOnClose={true}
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        isInitialValid={false}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({ setFieldValue }) => (
          <FormikForm>
            <FormInput
              label={`Nome ${labels?.visitor?.name}`}
              name="visitor.firstname"
              required
              component={Input}
            />
            <FormInput
              label={`Cognome ${labels?.visitor?.name}`}
              name="visitor.lastname"
              required
              component={Input}
            />

            <FormInput
              label={`Location`}
              name="booking.locationId"
              required
              component={LocationSelect}
              componentProps={{ where: { queue: { hasQueue: { EQ: true } } } }}
            />

            <Row gutter={12}>
              <Col span={12}>
                <FormInput label={'Data'} name="booking.date" required>
                  {({ value }) => (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value) : null}
                      format="DD/MM/YYYY"
                      onChange={(momentDate) => setFieldValue('booking.date', momentDate?.toDate())}
                    />
                  )}
                </FormInput>
              </Col>
              <Col span={12}>
                <FormInput label={'Orario'} name="booking.time" required>
                  {({ value }) => (
                    <DatePicker.TimePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value, 'HH:mm') : null}
                      onChange={(momentTime, momentString) =>
                        setFieldValue('booking.time', moment(momentString, 'HH:mm').format('HH:mm'))
                      }
                    />
                  )}
                </FormInput>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={12}>
                <FormInput
                  label={`Codice Fiscale ${labels?.visitor?.name}`}
                  name="visitor.fiscalCode"
                  component={Input}
                />
              </Col>
              <Col span={12}>
                <FormInput
                  label={`Numero tessera sanitaria ${labels?.visitor?.name}`}
                  name="visitor.healthCardNumber"
                  component={Input}
                />
              </Col>
            </Row>

            <FormInput
              label={`Numero impegnativa medico`}
              name="booking.prescriptionCode"
              component={Input}
            />

            <FormActions>
              <Button size="large" onClick={() => onCancel()}>
                Chiudi
              </Button>
              <SubmitButton type="primary">Salva</SubmitButton>
            </FormActions>
          </FormikForm>
        )}
      </Form>
    </Modal>
  )
}

AddBookingModal.defaultProps = {
  onCancel: () => {},
  onCreate: () => {},
}
