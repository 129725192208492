import React, { CSSProperties, useMemo } from 'react'
import { Field, FieldProps, connect, getIn, FormikContext, FormikHandlers } from 'formik'
import { FormikContextType } from 'formik/dist/types'
import { Form as AntdForm } from '@ant-design/compatible'
import { InputProps } from 'antd/lib/input/Input'
import '@ant-design/compatible/assets/index.css'
import { useFormikContext } from 'formik'
import { SelectProps } from 'antd/lib/select'

interface ChildAsFunctionProps {
  onChange: FormikHandlers['handleChange']
  onBlur: FormikHandlers['handleBlur']
  value: any
  formik: FormikContextType<any>
}

interface Props {
  name: string
  label?: any
  labelCol?: object
  wrapperCol?: object
  help?: React.ReactNode
  component?: any
  children?: ((props: ChildAsFunctionProps) => React.ReactNode) | React.ReactNode
  componentProps?: InputProps & SelectProps<any> & Record<any, any>
  style?: CSSProperties
  required?: boolean
}

const defaultStyle = { marginBottom: 20 }

const FormInput: React.FC<Props> = React.memo((props) => {
  // @ts-ignore
  const {
    name,
    label,
    help,
    style,
    required,
    component: Component,
    children,
    labelCol,
    wrapperCol,
    componentProps,
  } = props

  const formik = useFormikContext()

  const error = getIn(formik.errors, name)
  const touched = getIn(formik.touched, name)
  const value = getIn(formik.values, name)
  const onChange = formik.handleChange(name)
  const onBlur = formik.handleBlur(name)

  const wrapperStyle = useMemo(() => ({ ...defaultStyle, ...style }), [style])
  const helpText = useMemo(() => getIn(formik.errors, name) ?? help, [formik.errors, help, name])

  return (
    <>
      <AntdForm.Item
        labelCol={{ ...labelCol, span: 24 }}
        label={label}
        help={helpText}
        style={wrapperStyle}
        wrapperCol={{ ...wrapperCol, span: 24 }}
        required={required}
      >
        {typeof children === 'function' ? (
          // @ts-ignore
          children({ onChange, onBlur, value, formik })
        ) : (
          <Component onChange={onChange} onBlur={onBlur} value={value} {...componentProps} />
        )}
      </AntdForm.Item>
    </>
  )
})

export default connect(FormInput)
