import React, { useCallback, useMemo } from 'react'
import {
  Formik,
  Form as FormikForm,
  FormikConfig,
  FormikHelpers,
  useFormik,
  FormikErrors,
  FormikValues,
} from 'formik'
import { notification } from 'antd'
import Promise from 'bluebird'
import merge from 'lodash/merge'

interface IProps {
  withSuccessNotification?: boolean
  withErrorNotification?: boolean
  successText?: string
  errorText?: string
}

const DEFAULT_PROPS = {
  withSuccessNotification: true,
  withErrorNotification: true,
  successText: 'Modifiche salvate con successo',
  errorText: 'Errore durante la richiesta',
}

function useForm<T extends FormikValues>(props?: IProps) {
  const formProps = useMemo<IProps>(() => merge({}, DEFAULT_PROPS, props), [props])

  return useMemo(() => {
    const Form: React.FC<FormikConfig<T>> = (props) => {
      const { children, initialValues, onSubmit } = props

      const handleSubmit = useCallback(
        async (values: T, actions: FormikHelpers<T>) => {
          actions.setSubmitting(true)
          try {
            const [result] = await Promise.all([onSubmit(values, actions), Promise.delay(500)])
            if (formProps.withSuccessNotification) {
              notification.success({ message: formProps.successText })
            }
            return result
          } catch (err) {
            actions.setErrors(err as FormikErrors<any>)
            if (formProps.withErrorNotification) {
              notification.error({ message: formProps.errorText })
            }
            throw err
          } finally {
            actions.setSubmitting(false)
          }
        },
        [onSubmit],
      )

      const Children = useMemo(() => {
        if (typeof children === 'function') {
          return children
        }

        return () => <FormikForm>{children}</FormikForm>
      }, [children])

      return (
        <Formik {...props} initialValues={initialValues} onSubmit={handleSubmit}>
          {Children}
        </Formik>
      )
    }

    return { Form }
  }, [
    formProps.errorText,
    formProps.successText,
    formProps.withErrorNotification,
    formProps.withSuccessNotification,
  ])
}

export default useForm
