import React, { FocusEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Spin } from 'antd'
import { SelectValue, SelectProps } from 'antd/lib/select'
import { Select, CustomSelectProps, useGetLabelField, useGetValueField } from '../Select/Select'

export interface AsyncSelectProps<T> extends CustomSelectProps<T> {
  forceRefetch?: false
  fetchMethod: Function
  initialQuery?: any
  onFocus?: FocusEventHandler
  mode?: SelectProps<{}>['mode']
  allowClear?: boolean
}

export function AsyncSelect<ValueType extends SelectValue = SelectValue>(
  props: AsyncSelectProps<ValueType>,
) {
  const {
    labelField,
    valueField,
    options,
    value,
    fetchMethod,
    initialQuery,
    forceRefetch,
    onFocus,
    mode,
  } = props

  const [loaded, setLoaded] = useState(false)
  const [initialLoaded, setInitialLoaded] = useState(false)
  const loadingOptions = useMemo(
    () => [
      {
        label: (
          <span>
            <Spin /> Caricamento...
          </span>
        ),
        value: String(Math.random()),
        disabled: true,
      },
    ],
    [],
  )

  const getLabelField = useGetLabelField(labelField, [labelField])
  const getValueField = useGetValueField(valueField, [valueField])

  const formattedOptions = useMemo<
    { label?: React.ReactNode | string; value: string | number }[]
  >(() => {
    let formattedOptions =
      options?.map((option) => ({
        label: getLabelField(option),
        value: getValueField(option),
      })) || []
    if (!loaded) {
      formattedOptions = [...loadingOptions, ...formattedOptions]
    }

    return formattedOptions
  }, [options, loaded])

  const fetchInitialData = useCallback(async () => {
    if (!value) return
    await setInitialLoaded(true)
    const val = Array.isArray(props.value) ? props.value : [props.value]
    if (_.isEmpty(val)) {
      return await setInitialLoaded(false)
    }

    const query = _.merge({}, initialQuery, { [valueField as string]: { IN: val } })

    try {
      return await fetchMethod(query)
    } finally {
      await setInitialLoaded(false)
    }
  }, [props.value])

  const fetchData = useCallback(async () => {
    if (loaded && !forceRefetch) return
    const query = _.merge({}, initialQuery, {})

    await fetchMethod(query)
    return setLoaded(true)
  }, [])

  const handleFocus = useCallback((e) => {
    if (onFocus) onFocus(e)
    return fetchData()
  }, [])

  useEffect(() => {
    fetchInitialData()
  }, [props.value])

  return (
    <>
      <Spin spinning={initialLoaded}>
        <Select
          {...props}
          options={formattedOptions}
          labelField="label"
          valueField="value"
          onFocus={handleFocus}
        />
      </Spin>
    </>
  )
}

AsyncSelect.defaultProps = {
  labelField: 'label',
  valueField: 'value',
  onFocus: () => {},
  forceRefetch: false,
}
