import React, { memo, useCallback, useContext, useMemo } from 'react'
import { Button, Input, List, Modal, notification, Radio } from 'antd'
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled'
import _ from 'lodash'
import { useForm } from '../../../components/form/useForm'
import { Form as FormikForm } from 'formik'
import { useMutation, Visit, Visitor } from '../../../lib/graphql'
import { Name } from '../../../components/format/Name'
import { Loader } from '../../../components/Loader/Loader'
import { FormActions } from '../../../components/form/FormActions'
import { SubmitButton } from '../../../components/form/SubmitButton'
import { FormInput } from '../../../components/form/FormInput'
import { Box } from '../../../components/layout/Box'
import { LocationLabelsContext } from './locationLabelsContext'
import { formatName } from '../../../components/format/Name/Name'
import upperFirst from 'lodash/upperFirst'

interface Props {
  visible?: boolean
  visit?: Visit | null
  currentStatus?: MoveTo | 'ended'
  onCancel: () => void
  onSuccess: () => void
}

export type MoveTo = 'waiting' | 'started'

interface FormProps {
  locationId?: string | null
  moveTo?: MoveTo | null
  note?: string | null
}

const moveOptionsPriority = {
  waiting: 0,
  started: 1,
  ended: 2,
}

interface MoveOption {
  label: string
  value: MoveTo
}

export const AmendVisitModal: React.FC<Props> = memo((props) => {
  const labels = useContext(LocationLabelsContext)
  const { visit, currentStatus, onSuccess, onCancel, visible } = props

  const moveOptions = useMemo(
    () =>
      currentStatus
        ? (
            [
              { label: 'Sposta in coda', value: 'waiting' },
              { label: 'Sposta in visita', value: 'started' },
            ] as MoveOption[]
          ).filter((opt) => moveOptionsPriority[opt.value] < moveOptionsPriority[currentStatus])
        : [],
    [currentStatus],
  )

  const { Form } = useForm<FormProps>({ withSuccessNotification: false })

  const amendVisitMutation = useMutation()
  const handleSubmit = useCallback(
    async ({ moveTo, note }: FormProps) => {
      if (!visit?.id || !moveTo) return

      await amendVisitMutation.mutate({
        amendVisit: [
          {
            data: {
              visitId: visit.id,
              moveTo,
              note,
            },
          },
          { ticketCode: true, location: { name: true } },
        ],
      })

      notification.success({ message: `${_.upperFirst(labels?.visitor?.name ?? '')} spostato` })
      return onSuccess()
    },
    [labels, amendVisitMutation.mutate, onSuccess, visit],
  )

  return (
    <Modal
      title={`Cambia stato ${labels?.visitor?.name}`}
      footer={null}
      destroyOnClose={true}
      visible={visible}
      onCancel={() => onCancel()}
    >
      <>
        <p>
          {formatName(visit?.visitor!) && (
            <div>
              <span style={{ textTransform: 'capitalize' }}>{labels?.visitor?.name}</span>:{' '}
              {upperFirst(labels?.visitor?.name || '')}:{' '}
              <b>
                <Name person={visit?.visitor as Visitor} />
              </b>
            </div>
          )}
          <div>
            Numero: <b>{visit?.ticketCode}</b>
          </div>
        </p>
        <Form
          initialValues={{ locationId: null, moveTo: null, note: null }}
          onSubmit={handleSubmit}
        >
          {(formikBag) => (
            <FormikForm>
              <Loader>
                <List
                  dataSource={moveOptions}
                  renderItem={({ label, value }) => (
                    <List.Item
                      actions={[
                        <Radio.Group
                          defaultValue={null}
                          size="small"
                          value={formikBag.values.locationId}
                        >
                          <Radio.Button
                            value={value}
                            onClick={() => formikBag.setValues({ moveTo: value })}
                          >
                            {value === formikBag.values.moveTo ? (
                              <>
                                <CheckCircleFilled /> Selezionato
                              </>
                            ) : (
                              'Seleziona'
                            )}
                          </Radio.Button>
                        </Radio.Group>,
                      ]}
                    >
                      <List.Item.Meta title={label} />
                    </List.Item>
                  )}
                />

                <Box mt={3}>
                  <FormInput name="note" component={Input.TextArea} label="Nota (opzionale)" />
                </Box>

                <FormActions textAlign="right">
                  <Button onClick={() => onCancel()}>Annulla</Button>
                  <SubmitButton size="middle" disabled={!formikBag.values.moveTo} />
                </FormActions>
              </Loader>
            </FormikForm>
          )}
        </Form>
      </>
    </Modal>
  )
})
