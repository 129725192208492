import React, { useCallback, useMemo, DependencyList, ReactText } from 'react'
import { Select as AntdSelect } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { useMobileDetect } from '../../layout/useDeviceDetector'

const Option = AntdSelect.Option

export interface CustomSelectProps<ValueType> extends SelectProps<ValueType> {
  labelField: string | ((option: unknown) => string)
  valueField: string | ((option: unknown) => string)
  optionFilterProp?: string
  mobileModal?: boolean
  mode?: 'multiple' | 'tags'
}

export const useGetLabelField = (labelField: Function | string, deps: DependencyList = []) =>
  useCallback((option) => {
    if (typeof labelField === 'function') return labelField(option)

    return option[labelField]
  }, deps)

export const useGetValueField = (valueField: Function | string, deps: DependencyList = []) =>
  useCallback((option) => {
    if (typeof valueField === 'function') return valueField(option)

    return option[valueField]
  }, deps)

export function Select<ValueType extends SelectValue = SelectValue>(
  props: CustomSelectProps<ValueType>,
) {
  const {
    labelField,
    valueField,
    options,
    children,
    mode,
    mobileModal,
    /*modal,*/
    value,
    ...rest
  } = props
  const { isMobile } = useMobileDetect()

  const getLabelField = useGetLabelField(labelField, [labelField])

  const getValueField = useGetValueField(valueField, [valueField])

  const formattedOptions = useMemo<{ label?: React.ReactNode | string; value: ReactText }[]>(
    () =>
      options?.map((option) => ({
        label: getLabelField(option),
        value: getValueField(option),
      })) || [],
    [options],
  )

  const isMultiple = useMemo(() => mode && ['multiple', 'tags'].includes(mode), [mode])

  const handleClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target.className === 'ant-select-item-option-content') {
      //  @ts-ignore
      setTimeout(() => document.querySelector?.('.ant-select-selection-search-input')?.blur())
    }
  }, [])

  //  @ts-ignore
  const handleChange = useCallback((...args) => {
    //  @ts-ignore
    document.querySelector?.('.ant-select-selection-search-input')?.blur()
    if (rest.onChange) {
      //  @ts-ignore
      rest.onChange(...args)
    }
  }, [])

  return (
    <span onClick={handleClick}>
      <
        // @ts-ignore
      AntdSelect<SelectValue>
        {...rest}
        onChange={handleChange}
        value={value}
        mode={mode}
      >
        {children ||
          (formattedOptions || []).map((option) => (
            <Option {...option} value={option.value} key={option.value}>
              {option.label}
            </Option>
          ))}
      </AntdSelect>
    </span>
  )
}

Select.defaultProps = {
  labelField: 'label',
  valueField: 'value',
  optionFilterProp: 'children',
  mobileModal: true,
  showSearch: true,
  filterOption: (input: string, option: any) =>
    option.props.children &&
    option.props.children.toLowerCase &&
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}
