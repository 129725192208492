/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useMemo } from 'react'
import { Button } from 'antd'
import { ReloadOutlined, CloseOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { Wrapper } from './VersionNotification.styled'
import { VersionNotificationStoreModel, useInject } from '../../store'

const ROUTES_BLACKLIST = [/checkin-kiosk\/(.){24}/, /monitor\/view\/(.){24}/]

export const VersionNotification: React.FC = observer(() => {
  const { hasUpdate, setHasUpdate, updateUI } = useInject<VersionNotificationStoreModel>(
    ({ versionNotificationStore }) => versionNotificationStore,
  )
  const location = useLocation()

  const isBlackListed = useMemo(() => !!ROUTES_BLACKLIST.find((r) => r.test(location.pathname)), [
    location.pathname,
  ])

  if (!hasUpdate || isBlackListed) return null

  return (
    // @ts-ignore
    <Wrapper>
      <div className="version-notification">
        <div className="version-notification__iconText">
          <div className="version-notification__iconText__icon">
            <ReloadOutlined />
          </div>
          <p>
            E' stata rilasciata una nuova versione di Gaudia. Per favore clicca 'aggiorna' per
            utilizzare la versione aggiornata
          </p>
        </div>
        <div className="version-notification__actions">
          <Button type="primary" onClick={() => updateUI()}>
            Aggiorna
          </Button>
          <Button
            className="version-notification__actions__close"
            icon={<CloseOutlined />}
            onClick={() => setHasUpdate(false)}
          />
        </div>
      </div>
    </Wrapper>
  )
})
