/* eslint-disable no-console */

import { register } from 'register-service-worker'
import UpdateChecker from './UpdateChecker'

export default function registerServiceWorker() {
  console.info('calling registerServiceWorker')
  const pollingInterval = 60 * 10 * 1000
  const updateChecker = new UpdateChecker({ pollingInterval })

  const started = new Date().getTime()

  register(`${process.env.PUBLIC_URL}/service-worker.js`, {
    ready(registration) {
      console.log(
        `App is being served from cache by a service worker. \n For more details, visit https://goo.gl/AFskqB
				`,
      )

      updateChecker.setSwRegistration(registration)
      updateChecker.setupPolling()
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      updateChecker.setSwRegistration(registration)
      updateChecker.setupPolling()
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })

      // let's refresh straight away
      if (new Date().getTime() - started < 10000) {
        console.log('lets refresh straight away')
        window.location.reload()
        return
      }

      // show an update notification
      console.log('should notify')
      updateChecker.setHasUpdate(true)
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    async error(error) {
      console.error('Error during service worker registration:', error)
      // setup legacy polling
      updateChecker.setupPolling()
      await updateChecker.performLegacyCheck()
    },
  })
}
