import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card as AntdCard, Checkbox, Col, Form, Row, Typography } from 'antd'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { BranchesOutlined, CalendarOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import DefaultLayout from '../../components/layout/DefaultLayout'
import { Location, Policy, QueueCounts, useRequest } from '../../lib/graphql'
import { LocationSelect } from '../../components/form/LocationSelect/LocationSelect'
import { LocationAvatar } from '../../components/location/LocationAvatar'
import { useModal } from '../../components/modals/useModal'
import { Loader } from '../../components/Loader/Loader'
import { useDateInterval } from '../../components/useDateInterval'
import { useUserHasPolicy } from '../../components/role/userHasPolicy'
import { Action, QueueActions } from '@gaudia/shared'
import { useAblyEvent } from '../../components/ably/ably'
import { Box } from '../../components/layout/Box'
import { usePreference, useUpdatePreference } from '../../components/preferencesHooks'

const Card = styled(AntdCard)`
  & .ant-card-body {
    display: flex;
    padding: 0;
  }
`

const CardGrid = styled(AntdCard.Grid)`
  flex-grow: 1;
  padding: 10px;
  text-align: center;

  b {
    font-size: 30px;
  }
`

const CardCol = styled(Col)`
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    max-width: 50%;
    width: 50%;
  }

  @media (min-width: 992px) {
    max-width: 50%;
    width: 50%;
  }

  @media (min-width: 1200px) {
    max-width: 33%;
    width: 33%;
  }

  @media (min-width: 2000px) {
    max-width: 25%;
    width: 25%;
  }
`

const QueueCountCard: React.FC<{
  locationIdQueueCount: QueueCounts
  verify: (policyAction: Action | Action[], resourceId?: string | null) => Policy | null | undefined
}> = ({ locationIdQueueCount, verify }) => {
  return (
    <CardCol>
      <Card
        title={
          <>
            {locationIdQueueCount?.location && (
              <LocationAvatar location={locationIdQueueCount?.location} showLabel={true} />
            )}{' '}
            {locationIdQueueCount?.location?.name}
          </>
        }
        actions={[
          verify([QueueActions.ViewQueues], locationIdQueueCount?.location?._id) ? (
            <Link to={`/queues/agenda/${locationIdQueueCount?.location?._id}`}>
              <Button icon={<CalendarOutlined />} type="text">
                Agenda
              </Button>
            </Link>
          ) : (
            <Button type="text" style={{ visibility: 'hidden' }} />
          ),
          verify([QueueActions.ManageQueues], locationIdQueueCount?.location?._id) ? (
            <Link to={`/queues/manage/${locationIdQueueCount?.location?._id}`}>
              <Button icon={<BranchesOutlined />} type="text">
                Gestisci
              </Button>
            </Link>
          ) : (
            <Button type="text" style={{ visibility: 'hidden' }} />
          ),
        ]}
      >
        <CardGrid hoverable={false} style={{ width: '25%' }}>
          <div>
            <Typography.Paragraph ellipsis style={{ margin: 0 }}>
              In Coda
            </Typography.Paragraph>
          </div>
          <b>{locationIdQueueCount.counts?.waiting}</b>
        </CardGrid>
        <CardGrid hoverable={false} style={{ width: '25%' }}>
          <div>
            <Typography.Paragraph ellipsis style={{ margin: 0 }}>
              In Visita
            </Typography.Paragraph>
          </div>
          <b>{locationIdQueueCount.counts?.started}</b>
        </CardGrid>
        <CardGrid hoverable={false} style={{ width: '25%' }}>
          <div>
            <Typography.Paragraph ellipsis style={{ margin: 0 }}>
              Completati
            </Typography.Paragraph>
          </div>
          <b>{locationIdQueueCount.counts?.ended}</b>
        </CardGrid>
        <CardGrid hoverable={false} style={{ width: '25%' }}>
          <div>
            <Typography.Paragraph ellipsis style={{ margin: 0 }}>
              Previsti
            </Typography.Paragraph>
          </div>
          <b>{locationIdQueueCount.counts?.expected}</b>
        </CardGrid>
      </Card>
    </CardCol>
  )
}

const LocationsQueuesSummary = observer(() => {
  const { locationId } = useParams<{ locationId?: string }>()
  const history = useHistory()
  const uiPreference = usePreference('locationsQueuesSummary')
  const uiPreferenceFields = uiPreference?.data?.fields
  const { startDate, endDate } = useDateInterval()

  useEffect(() => {
    if (uiPreference.isLoading) return
    history.push(`/queues/summary/${uiPreferenceFields?.selectedLocationId ?? ''}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, uiPreferenceFields?.selectedLocationId, locationId])

  const [refetchInterval, setRefetchInterval] = useState<number | false>(5000)
  const {
    data: queueCountsData,
    isFetchingInitial: isFetchingQueueCounts,
    refetch,
  } = useRequest(
    {
      queueCounts: [
        _.merge(
          { includeChildren: true },
          locationId && { where: { locationId } },
          startDate && { where: { startDate } },
          endDate && { where: { endDate } },
        ),
        {
          location: {
            id: true,
            _id: true,
            name: true,
            shortName: true,
            color: true,
            parentLocationId: true,
            parentLocation: {
              id: true,
              name: true,
            },
          },
          counts: { started: true, waiting: true, ended: true, expected: true },
        },
      ],
    },
    { refetchInterval },
  )

  const throttledRefetch = useCallback(
    _.throttle(() => refetch(), 5000),
    [],
  )

  const ably = useAblyEvent(
    // @ts-ignore
    queueCountsData?.queueCounts?.map(({ location }) => ({
      channelName: `locationQueue:${location?._id}`,
    })),
    throttledRefetch,
  )

  useAblyEvent(
    // @ts-ignore
    queueCountsData?.queueCounts?.map(({ location }) => ({
      channelName: `locationQueue:${location?._id}`,
      eventName: 'refresh',
    })),
    throttledRefetch,
  )

  useEffect(() => {
    setRefetchInterval(ably.isConnected ? false : 5000)
  }, [ably.isConnected])

  const updatePreferenceMutation = useUpdatePreference('locationsQueuesSummary')

  const handleLocationSelect = useCallback(
    (locationId) =>
      updatePreferenceMutation(
        {
          fields: { selectedLocationId: locationId ?? null },
        },
        { isDeltaUpdate: true },
      ),
    [updatePreferenceMutation],
  )

  const handleGroupByChange = useCallback(
    (enabled) =>
      updatePreferenceMutation({ fields: { groupByLocation: enabled } }, { isDeltaUpdate: true }),
    [uiPreferenceFields, updatePreferenceMutation],
  )

  const [
    isLocationQueueDetailModalOpen,
    setLocationQueueDetailModal,
    toggleLocationQueueDetailModal,
  ] = useModal()

  const [detailLocationId, setDetailLocationId] = useState<string | null>()
  const detailLocationRequest = useRequest(
    !!detailLocationId && {
      locationById: [
        { id: detailLocationId },
        {
          name: true,
          shortName: true,
          color: true,
          locationType: { appearance: { visitor: { name: true, pluralName: true } } },
        },
      ],
    },
    { enabled: !!detailLocationId },
  )

  const { verify } = useUserHasPolicy()

  const locationGroups = useMemo<{ group: Location; items: QueueCounts[] }[]>(() => {
    if (!uiPreferenceFields?.groupByLocation) return []

    const parentLocations: Location[] = _.uniqBy(
      _.map(queueCountsData?.queueCounts, 'location.parentLocation'),
      'id',
    )

    const parentLocationsHash = _.keyBy(parentLocations, 'id')

    const groups = _.groupBy<QueueCounts>(
      queueCountsData?.queueCounts as QueueCounts[],
      'location.parentLocationId',
    )

    return _.sortBy<{ group: Location; items: QueueCounts[] }>(
      _.map<Record<string, QueueCounts[]>, { group: Location; items: QueueCounts[] }>(
        groups,
        (locations, locationId) => {
          return {
            group: parentLocationsHash[locationId] ?? { name: 'Sconosciuto' },
            items: locations,
          }
        },
      ),
      '0.name',
    )
  }, [queueCountsData?.queueCounts, uiPreferenceFields?.groupByLocation])

  return (
    <DefaultLayout>
      <Box p={[12, 0]}>
        <Loader isLoading={isFetchingQueueCounts || uiPreference.isFetchingInitial}>
          <Form layout="inline">
            <Form.Item label="Location" style={{ marginBottom: 20 }}>
              <LocationSelect
                style={{ width: 200 }}
                value={locationId}
                onChange={handleLocationSelect}
                allowClear
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: 20 }}>
              <Checkbox
                checked={!!uiPreferenceFields?.groupByLocation}
                onClick={() => handleGroupByChange(!uiPreferenceFields?.groupByLocation)}
              >
                Raggruppa
              </Checkbox>
            </Form.Item>
          </Form>

          {uiPreferenceFields?.groupByLocation && (
            <>
              {locationGroups.map(({ group, items }) => (
                <Row gutter={20} style={{ marginBottom: 20 }}>
                  <Col span={24}>
                    <h3>{group.name}</h3>
                  </Col>
                  {items?.map((locationIdQueueCount) => {
                    return (
                      locationIdQueueCount && (
                        <QueueCountCard
                          locationIdQueueCount={locationIdQueueCount}
                          verify={verify}
                        />
                      )
                    )
                  })}
                </Row>
              ))}
            </>
          )}

          {!uiPreferenceFields?.groupByLocation && (
            <>
              <Row gutter={20}>
                {queueCountsData?.queueCounts?.map((locationIdQueueCount) => {
                  return (
                    locationIdQueueCount && (
                      <QueueCountCard locationIdQueueCount={locationIdQueueCount} verify={verify} />
                    )
                  )
                })}
              </Row>
            </>
          )}
        </Loader>
      </Box>
    </DefaultLayout>
  )
})

export default LocationsQueuesSummary
