import React, { ReactNode, useMemo } from 'react'
import styled from '@emotion/styled'
import { Button, Col, Divider, Dropdown, Menu, Row } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { Visit } from '../../../lib/graphql'
import { Box } from '../../../components/layout/Box'

export interface ListItemProps {
  visit?: Visit
  beforeTitle?: ReactNode
  afterTitle?: ReactNode
  title?: ReactNode
  content?: ReactNode
  actions?: ListItemActionsAction[]
  style?: React.CSSProperties
}

export interface ListItemActionsAction {
  hidden?: boolean
  main?: boolean
  text: ReactNode
  onClick?: Function
}

interface ListItemActionsProps {
  actions: ListItemActionsAction[]
}

const Wrapper = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 15px',
  flexWrap: 'wrap',
  minHeight: '65px',
  '@media (min-width: 1200px)': {
    flexWrap: 'nowrap',
  },
})

const TitleWrapper = styled.div({
  flexGrow: 1,
  marginRight: '3px',
})

const ContentWrapper = styled.div()
const ActionsWrapper = styled.div({
  marginLeft: '0',
  width: '100%',
  textAlign: 'center',
  marginTop: 10,
  whiteSpace: 'nowrap',
  '@media (min-width: 1200px)': {
    width: 'auto',
    marginLeft: 15,
    marginTop: 0,
  },
})

export const ListItemActions: React.FC<ListItemActionsProps> = ({ actions }) => {
  const visibleActions = useMemo(() => actions?.filter(({ main }) => main), [actions])
  const dropdownActions = useMemo(() => actions?.filter(({ main }) => !main), [actions])

  return (
    <Row align="top" justify="end" style={{ flexWrap: 'nowrap' }}>
      {!!dropdownActions?.length && (
        <Col>
          <Dropdown
            overlay={
              <Menu>
                {dropdownActions?.map((action) => (
                  <Menu.Item onClick={() => action?.onClick?.()}>{action.text}</Menu.Item>
                ))}
              </Menu>
            }
            trigger={['click']}
          >
            <Button size={'middle'} icon={<EllipsisOutlined />} />
          </Dropdown>
        </Col>
      )}

      {!!visibleActions?.length && !!dropdownActions?.length && (
        <Col>
          <Divider type="vertical" />
        </Col>
      )}

      {visibleActions?.map((action) => (
        <Col>
          <Button
            size={'middle'}
            // type={action.main ? 'primary' : 'default'}
            block
            onClick={() => action?.onClick?.()}
          >
            {action.text}
          </Button>
        </Col>
      ))}
    </Row>
  )
}

export const ListItem: React.FC<ListItemProps> = ({
  beforeTitle,
  afterTitle,
  title,
  content,
  actions,
  style,
}) => {
  return (
    <Wrapper style={style}>
      <Box width="100%">
        <TitleWrapper>
          <Box display="flex" alignItems="center">
            {beforeTitle}
            {title}
            {/*<h4 className="ant-list-item-meta-title">{title}</h4>*/}
          </Box>

          {afterTitle}
        </TitleWrapper>

        <ContentWrapper>{content}</ContentWrapper>
      </Box>

      {!!actions?.length && (
        <ActionsWrapper>
          <ListItemActions actions={actions} />
        </ActionsWrapper>
      )}
    </Wrapper>
  )
}
