import React from 'react'
import { Avatar } from 'antd'
import { AvatarProps } from 'antd/lib/avatar'
import styled from '@emotion/styled'
import { Location } from '../../lib/graphql'

interface MyAvatarProps extends AvatarProps {
  color?: string | null
}

const MyAvatar = styled(Avatar)<MyAvatarProps>`
  background-color: ${(props: MyAvatarProps) => props.color};
  .ant-avatar-string {
    font-size: ${(props: MyAvatarProps) => props.size || 30}px;
    font-weight: bold;
  }
`

export const LocationAvatar: React.FC<{
  location: Location
  size?: number | 'small' | 'large' | 'default' | undefined
  showLabel?: boolean
}> = ({ location, size, showLabel }) => {
  return (
    <MyAvatar shape="square" size={size} color={location.color}>
      {showLabel ? location.shortName : null}
    </MyAvatar>
  )
}

LocationAvatar.defaultProps = {
  showLabel: true,
}
