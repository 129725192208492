import React, { CSSProperties, useState } from 'react'
import { SelectProps, SelectValue } from 'antd/lib/select'
import _ from 'lodash'
import { AsyncSelect } from '../AsyncSelect/AsyncSelect'
import { LocationFilterQueryInput, useRequest } from '../../../lib/graphql'

interface Props {
  value: any
  onChange: (value: SelectValue) => void
  style?: CSSProperties
  mode?: SelectProps<{}>['mode']
  allowClear?: boolean
  where?: LocationFilterQueryInput
}

export const LocationSelect: React.FC<Props> = ({ where: initialWhere, ...props }) => {
  const [where, setWhere] = useState<LocationFilterQueryInput>()
  const query = useRequest({
    locations: [{ where: _.merge({}, initialWhere, where) }, { items: { _id: true, name: true } }],
  })

  const fetchMethod = (where: LocationFilterQueryInput) => {
    setWhere(where)
  }

  return (
    <>
      <AsyncSelect
        fetchMethod={fetchMethod}
        // @ts-ignore
        options={query?.data?.locations?.items}
        valueField="_id"
        labelField="name"
        {...props}
      />
    </>
  )
}
