import { useMutation, Visit, Visitor } from '../../../lib/graphql'
import React, { useCallback, useContext } from 'react'
import { List, Modal, notification } from 'antd'
import upperFirst from 'lodash/upperFirst'
import { Name } from '../../../components/format/Name'
import { LocationLabelsContext } from './locationLabelsContext'
import { formatName } from '../../../components/format/Name/Name'
import Bluebird from 'bluebird'

export const useCommonActions = () => {
  const labels = useContext(LocationLabelsContext)

  const endVisitMutation = useMutation()
  const handleEndVisit = useCallback(
    (visits: Visit[], onComplete?: () => void) => {
      const isMulti = visits?.length > 1
      return new Promise<void>((resolve, reject) =>
        Modal.confirm({
          title: `Sei sicuro di voler terminare ${isMulti ? 'le visite' : 'la visita'}?`,
          content: (
            <>
              <List
                dataSource={visits || []}
                renderItem={(visit) => (
                  <List.Item>
                    {formatName(visit?.visitor) && (
                      <div>
                        {upperFirst(labels?.visitor?.name || '')}:{' '}
                        <b>
                          <Name person={visit?.visitor as Visitor} />
                        </b>
                      </div>
                    )}

                    <div>
                      Numero: <b>{visit.ticketCode}</b>
                    </div>
                  </List.Item>
                )}
              />
            </>
          ),
          async onOk() {
            await endVisitMutation.mutate({
              endVisit: [
                {
                  data: {
                    visitIds: visits?.map((v) => v.id),
                    endedReason: isMulti ? 'bulkComplete' : null,
                  },
                },
                { id: true, endedAt: true },
              ],
            })

            notification.success({ message: isMulti ? 'Visite terminate' : 'Visita terminata' })

            onComplete?.()

            resolve()
          },
          async onCancel() {
            reject()
          },
        }),
      )
    },
    [endVisitMutation, labels?.visitor?.name],
  )

  const deleteVisitByIdMutation = useMutation()
  const handleDeleteVisitById = useCallback(
    (visits: Visit[], onComplete?: () => void) => {
      const isMulti = visits?.length > 1
      return new Promise<void>((resolve, reject) =>
        Modal.confirm({
          title: `Sei sicuro di voler eliminare ${isMulti ? 'le visite' : 'la visita'}?`,
          content: (
            <>
              <List
                dataSource={visits || []}
                renderItem={(visit) => (
                  <List.Item>
                    {formatName(visit?.visitor) && (
                      <div>
                        {upperFirst(labels?.visitor?.name || '')}:{' '}
                        <b>
                          <Name person={visit?.visitor as Visitor} />
                        </b>
                      </div>
                    )}

                    <div>
                      Numero: <b>{visit.ticketCode}</b>
                    </div>
                  </List.Item>
                )}
              />
            </>
          ),
          async onOk() {
            await Bluebird.mapSeries(visits ?? [], (visit) =>
              deleteVisitByIdMutation.mutate({
                deleteVisitById: [
                  {
                    id: visit?.id!,
                  },
                  { id: true, endedAt: true },
                ],
              }),
            )

            notification.success({ message: isMulti ? 'Visite eliminate' : 'Visita eliminata' })

            onComplete?.()

            resolve()
          },
          async onCancel() {
            reject()
          },
        }),
      )
    },
    [deleteVisitByIdMutation, labels?.visitor?.name],
  )

  return {
    endVisit: {
      run: handleEndVisit,
      mutation: endVisitMutation,
    },

    deleteVisitById: {
      run: handleDeleteVisitById,
      mutation: deleteVisitByIdMutation,
    },
  }
}
