import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Badge, Button, Card as AntdCard, Col, Dropdown, Menu, Row, Tabs } from 'antd'
import styled from '@emotion/styled'
import { SettingOutlined, WarningOutlined } from '@ant-design/icons'
import { useIsDocumentVisible, useWebNotification } from '@gaudia/ui-common'
import DefaultLayout from '../../../components/layout/DefaultLayout'
import { User, useRequest, Visit } from '../../../lib/graphql'
import Loader from '../../../components/Loader/Loader'
import { LocationAvatar } from '../../../components/location/LocationAvatar'
import { Box } from '../../../components/layout/Box'
import { useInject } from '../../../store'
import { Visiting } from './Visiting'
import { Visited } from './Visited'
import { Waiting } from './Waiting'
import { LocationLabelsContext } from './locationLabelsContext'
import { useModal, useModal2 } from '../../../components/modals/useModal'
import { ViewAllBookingsModal } from './ViewAllBookingsModal'
import { AddBookingModal } from '../AddBookingModal'
import { AmendVisitModal, MoveTo } from './AmendVisitModal'
import { Desktop, Mobile, useIsDesktop } from '../../../components/layout/Responsive'
import { LocationNotification } from './LocationNotification'
import { observer } from 'mobx-react-lite'
import { DisabledQueue, useLocationQueues } from './useLocationQueues'
import { LocationQueueSettingsModal } from './LocationQueueSettingsModal'
import { useSmartUpdate } from '../../../components/useSmartUpdate'
import { Name } from '../../../components/format/Name'
import { usePreference } from '../../../components/preferencesHooks'
import { LocationQueuePreferences } from '../../../types'

const { TabPane } = Tabs

const Card = styled(AntdCard)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .ant-card-head {
    padding: 0 12px;
  }
  .ant-card-body {
    padding: 12px 12px 0 12px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
  }

  @media (min-width: 767px) {
    .ant-card-head {
      min-height: 65px;
      padding: 0 24px;
    }

    .ant-card-body {
      padding: 12px 24px;
    }
  }
`

const TheRow = styled(Row)({
  overflow: 'hidden',
  flex: 1,
})

const TheCol = styled(Col)({
  flex: 1,
  overflow: 'auto',
  minHeight: '100%',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 0,
})

export const LocationQueue = observer(() => {
  const { locationId } = useParams<{ locationId: string }>()
  const { authStore } = useInject(({ authStore }) => ({ authStore }))
  useSmartUpdate()

  const locationQuery = useRequest(
    locationId
      ? {
          locationById: [
            { id: locationId },
            {
              name: true,
              shortName: true,
              color: true,
              locationType: { appearance: { visitor: { name: true, pluralName: true } } },
            },
          ],
        }
      : null,
    { enabled: locationId, refetchOnWindowFocus: false },
  )

  const labels = useMemo(
    () => locationQuery?.data?.locationById?.locationType?.appearance,
    [
      // eslint-disable-next-line
      locationQuery?.data,
    ],
  )

  const locationQueuePreferencesQuery = usePreference<LocationQueuePreferences>('locationQueue', [
    locationId,
  ])
  const uiPreferences = locationQueuePreferencesQuery.data?.fields

  const disabledQueues = useMemo(() => {
    if (!uiPreferences?.endedList?.isVisible) {
      return ['visited'] as DisabledQueue[]
    }
  }, [uiPreferences?.endedList?.isVisible])

  const isInForeground = useIsDocumentVisible()
  const webNotification = useWebNotification({ requestPermission: true })
  const handleNewWaitingVisit = useCallback(() => {
    if (!isInForeground) {
      webNotification.show({
        title: 'Gaudia: Sono presenti delle nuove visite in coda',
        icon: '/gaudia-logo-square.jpg',
      })
    }
  }, [isInForeground])

  const { visited, visiting, waiting } = useLocationQueues({
    locationId,
    disabledQueues,
    whitelistedTags: uiPreferences?.whitelistedTags,
    blacklistedTags: uiPreferences?.blacklistedTags,
    onVisitWaiting: handleNewWaitingVisit,
  })

  // TODO: probably temporary
  const handleRefresh = useCallback(() => {
    if (!disabledQueues?.includes('visited')) {
      visited.refetch()
    }
    visiting.refetch()
    waiting.refetch()
  }, [disabledQueues, visited, visiting, waiting])

  const [isAllVisitsModalVisible, setAllVisitsModalVisible] = useModal()
  const [isAddVisitModalVisible, setAddVisitModalVisible] = useModal()
  const [isAmendVisitModalVisible, setAmendVisitModalVisible] = useModal()
  const [propsForAmendModal, setPropsForAmendModal] = useState<{
    currentStatus: MoveTo | 'ended'
    visit: Visit
  } | null>()

  const openAmendVisitModal = useCallback(
    ({ visit, currentStatus }: { currentStatus: MoveTo | 'ended'; visit: Visit }) => {
      setPropsForAmendModal({ visit, currentStatus })
      setAmendVisitModalVisible(true)
    },
    [setAmendVisitModalVisible],
  )

  const locationQueueSettingsModal = useModal2()

  const isDesktop = useIsDesktop()

  const [activeMobileTab, setActiveMobileTab] = useState<'waiting' | 'visiting' | 'completed'>(
    'waiting',
  )

  return (
    <LocationLabelsContext.Provider value={labels}>
      <DefaultLayout>
        <Loader isLoading={locationQuery.isFetchingInitial}>
          <Card
            title={
              locationQuery?.data?.locationById && (
                <Box display="flex" alignItems="center">
                  <LocationAvatar location={locationQuery?.data?.locationById} showLabel={true} />
                  <Box ml={10}>
                    {locationQuery?.data?.locationById?.name}:{' '}
                    {/*{upperFirst(labels?.visitor?.pluralName ?? '')}*/}
                    Gestione Coda
                  </Box>
                </Box>
              )
            }
            extra={
              <Box fontSize="15px" display="flex" alignItems="center">
                <Desktop>
                  <Box>
                    <b>Operatore:</b> <Name person={authStore.identity as User} />
                  </Box>
                </Desktop>
                <Box ml={2}>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => setAllVisitsModalVisible(true)}>
                          Visite previste
                        </Menu.Item>
                        <Menu.Item onClick={() => setAddVisitModalVisible(true)}>
                          Aggiungi visita
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          onClick={() => locationQueueSettingsModal.setOpen(true)}
                          icon={<SettingOutlined />}
                        >
                          Impostazioni
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Badge
                      count={
                        (uiPreferences?.whitelistedTags?.length ||
                          uiPreferences?.blacklistedTags?.length) && (
                          <WarningOutlined style={{ color: '#faad14' }} />
                        )
                      }
                    >
                      <Button icon={<SettingOutlined />} />
                    </Badge>
                  </Dropdown>
                </Box>
              </Box>
            }
          >
            <LocationNotification locationId={locationId} />

            <TheRow gutter={32}>
              {(isDesktop || activeMobileTab === 'waiting') && (
                <TheCol span={24} md={12}>
                  <Waiting
                    locationId={locationId}
                    waiting={waiting}
                    whitelistedTags={uiPreferences?.whitelistedTags}
                    blacklistedTags={uiPreferences?.blacklistedTags}
                    onCall={() => {
                      handleRefresh()
                    }}
                  />
                </TheCol>
              )}

              {(isDesktop || ['visiting', 'completed'].includes(activeMobileTab)) && (
                <TheCol span={24} md={12}>
                  {(isDesktop || activeMobileTab === 'visiting') && (
                    <Box
                      overflow="auto"
                      flexGrow={1}
                      height={['auto', '50%']}
                      display="flex"
                      flexDirection="column"
                    >
                      <Loader isLoading={!authStore?.identity?.id}>
                        <Visiting
                          visiting={visiting}
                          onMoveRequest={openAmendVisitModal}
                          onComplete={() => {
                            handleRefresh()
                          }}
                        />
                      </Loader>
                    </Box>
                  )}

                  {((isDesktop && uiPreferences?.endedList?.isVisible) ||
                    activeMobileTab === 'completed') && (
                    <Box
                      overflow="auto"
                      flexGrow={1}
                      height={['auto', '50%']}
                      display="flex"
                      flexDirection="column"
                      mt={isDesktop ? 30 : 0}
                    >
                      <Visited visited={visited} onMoveRequest={openAmendVisitModal} />
                    </Box>
                  )}
                </TheCol>
              )}
            </TheRow>

            <Mobile>
              <Tabs
                tabPosition="bottom"
                size="large"
                centered
                onChange={(tab) => {
                  // @ts-ignore
                  setActiveMobileTab(tab)
                }}
              >
                <TabPane
                  tab={
                    <>
                      In Coda{' '}
                      {!waiting?.isFetchingInitial && <>({waiting?.data?.visits?.items?.length})</>}
                    </>
                  }
                  key="waiting"
                />
                <TabPane
                  tab={
                    <>
                      In Visita{' '}
                      {!visiting?.isFetchingInitial && (
                        <>({visiting?.data?.visits?.items?.length})</>
                      )}
                    </>
                  }
                  key="visiting"
                />
                {uiPreferences?.endedList?.isVisible && (
                  <TabPane
                    tab={
                      <>
                        Completati{' '}
                        {!visited?.isFetchingInitial && (
                          <>({visited?.data?.visits?.items?.length})</>
                        )}
                      </>
                    }
                    key="completed"
                  />
                )}
              </Tabs>
            </Mobile>
          </Card>
        </Loader>
      </DefaultLayout>

      <ViewAllBookingsModal
        locationId={locationId}
        visible={isAllVisitsModalVisible}
        onCancel={() => setAllVisitsModalVisible(false)}
      />

      <AddBookingModal
        locationId={locationId}
        visible={isAddVisitModalVisible}
        onCancel={() => setAddVisitModalVisible(false)}
        onCreate={() => {
          setAddVisitModalVisible(false)
          handleRefresh()
        }}
      />

      <AmendVisitModal
        {...propsForAmendModal}
        visible={isAmendVisitModalVisible}
        onCancel={() => {
          setPropsForAmendModal(null)
          setAmendVisitModalVisible(false)
        }}
        onSuccess={() => {
          setAmendVisitModalVisible(false)
          handleRefresh()
        }}
      />

      <LocationQueueSettingsModal
        isOpen={locationQueueSettingsModal.isOpen}
        onClose={locationQueueSettingsModal.close}
        locationId={locationId}
      />
    </LocationLabelsContext.Provider>
  )
})

export default LocationQueue
