import LocationsQueuesSummary from './LocationsQueuesSummary'
import LocationQueue from './LocationQueue/LocationQueue'
import LocationsAgenda from './LocationsAgenda'
import Analytics from './analytics/Analytics'

export default [
  { path: '/queues/', redirect: '/queues/summary' },
  { path: '/queues/summary/:locationId?', component: LocationsQueuesSummary },
  { path: '/queues/manage/:locationId?', component: LocationQueue },
  { path: '/queues/agenda/:locationId?', component: LocationsAgenda },
  { path: '/queues/analytics', component: Analytics },
]
