import React, { useCallback, useContext, useMemo } from 'react'
import { Button, Checkbox, Form as AntdForm, Modal, Select, Tag } from 'antd'
import { observer } from 'mobx-react-lite'
import { CustomTagProps } from 'rc-select/lib/interface/generator'
import { PreferencePartialMutationInput, useRequest } from '../../../lib/graphql'
import { LocationLabelsContext } from './locationLabelsContext'
import { Box } from '../../../components/layout/Box'
import { usePreference, useUpdatePreference } from '../../../components/preferencesHooks'
import { LocationQueuePreferences } from '../../../types'
import { useForm } from '../../../components/form/useForm'
import { FormInput } from '../../../components/form/FormInput'
import { FormActions } from '../../../components/form/FormActions'
import { SubmitButton } from '../../../components/form/SubmitButton'

interface Props {
  isOpen?: boolean
  onClose?: Function
  locationId: string
}

export const LocationQueueSettingsModal: React.FC<Props> = observer(
  ({ isOpen, onClose, locationId }) => {
    const locationQueuePreferencesQuery = usePreference<LocationQueuePreferences>('locationQueue', [
      locationId,
    ])
    const uiPreferences = locationQueuePreferencesQuery.data?.fields
    const updateUiPreferences = useUpdatePreference<LocationQueuePreferences>('locationQueue')

    const { Form } = useForm<PreferencePartialMutationInput>({
      successText: 'Preferenze salvate',
    })

    const handleSubmit = async (data: Partial<PreferencePartialMutationInput>) => {
      await updateUiPreferences({ fields: data, entityIds: [locationId] })
      onClose?.()
    }

    const labels = useContext(LocationLabelsContext)

    const visitTagsQuery = useRequest(
      {
        visitTags: {
          text: true,
          color: true,
        },
      },
      { enabled: isOpen },
    )

    const visitTags = useMemo(
      () => visitTagsQuery?.data?.visitTags,
      [visitTagsQuery?.data?.visitTags],
    )

    const tagRender = useCallback(
      (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props

        const onPreventMouseDown = (event: {
          preventDefault: () => void
          stopPropagation: () => void
        }) => {
          event.preventDefault()
          event.stopPropagation()
        }

        const tag = visitTags?.find((tag) => tag?.text === value)

        return (
          <Tag
            color={tag?.color ?? 'default'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
          >
            {label}
          </Tag>
        )
      },
      [visitTags],
    )

    return (
      <Modal
        visible={isOpen}
        title="Impostazioni"
        destroyOnClose={true}
        footer={null}
        onCancel={() => onClose?.()}
      >
        <Form
          initialValues={(uiPreferences as PreferencePartialMutationInput) ?? {}}
          onSubmit={handleSubmit}
        >
          <AntdForm layout="vertical">
            <Box>
              <h2>Generale</h2>

              <FormInput label="Includi visite aventi solo i seguenti tag" name="whitelistedTags">
                {({ value, formik }) => (
                  <>
                    <Select
                      mode="tags"
                      placeholder="Seleziona o aggiungi tag"
                      value={value}
                      options={
                        visitTags?.map((tag) => ({
                          value: tag?.text ?? '',
                          label: tag?.text ?? '',
                        })) ?? []
                      }
                      style={{ width: '100%' }}
                      onChange={(value) => formik.setFieldValue('whitelistedTags', value)}
                      tagRender={tagRender}
                      allowClear={true}
                    />
                  </>
                )}
              </FormInput>

              <FormInput label="Escludi visite aventi i seguenti tag" name="blacklistedTags">
                {({ value, formik }) => (
                  <>
                    <Select
                      mode="tags"
                      placeholder="Seleziona o aggiungi tag"
                      value={value}
                      options={
                        visitTags?.map((tag) => ({
                          value: tag?.text ?? '',
                          label: tag?.text ?? '',
                        })) ?? []
                      }
                      style={{ width: '100%' }}
                      onChange={(value) => formik.setFieldValue('blacklistedTags', value)}
                      tagRender={tagRender}
                      allowClear={true}
                    />
                  </>
                )}
              </FormInput>

              <FormInput
                label="Mostra informazioni ospiti aggiuntive"
                name="additionalVisibleFields"
              >
                {({ value, formik }) => (
                  <Select
                    mode="multiple"
                    placeholder="Codice Fiscale, Numeri di telefono..."
                    value={value}
                    options={[
                      {
                        value: 'fiscalCode',
                        label: 'Codice Fiscale',
                      },
                      {
                        value: 'phoneNumbers',
                        label: 'Numeri di telefono',
                      },
                    ]}
                    style={{ width: '100%' }}
                    onChange={(value) => formik.setFieldValue('additionalVisibleFields', value)}
                    tagRender={tagRender}
                    allowClear={true}
                  />
                )}
              </FormInput>
            </Box>

            <Box mb={20}>
              <h2>Lista in visita</h2>

              <FormInput name="startedList.showOnlyStartedByMyself">
                {({ value, formik }) => (
                  <Checkbox
                    checked={value}
                    onChange={() =>
                      formik.setFieldValue('startedList.showOnlyStartedByMyself', !value)
                    }
                  >
                    Mostra solo {labels?.visitor?.pluralName} chiamati da te
                  </Checkbox>
                )}
              </FormInput>
            </Box>

            <Box mb={20}>
              <h2>Lista completate</h2>
              <FormInput name="endedList.isVisible">
                {({ value, formik }) => (
                  <Checkbox
                    checked={value}
                    onChange={() => formik.setFieldValue('endedList.isVisible', !value)}
                  >
                    Mostra lista completate
                  </Checkbox>
                )}
              </FormInput>
            </Box>
          </AntdForm>

          <FormActions>
            <Button onClick={() => onClose?.()}>Chiudi</Button>

            <SubmitButton size="middle">Salva</SubmitButton>
          </FormActions>
        </Form>
      </Modal>
    )
  },
)
