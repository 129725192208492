import React, { memo, Fragment, ReactNode } from 'react'
import { useMedia } from 'react-use'

interface IProps {
  children: ReactNode
  isMobile?: boolean
}

export const useIsMobile = () => {
  const isMobile = useMedia('(max-width: 767px)')

  return isMobile
}

const Mobile: React.FC<IProps> = memo((props: IProps) => {
  const { children } = props
  const isMobile = useMedia('(max-width: 767px)')

  if (!isMobile) return null

  return <Fragment>{children}</Fragment>
})

export default Mobile
