import React, { useCallback, useContext } from 'react'
import { Button, Col, Dropdown, Menu, Modal, notification, Row, Tag } from 'antd'
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined'
import to from 'await-to-js'
import { Box } from '../../../components/layout/Box'
import { ensureRefetch, useMutation, UseQueryResult, Visit, Visitor } from '../../../lib/graphql'
import { LocationLabelsContext } from './locationLabelsContext'
import { RelativeDate } from '../../../components/format/RelativeDate/RelativeDate'
import upperFirst from 'lodash/upperFirst'
import { Desktop } from '../../../components/layout/Responsive'
import { MoveTo } from './AmendVisitModal'
import { Name } from '../../../components/format/Name'
import { VisitsList } from './VisitsList'
import { ListItemTitle } from './ListItemTitle'
import { useCommonActions } from './useCommonActions'
import { useParams } from 'react-router-dom'
import { ListItemAdditionalFields } from './ListItemAdditionalFields'

interface Props {
  visiting: UseQueryResult
  onComplete?: Function
  onMoveRequest?: ({
    currentStatus,
    visit,
  }: {
    currentStatus: MoveTo | 'ended'
    visit: Visit
  }) => any
}

export const Visiting: React.FC<Props> = ({ visiting, onMoveRequest, onComplete }) => {
  const { locationId } = useParams<{ locationId: string }>()
  const labels = useContext(LocationLabelsContext)
  const commonActions = useCommonActions()

  const callVisitorMutation = useMutation()
  const handleCallAgainClick = useCallback(
    (visit: Visit) => {
      Modal.confirm({
        title: `Sei sicuro di voler chiamare di nuovo l'utente?`,
        content: (
          <>
            <div>
              {upperFirst(labels?.visitor?.name || '')}:{' '}
              <b>
                <Name person={visit?.visitor as Visitor} />
              </b>
            </div>
            <div>
              Numero: <b>{visit.ticketCode}</b>
            </div>
          </>
        ),
        async onOk() {
          if (!visit.id) return
          const [err] = await to(
            callVisitorMutation.mutate({
              callVisit: [{ visitId: visit.id, isRecall: true }, { id: true }],
            }),
          )

          if (err) {
            return notification.warn({
              message: `Chiamata non effettuata`,
              duration: 8,
              description: (
                <>
                  <Box mb={20}>
                    Non è stato possibile chiamare
                    <div>
                      {upperFirst(labels?.visitor?.name || '')}:{' '}
                      <b>
                        <Name person={visit?.visitor as Visitor} />
                      </b>
                    </div>
                    <div>
                      Numero: <b>{visit.ticketCode}</b>
                    </div>
                  </Box>
                  <div>In quanto è stato appena chiamato da un altro operatore</div>
                </>
              ),
            })
          }

          notification.success({ message: `${upperFirst(labels?.visitor?.name ?? '')} chiamato` })

          // onCall?.()
        },
      })
    },
    [labels?.visitor?.name, callVisitorMutation],
  )

  const handleCompleteAllVisitsClick = useCallback(
    () => commonActions.endVisit.run((visiting?.data?.visits?.items as Array<Visit>) ?? []),
    [commonActions.endVisit, visiting?.data?.visits?.items],
  )

  return (
    <>
      <Desktop>
        <Row style={{ marginBottom: '10px' }}>
          <Col style={{ flexGrow: 1 }}>
            <h2 style={{ display: 'inline' }}>In visita</h2>{' '}
            {!visiting.isFetchingInitial && `(${visiting?.data?.visits?.items?.length})`}
          </Col>
          <Col>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={handleCompleteAllVisitsClick}>
                    Marca tutte le visite come completate
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Col>
        </Row>
      </Desktop>

      <VisitsList
        visits={visiting?.data?.visits?.items as Visit[]}
        isLoading={visiting?.isFetchingInitial}
        listItemProps={(item) => ({
          content: (
            <Box>
              <ListItemTitle visit={item} />

              <Box fontSize={13} mb={1}>
                <RelativeDate label="chiamato: " date={item?.startedAt} />
              </Box>

              <ListItemAdditionalFields visitor={item.visitor} locationId={item.locationId!} />

              {item?.tags?.map((tag, tagIndex) => (
                <Box display="inline-block" mt={1}>
                  <Tag color={tag?.color ?? 'default'}>{tag?.text}</Tag>
                </Box>
              ))}
            </Box>
          ),
          actions: [
            {
              main: true,
              text: 'Completa',
              onClick: () => commonActions.endVisit.run([item]),
            },
            {
              text: 'Sposta in nuovo stato',
              onClick: () => onMoveRequest?.({ visit: item, currentStatus: 'started' }),
            },
            {
              text: 'Chiama di nuovo',
              onClick: () => handleCallAgainClick(item),
            },
            {
              text: 'Elimina',
              onClick: () =>
                commonActions.deleteVisitById.run([item], () => ensureRefetch(visiting)),
            },
          ],
        })}
        emptyList={`Nessun ${labels?.visitor?.name} in visita`}
      />
    </>
  )
}

export default Visiting
