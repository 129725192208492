import {
  LocationActions,
  MonitorActions,
  RoleActions,
  QueueActions,
  UserActions,
  LocationTypeActions,
  Action,
  CheckinKioskActions,
  CheckinVirtualKioskActions,
  OrganizationActions,
  AnalyticsActions,
} from '@gaudia/shared'

export interface SubItemItem {
  title: string
  path: string
  policies?: Action[]
  matches?: string[]
}

export interface SubItem {
  title: string
  policies?: Action[]
  items: SubItemItem[]
}

export interface TopItem {
  title: string
  path: string
  policies?: Action[]
  matches?: string[]
  items: SubItem[]
}

export type Menu = TopItem[]

export default [
  {
    title: 'Code',
    path: '/queues/summary',
    matches: ['/queues/*'],
    items: [
      {
        title: 'Panoramica code',
        policies: [],
        items: [
          {
            title: 'Panoramica code',
            path: '/queues/summary/',
            matches: [/queues\/summary/],
            policies: [QueueActions.ViewQueues],
          },
          {
            title: 'Statistiche',
            path: '/queues/analytics/',
            matches: [/queues\/analytics/],
            policies: [AnalyticsActions.ViewAnalytics],
          },
          {
            title: 'Prenotati',
            path: '/queues/agenda/',
            matches: [/queues\/agenda/],
            policies: [QueueActions.ViewBookings],
          },
        ],
      },
    ],
  },
  {
    title: 'Impostazioni',
    path: '/settings/location/list',
    matches: ['/settings/*'],
    items: [
      {
        title: 'Account',
        policies: [],
        items: [
          {
            title: 'Impostazioni Account',
            path: '/settings/organization/edit',
            policies: [OrganizationActions.ModifyOrganization],
          },
        ],
      },
      {
        title: 'Location',
        policies: [],
        items: [
          {
            title: 'Lista Location',
            path: '/settings/location/list',
            policies: [LocationActions.ModifyLocations],
          },
          {
            title: 'Nuova Location',
            path: '/settings/location/add',
            policies: [LocationActions.ModifyLocations],
          },
        ],
      },
      {
        title: 'Tipologie di Location',
        policies: [],
        items: [
          {
            title: 'Lista Tipologie Location',
            path: '/settings/locationType/list',
            policies: [LocationTypeActions.ModifyLocationTypes],
          },
          {
            title: 'Nuova Tipologia Location',
            path: '/settings/locationType/add',
            policies: [LocationTypeActions.ModifyLocationTypes],
          },
        ],
      },
      {
        title: 'Monitor',
        policies: [],
        items: [
          {
            title: 'Lista Monitor',
            path: '/settings/monitor/list',
            policies: [MonitorActions.ModifyMonitors],
          },
          {
            title: 'Nuovo Monitor',
            path: '/settings/monitor/add',
            policies: [MonitorActions.ModifyMonitors],
          },
        ],
      },
      {
        title: 'Totem e dispositivi di Check-in',
        policies: [],
        items: [
          {
            title: 'Lista Dispositivi',
            path: '/settings/checkin-kiosk/list',
            policies: [CheckinKioskActions.ModifyCheckinKiosks],
          },
          {
            title: 'Nuovo Dispositivo',
            path: '/settings/checkin-kiosk/add',
            policies: [CheckinKioskActions.ModifyCheckinKiosks],
          },
        ],
      },
      {
        title: 'Dispositivi di Check-in Virtuali',
        policies: [],
        items: [
          {
            title: 'Lista Dispositivi',
            path: '/settings/checkin-virtual-kiosk/list',
            policies: [CheckinVirtualKioskActions.ViewCheckinVirtualKiosks],
          },
          {
            title: 'Nuovo Dispositivo',
            path: '/settings/checkin-virtual-kiosk/add',
            policies: [CheckinVirtualKioskActions.ModifyCheckinVirtualKiosks],
          },
        ],
      },
      {
        title: 'Utenti e Ruoli',
        policies: [],
        items: [
          {
            title: 'Lista Utenti',
            path: '/settings/membership/list',
            policies: [UserActions.ManageUsers],
          },
          {
            title: 'Aggiungi Utente',
            path: '/settings/membership/add',
            policies: [UserActions.ManageUsers],
          },
          {
            title: 'Lista Ruoli',
            path: '/settings/role/list',
            policies: [RoleActions.ModifyRoles],
          },
          {
            title: 'Nuovo Ruolo',
            path: '/settings/role/add',
            policies: [RoleActions.ModifyRoles],
          },
        ],
      },
    ],
  },
]
