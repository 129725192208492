import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { Checkbox, List } from 'antd'
import { useList, usePrevious, useWindowSize } from 'react-use'
import set from 'lodash/fp/set'
import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle'
import { DragOutlined } from '@ant-design/icons'
import VList from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer'
import { CellMeasurerCache } from 'react-virtualized'
import { observer } from 'mobx-react-lite'

import { ListItem, ListItemProps } from './ListItem'
import { ScrollingBox } from './LocationQueue.styled'
import { Visit } from '../../../lib/graphql'
import { onDragEndReorder } from '../../../lib/dndUtils'
import { Box } from '../../../components/layout/Box'
import { useIsMobile } from '../../../components/layout/Responsive/Mobile'
import Loader from '../../../components/Loader'
import { usePreference } from '../../../components/preferencesHooks'
import { LocationQueuePreferences } from '../../../types'
import { useParams } from 'react-router'

export interface ReorderedItem {
  id?: string | null
  order?: number | null
}

export interface VisitsListProps {
  isLoading?: boolean
  visits?: Visit[]
  reorderedItems?: ReorderedItem[]
  selectedItemIds?: string[]
  isMultiselecting?: boolean
  isReordering?: boolean
  onChange?: (bag: { reorderedItems?: ReorderedItem[]; selectedItemIds?: string[] }) => any
  actionsPanel?: (bag: {
    reorderedItems?: ReorderedItem[]
    selectedItemIds?: string[]
    isMultiselecting?: boolean
    isReordering?: boolean
  }) => React.ReactNode | null
  listItemProps: (item: Visit) => ListItemProps
  emptyList?: ReactNode
}

export const VisitsList: React.FC<VisitsListProps> = observer(
  ({
    visits,
    reorderedItems: propReorderedItems,
    selectedItemIds: propSelectedItemIds,
    isLoading,
    isMultiselecting,
    isReordering,
    listItemProps,
    emptyList,
    actionsPanel,
    onChange,
  }) => {
    /**
     * Reordering
     */
    const [localVisits, setLocalVisits] = useState<(Visit | null)[]>([])

    useEffect(() => {
      if (!isReordering) {
        setLocalVisits(visits ?? [])
      }
    }, [isReordering, visits])

    const [reorderedItems, reorderedItemsActions] = useList<ReorderedItem>([])
    useEffect(() => {
      if (!isEqual(propReorderedItems, reorderedItems)) {
        reorderedItemsActions.set(propReorderedItems as ReorderedItem[])
      }
      // eslint-disable-next-line
    }, [propReorderedItems])

    const handleDragEnd = useCallback(
      (result: DropResult, provided: ResponderProvided) => {
        if (!result.destination || result.source.index === result.destination.index) {
          return
        }
        const destination = localVisits[result.destination.index]
        const source = localVisits[result.source.index]
        if (!destination || !source || !destination?.order) return

        let updatedWaitingListData = onDragEndReorder(
          localVisits,
          result.source.index,
          result.destination.index,
        )

        const destinationPrev = updatedWaitingListData[result.destination.index - 1]
        const destinationNext = updatedWaitingListData[result.destination.index + 1]
        const destinationPrevOrder = destinationPrev?.order ?? destination?.order - 2000
        const destinationNextOrder = destinationNext?.order ?? destination?.order + 2000
        const newOrder = (destinationPrevOrder + destinationNextOrder) / 2
        updatedWaitingListData = set(
          `${result.destination.index}.order`,
          newOrder,
          updatedWaitingListData,
        )
        const dest = updatedWaitingListData[result.destination.index]
        reorderedItemsActions.push({ id: dest?.id, order: dest?.order })

        setLocalVisits(updatedWaitingListData)
      },
      [localVisits, reorderedItemsActions],
    )

    /**
     * Multi select
     */
    const [selectedItemIds, multiselectActions] = useList<string>([])
    useEffect(() => {
      if (!isEqual(propSelectedItemIds, selectedItemIds)) {
        multiselectActions.set(propSelectedItemIds as string[])
      }
      // eslint-disable-next-line
    }, [propSelectedItemIds])

    useEffect(() => {
      onChange?.({ reorderedItems, selectedItemIds })
    }, [selectedItemIds, reorderedItems, onChange])

    useEffect(() => {
      if (!isMultiselecting) {
        multiselectActions.reset()
      }
    }, [isMultiselecting, multiselectActions])

    const ActionPanel = useMemo(() => {
      return actionsPanel?.({
        reorderedItems,
        selectedItemIds,
        isMultiselecting,
        isReordering,
      })
    }, [actionsPanel, isMultiselecting, isReordering, reorderedItems, selectedItemIds])

    const createListItemProps = useCallback(
      (item: Visit) => {
        const props = listItemProps(item)
        return {
          ...props,
          actions: isReordering || isMultiselecting ? [] : props.actions,
        }
      },
      [isMultiselecting, isReordering, listItemProps],
    )
    const { locationId } = useParams<{ locationId: string }>()
    const locationQueuePreferencesQuery = usePreference<LocationQueuePreferences>('locationQueue', [
      locationId,
    ])
    const uiPreferences = locationQueuePreferencesQuery.data?.fields

    const cache = useMemo(
      () =>
        new CellMeasurerCache({
          //Define a CellMeasurerCache --> Put the height and width you think are the best
          defaultHeight: 65,
          minHeight: 50,
          fixedWidth: true,
        }),
      [],
    )

    useEffect(() => {
      cache.clearAll()
    }, [JSON.stringify(uiPreferences?.additionalVisibleFields)])

    const windowSize = useWindowSize()
    const prevWindowSize = usePrevious(windowSize)
    const debouncedClearAll = useMemo(
      () =>
        throttle(() => {
          cache.clearAll()
        }, 1000),
      [],
    )

    useEffect(() => {
      if (prevWindowSize && prevWindowSize !== windowSize) {
        debouncedClearAll()
      }
    }, [prevWindowSize, windowSize])

    const rowRenderer = useMemo(
      () =>
        // @ts-ignore
        ({ index, key, style, parent }) => {
          const item = localVisits?.[index] as Visit

          return (
            <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
              {({ registerChild }) => (
                <div
                  // @ts-ignore
                  ref={registerChild}
                  style={style}
                >
                  <ListItem
                    {...createListItemProps(item)}
                    // @ts-ignore
                    style={{ borderBottom: '1px solid #d9d9d9' }}
                    beforeTitle={
                      <AnimatePresence>
                        {isReordering && (
                          <motion.div
                            initial={{ opacity: 0, x: '-100%' }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: '-100%' }}
                            transition={{ type: 'tween' }}
                          >
                            <Box mr={10}>
                              <DragOutlined style={{ fontSize: '25px' }} />
                            </Box>
                          </motion.div>
                        )}
                        {isMultiselecting && (
                          <motion.div
                            initial={{ opacity: 0, x: '-100%' }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: '-100%' }}
                            transition={{ type: 'tween' }}
                          >
                            <Box mr={10}>
                              <Checkbox
                                checked={selectedItemIds?.includes(item?.id as string)}
                                onClick={() => {
                                  const id = item?.id as string
                                  const index = selectedItemIds?.indexOf(id)
                                  if (index > -1) {
                                    multiselectActions.removeAt(index)
                                  } else {
                                    multiselectActions.push(id)
                                  }
                                }}
                              />
                            </Box>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    }
                  />
                </div>
              )}
            </CellMeasurer>
          )
        },
      [
        createListItemProps,
        isMultiselecting,
        isReordering,
        localVisits,
        multiselectActions,
        selectedItemIds,
      ],
    )

    const isMobile = useIsMobile()

    return (
      <>
        {/*<ScrollingBox>
          <List size="large" rowKey="id" bordered loading={isLoading} style={{ flexGrow: 1 }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(droppableProvided: any) => (
                  <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                    <AnimatePresence>
                      {localVisits?.map(
                        (item, index) =>
                          !!item?.id && (
                            <Draggable
                              key={'draggable' + item?.id || ''}
                              draggableId={'draggable' + item?.id || ''}
                              index={index}
                              isDragDisabled={!isReordering}
                            >
                              {(provided: any) => (
                                <motion.div
                                  key={item?.id as string}
                                  initial={{ opacity: 0, y: '-100%' }}
                                  animate={{ opacity: 1, y: 0 }}
                                  exit={{ opacity: 0, y: '-100%' }}
                                  transition={{ type: 'tween', delay: 0.4, staggerChildren: 0.5 }}
                                  style={{ borderBottom: '1px solid #f0f0f0' }}
                                >
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <ListItem
                                      {...createListItemProps(item)}
                                      beforeTitle={
                                        <AnimatePresence>
                                          {isReordering && (
                                            <motion.div
                                              initial={{ opacity: 0, x: '-100%' }}
                                              animate={{ opacity: 1, x: 0 }}
                                              exit={{ opacity: 0, x: '-100%' }}
                                              transition={{ type: 'tween' }}
                                            >
                                              <Box mr={10}>
                                                <DragOutlined style={{ fontSize: '25px' }} />
                                              </Box>
                                            </motion.div>
                                          )}
                                          {isMultiselecting && (
                                            <motion.div
                                              initial={{ opacity: 0, x: '-100%' }}
                                              animate={{ opacity: 1, x: 0 }}
                                              exit={{ opacity: 0, x: '-100%' }}
                                              transition={{ type: 'tween' }}
                                            >
                                              <Box mr={10}>
                                                <Checkbox
                                                  checked={selectedItemIds?.includes(
                                                    item?.id as string,
                                                  )}
                                                  onClick={() => {
                                                    const id = item?.id as string
                                                    const index = selectedItemIds?.indexOf(id)
                                                    if (index > -1) {
                                                      multiselectActions.removeAt(index)
                                                    } else {
                                                      multiselectActions.push(id)
                                                    }
                                                  }}
                                                />
                                              </Box>
                                            </motion.div>
                                          )}
                                        </AnimatePresence>
                                      }
                                    />
                                  </div>
                                </motion.div>
                              )}
                            </Draggable>
                          ),
                      )}
                      {droppableProvided.placeholder}
                    </AnimatePresence>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {!isLoading && !visits?.length && (
              <List.Item>{emptyList || 'Nessuno'}</List.Item>
            )}
          </List>
        </ScrollingBox>*/}

        <ScrollingBox height={'auto'}>
          <Loader isLoading={isLoading}>
            <AutoSizer>
              {({ width, height }) => (
                <VList
                  height={height}
                  width={width}
                  overscanRowCount={20}
                  rowCount={localVisits?.length}
                  // rowHeight={isMobile ? 106 : 65}
                  rowRenderer={rowRenderer}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                />
              )}
            </AutoSizer>
            {!isLoading && !visits?.length && (
              <List size="large">
                <List.Item>{emptyList || 'Nessuno'}</List.Item>
              </List>
            )}
          </Loader>
        </ScrollingBox>

        <AnimatePresence>
          {ActionPanel && (
            <motion.div
              initial={{ y: '+100%' }}
              animate={{ y: 0 }}
              exit={{ y: '+100%' }}
              transition={{ type: 'tween' }}
            >
              {ActionPanel}
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  },
)
