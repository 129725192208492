import { Instance, types } from 'mobx-state-tree'
import { createContext, useContext } from 'react'
import { AuthStore } from './AuthStore'
import { DashboardStore } from './DashboardStore'
import { VersionNotificationStore } from './VersionNotificationStore'

/**
 * Define store
 */
export const RootStore = types.model({
  authStore: types.optional(AuthStore, {}),
  dashboardStore: types.optional(DashboardStore, {}),
  versionNotificationStore: types.optional(VersionNotificationStore, {}),
})

export type RootStoreModel = Instance<typeof RootStore>

/**
 * Context and use store
 */
const StoreContext = createContext<RootStoreModel>({} as RootStoreModel)

export const useStore = () => useContext(StoreContext)
export const StoreProvider = StoreContext.Provider

export type MapStore<T> = (store: RootStoreModel) => T

export const useInject = <T = RootStoreModel>(mapStore: MapStore<T>) => {
  const store = useStore()
  return mapStore(store)
}

export const store = RootStore.create()
