import { css } from '@emotion/core'

export default css`
  & {
    padding: 0;
    line-height: 1;
    height: auto;
    &.is-mobileMenuOpen {
      position: fixed;
      width: 100%;
      z-index: 10;
    }
    @media (min-width: 768px) {
      min-height: 50px;
    }
    .TopBar-wrapper {
      display: flex;
    }
    .TopBar-logo {
      background: #1890ff;
      width: 100px;
      color: white;
      font-size: 20px;
      min-width: 100px;
      padding: 5px 15px;
      @media (min-width: 768px) {
        width: 200px;
        min-width: 200px;
      }
      // background: #078EC7;
      // background: white;
      display: flex;
      align-items: center;
      // justify-content: center;
      a {
        color: white;
        font-weight: bold;
      }
      img {
        filter: brightness(0) invert(1);
        max-width: 120px;
        max-height: 40px;
        width: 100%;
        // border-radius: 15px;
      }
    }
    .TopBar-items {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      width: 100%;
      .TopBar-items-left {
        overflow: auto;
        .ant-menu {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 0 12px;
          .ant-menu-item {
            text-transform: uppercase;
            line-height: 1;
            font-weight: 500;
            letter-spacing: 1.2px;
            padding: 0 12px;
            // font-size: 14px;

            &:hover {
              background: transparent;
            }
            @media (min-width: 768px) {
              // font-size: 10px;
              padding: 0 6px;
            }
            @media (min-width: 1024px) {
              // font-size: 13px;
              padding: 0 12px;
            }
            &.ant-menu-item-selected {
              background-color: transparent;
              a {
                position: relative;
                &:after {
                  content: '';
                  position: absolute;
                  top: calc(100% + 2px);
                  left: 0;
                  width: 100%;
                  height: 2px;
                  background: #008ec7;
                }
              }
            }
          }
        }
      }
      .TopBar-items-right {
        flex-shrink: 1;
        .ant-menu {
          display: flex;
          align-items: center;
          height: 50px;
          .ant-menu-item {
            line-height: 1;
            font-weight: 500;
            letter-spacing: 1.2px;
            i {
              margin-right: 2px;
            }
            &.ant-menu-item-selected {
              background-color: transparent;
              a {
                position: relative;
              }
            }
          }
        }
      }
    }
    .TopBar-mobileMenu {
      color: white;
      a.TopBar-mobileMenu-close {
        font-size: 30px;
      }
    }
  }
`
