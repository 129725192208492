import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { Dropdown, Menu } from 'antd'
import LogoutOutlined from '@ant-design/icons/LogoutOutlined'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import UserOutlined from '@ant-design/icons/UserOutlined'
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined'
import { AuthStoreModel, useInject } from '../../../store'
import MenuContext from '../../../features/MenuContext'

interface Props {
  onClick?: Function
  children?: ({ Dropdown, username }: { Dropdown: React.FC; username?: string }) => ReactNode
}

export const UserDropdown: React.FC<Props> = observer(props => {
  const history = useHistory()
  const auth = useInject<AuthStoreModel>(({ authStore }) => authStore)
  const hasMultipleOrgs = useMemo(() => auth?.organizations?.length > 1, [
    auth?.organizations?.length,
  ])
  const { setMobileMenuOpen } = useContext(MenuContext)
  const { children } = props
  const { authStore } = useInject(({ authStore }) => ({ authStore }))

  const handleProfileClick = useCallback(() => {
    const userId = authStore?.identity?.id
    if (userId) {
      history.push(`/settings/user/edit/${userId}`)
      setMobileMenuOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore?.identity, history])

  const handleOrgClick = useCallback(() => {
    history.push('/select-organization')
    setMobileMenuOpen(false)
  }, [history])

  const handleLogoutClick = useCallback(async () => {
    await auth.logout()
    history.push('/login')
  }, [auth, history])

  const username = useMemo(
    // @ts-ignore
    () => (authStore?.identity?.__typename === 'User' ? authStore?.identity?.email : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authStore?.identity],
  )

  const actionsDropdown = (
    <Menu>
      <Menu.Item key="2">
        <div onClick={handleProfileClick}>
          <UserOutlined /> Profilo utente
        </div>
      </Menu.Item>
      {hasMultipleOrgs && (
        <Menu.Item key="2">
          <div onClick={handleOrgClick}>
            <ApartmentOutlined /> Org: {auth.currentOrganization?.name}
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="2">
        <div
          onClick={(...args) => {
            handleLogoutClick()
          }}
        >
          <LogoutOutlined /> Logout
        </div>
      </Menu.Item>
    </Menu>
  )

  const TheDropdown: React.FC = (props: any) => (
    <Dropdown style={{ zIndex: 10000 }} overlay={actionsDropdown} trigger={['click']} {...props} />
  )

  if (!children) return null

  return (
    <>
      {children({
        Dropdown: TheDropdown,
        username,
      })}
    </>
  )
})
