import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Button, Col, Dropdown, Menu, Modal, notification, Row, Tag } from 'antd'
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined'
import upperFirst from 'lodash/upperFirst'
import capitalize from 'lodash/capitalize'
import Bluebird from 'bluebird'
import StepForwardOutlined from '@ant-design/icons/StepForwardOutlined'
import { to } from 'await-to-js'
import LogRocket from 'logrocket'
import { Box } from '../../../components/layout/Box'
import { ensureRefetch, useMutation, UseQueryResult, Visit, Visitor } from '../../../lib/graphql'
import { FormActions } from '../../../components/form/FormActions/FormActions'
import { useModal } from '../../../components/modals/useModal'
import { TransferModal } from './TransferModal'
import { LocationLabelsContext } from './locationLabelsContext'
import { RelativeDate } from '../../../components/format/RelativeDate/RelativeDate'
import { Desktop, useIsMobile } from '../../../components/layout/Responsive'
import { Name } from '../../../components/format/Name'
import { ReorderedItem, VisitsList, VisitsListProps } from './VisitsList'
import { ListItemTitle } from './ListItemTitle'
import { useCommonActions } from './useCommonActions'
import { formatName } from '../../../components/format/Name/Name'
import { ListItemAdditionalFields } from './ListItemAdditionalFields'

interface Props {
  locationId: string
  waiting: UseQueryResult
  onCall?: Function
  whitelistedTags?: string[]
  blacklistedTags?: string[]
}

export const Waiting: React.FC<Props> = ({
  locationId,
  waiting,
  whitelistedTags,
  blacklistedTags,
  onCall,
}) => {
  const labels = useContext(LocationLabelsContext)

  const callVisitorMutation = useMutation()
  const callVisitor = useCallback(
    async (visit: Visit) => {
      Modal.confirm({
        title: `Sei sicuro di voler effettuare la chiamata?`,
        content: (
          <div>
            {formatName(visit?.visitor) && (
              <div>
                {upperFirst(labels?.visitor?.name || '')}:{' '}
                <b>
                  <Name person={visit?.visitor as Visitor} />
                </b>
              </div>
            )}

            <div>
              Numero: <b>{visit.ticketCode}</b>
            </div>
          </div>
        ),
        okText: 'Chiama',
        async onOk() {
          if (!visit.id) return
          const [err] = await to(
            callVisitorMutation.mutate({
              callVisit: [{ visitId: visit.id }, { id: true }],
            }),
          )

          if (err) {
            return notification.warn({
              message: `Chiamata non effettuata`,
              duration: 8,
              description: (
                <>
                  <Box mb={20}>
                    Non è stato possibile chiamare
                    <div>
                      {upperFirst(labels?.visitor?.name || '')}:{' '}
                      <b>
                        <Name person={visit?.visitor as Visitor} />
                      </b>
                    </div>
                    <div>
                      Numero: <b>{visit.ticketCode}</b>
                    </div>
                  </Box>
                  <div>In quanto è stato appena chiamato da un altro operatore</div>
                </>
              ),
            })
          }

          notification.success({ message: `${upperFirst(labels?.visitor?.name ?? '')} chiamato` })

          onCall?.()
        },
      })
    },
    [labels, callVisitorMutation.mutate, onCall],
  )

  /**
   * Reordering
   */

  const [isReordering, setReordering] = useState(false)
  const [reorderedItems, setReorderedItems] = useState<ReorderedItem[] | undefined>([])

  const handleReorderCancel = useCallback(() => {
    setReordering(false)
  }, [])

  const changeOrderMutation = useMutation()
  const [isSavingOrder, setIsSavingOrder] = useState(false)
  const handleReorderSubmit = useCallback(
    async (itemsToReorder: ReorderedItem[]) => {
      setIsSavingOrder(true)
      await Bluebird.map(
        itemsToReorder,
        async (item) => {
          return (
            item.id &&
            changeOrderMutation.mutate({
              // TODO: add partial logic to @davinci/graphql
              updateVisit: [
                { where: { _id: { EQ: item.id } }, data: { order: item.order } },
                { id: true },
              ],
            })
          )
        },
        { concurrency: 10 },
      )

      setReordering(false)
      setIsSavingOrder(false)
      setSelectedItemIds([])
      notification.success({ message: 'Riordinamento effettuato' })

      await waiting.refetch()
    },
    [changeOrderMutation.mutate, waiting],
  )

  const commonActions = useCommonActions()

  /**
   * Multi select
   */

  const [isMultiselecting, setIsMultiselecting] = useState(false)
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>()
  const [isTerminating, setTerminating] = useState(false)
  const handleTerminateClick = useCallback(async () => {
    setTerminating(true)
    const selectedVisits = (waiting?.data?.visits?.items as Visit[])?.filter(
      ({ id }) => id && selectedItemIds?.includes(id),
    )

    await commonActions.endVisit.run(selectedVisits).finally(() => setTerminating(false))
    setIsMultiselecting(false)
    setSelectedItemIds([])
  }, [waiting?.data?.visits?.items, commonActions.endVisit, selectedItemIds])

  /**
   * Transfer
   */

  const [isTransferModalOpen, setTransferModalOpen] = useModal()
  const [inTransferVisits, setInTransferVisits] = useState<Visit[] | null>()

  const handleTransferClick = useCallback(
    (item: Visit[]) => {
      setInTransferVisits(item)
      setTransferModalOpen(true)
    },
    [setTransferModalOpen],
  )

  const handleTransferSuccess = useCallback(
    (inTransferVisits) => {
      setTransferModalOpen(false)
      notification.success({
        message: `${capitalize(labels?.visitor?.pluralName ?? '')} spostati con successo`,
      })
      setSelectedItemIds([])
      setIsMultiselecting(false)
      return waiting.refetch()
    },
    [labels, setTransferModalOpen, waiting],
  )

  const handleTransferModalCancel = useCallback(() => {
    setInTransferVisits(null)
    setTransferModalOpen(false)
  }, [setTransferModalOpen])

  /**
   * Call Next
   */
  const callNextCriteria = useMemo(
    () =>
      (whitelistedTags && whitelistedTags?.length > 0) ||
      (blacklistedTags && blacklistedTags?.length > 0)
        ? {
            tags: {
              text: {
                ...(whitelistedTags?.length && { IN: whitelistedTags }),
                ...(blacklistedTags?.length && { NIN: blacklistedTags }),
              },
            },
          }
        : {},
    [whitelistedTags, blacklistedTags],
  )
  const callNextMutation = useMutation()

  const handleCallNextBtnClick = useCallback(async () => {
    Modal.confirm({
      title: `Chiama il Prossimo`,
      content: <div>Sei sicuro di voler chiamare il prossimo {labels?.visitor?.name}?</div>,
      okText: 'Chiama',
      async onOk() {
        const waitingCount = waiting?.data?.visits?.items?.length
        const [err, result] = await to(
          callNextMutation.mutate({
            callNextVisit: [
              { locationId, where: callNextCriteria },
              { id: true, visitor: { id: true, fullName: true }, ticketCode: true },
            ],
          }),
        )

        if (err) {
          return notification.warn({
            message: `Chiamata non effettuata`,
            duration: 8,
            description: (
              <>
                <div>Non è stato possibile chiamare il prossimo {labels?.visitor?.name}.</div>
                Probabilmente non c'è nessuno in coda
              </>
            ),
          })
        }

        if (waitingCount === 0) {
          LogRocket.captureMessage('Call-next successful even though the waiting count was 0', {
            extra: {
              visitorId: result?.data?.callNextVisit?.visitor?.id ?? '',
            },
          })
        }

        notification.success({
          message: `${upperFirst(labels?.visitor?.name ?? '')} chiamato`,
          description: (
            <div>
              <div>
                {result?.data?.callNextVisit?.visitor && (
                  <Name person={result?.data?.callNextVisit?.visitor} />
                )}
              </div>
              <div>
                Numero: <b>{result?.data?.callNextVisit?.ticketCode}</b>
              </div>
            </div>
          ),
        })
        if (onCall) {
          onCall()
        }
      },
    })
  }, [callNextMutation])

  const actionsPanel: VisitsListProps['actionsPanel'] = useCallback(
    ({ reorderedItems, isMultiselecting, isReordering }) => {
      if (!isMultiselecting && !isReordering) return null

      if (isReordering) {
        return (
          <FormActions textAlign="right">
            <Button size="large" onClick={handleReorderCancel}>
              Annulla
            </Button>
            <Button
              size="large"
              type="primary"
              disabled={!reorderedItems?.length}
              onClick={() => reorderedItems && handleReorderSubmit(reorderedItems)}
              loading={isSavingOrder}
            >
              Salva
            </Button>
          </FormActions>
        )
      }

      if (isMultiselecting) {
        return (
          <FormActions style={{ textAlign: 'center' }}>
            <Button size="large" onClick={() => setIsMultiselecting(false)}>
              Annulla
            </Button>
            <Button
              size="large"
              type="primary"
              disabled={!selectedItemIds?.length}
              onClick={() =>
                selectedItemIds &&
                handleTransferClick(
                  waiting?.data?.visits?.items?.filter?.(
                    (visit) => visit?.id && selectedItemIds.includes(visit?.id),
                  ) as Visit[],
                )
              }
            >
              Trasferisci
            </Button>
            <Button
              size="large"
              type="primary"
              disabled={!selectedItemIds?.length}
              onClick={handleTerminateClick}
            >
              Termina
            </Button>
          </FormActions>
        )
      }
    },
    [
      handleReorderCancel,
      handleReorderSubmit,
      handleTerminateClick,
      handleTransferClick,
      isSavingOrder,
      isTerminating,
      selectedItemIds,
      waiting,
    ],
  )

  const handleListChange = useCallback<Required<VisitsListProps>['onChange']>(
    ({ reorderedItems, selectedItemIds }) => {
      setReorderedItems(reorderedItems)
      setSelectedItemIds(selectedItemIds)
    },
    [],
  )

  const isMobile = useIsMobile()

  return (
    <>
      <Row style={{ marginBottom: '10px' }}>
        <Desktop>
          <Col style={{ flexGrow: 1 }}>
            <h2 style={{ display: 'inline' }}>In Coda</h2>{' '}
            {!waiting.isFetchingInitial && `(${waiting?.data?.visits?.items?.length})`}
          </Col>
        </Desktop>

        <Row
          gutter={6}
          {...(isMobile ? { style: { width: '100%', justifyContent: 'flex-end' } } : {})}
        >
          <Col>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => setIsMultiselecting(true)}>
                    Selezione multipla
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Col>
          <Col>
            <Button
              icon={<StepForwardOutlined />}
              type="primary"
              disabled={!waiting?.data?.visits?.items?.length}
              onClick={handleCallNextBtnClick}
            >
              Chiama Prossimo
            </Button>
          </Col>
        </Row>
      </Row>
      <VisitsList
        isLoading={waiting.isFetchingInitial}
        isMultiselecting={isMultiselecting}
        isReordering={isReordering}
        visits={waiting?.data?.visits?.items as Visit[]}
        reorderedItems={reorderedItems}
        selectedItemIds={selectedItemIds}
        onChange={handleListChange}
        listItemProps={(item) => ({
          content: (
            <Box>
              <ListItemTitle visit={item} />

              <Box fontSize={13} mb={1}>
                <RelativeDate label="check-in: " date={item?.createdAt} />
              </Box>

              <ListItemAdditionalFields visitor={item.visitor} locationId={item.locationId!} />

              {item?.tags?.map((tag, tagIndex) => (
                <Box display="inline-block" mt={1}>
                  <Tag color={tag?.color ?? 'default'}>{tag?.text}</Tag>
                </Box>
              ))}
            </Box>
          ),
          actions: [
            {
              main: true,
              text: 'Chiama',
              onClick: () => !isReordering && callVisitor(item),
            },
            {
              text: 'Transferisci ad altra location',
              onClick: () => handleTransferClick([item]),
            },
            {
              text: 'Marca come completata',
              onClick: () => commonActions.endVisit.run([item]),
            },
            {
              text: 'Elimina',
              onClick: () =>
                commonActions.deleteVisitById.run([item], () => ensureRefetch(waiting)),
            },
          ],
        })}
        emptyList={`Nessun ${labels?.visitor?.name} in attesa`}
        actionsPanel={actionsPanel}
      />
      <TransferModal
        visible={isTransferModalOpen}
        inTransferVisits={inTransferVisits}
        onCancel={handleTransferModalCancel}
        onSuccess={handleTransferSuccess}
      />
    </>
  )
}
