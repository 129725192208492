import { asyncLoad } from '../../lib/asyncLoad'
import UE from './membership/UserEdit'

export default [
  {
    path: '/settings/locationType/list',
    component: asyncLoad({
      loader: () => import('./locationType/LocationTypeList'),
    }),
  },
  {
    path: '/settings/locationType/edit/:id',
    component: asyncLoad({
      loader: () => import('./locationType/LocationTypeEdit'),
    }),
  },
  {
    path: '/settings/locationType/add',
    component: asyncLoad({
      loader: () => import('./locationType/LocationTypeEdit'),
    }),
  },

  {
    path: '/settings/location/list',
    component: asyncLoad({
      loader: () => import('./location/LocationList'),
    }),
  },
  {
    path: '/settings/location/edit/:id',
    component: asyncLoad({
      loader: () => import('./location/LocationEdit'),
    }),
  },
  {
    path: '/settings/location/add',
    component: asyncLoad({
      loader: () => import('./location/LocationEdit'),
    }),
  },

  {
    path: '/settings/role/list',
    component: asyncLoad({
      loader: () => import('./role/RoleList'),
    }),
  },
  {
    path: '/settings/role/edit/:id',
    component: asyncLoad({
      loader: () => import('./role/RoleEdit'),
    }),
  },
  {
    path: '/settings/role/add',
    component: asyncLoad({
      loader: () => import('./role/RoleEdit'),
    }),
  },

  {
    path: '/settings/monitor/list',
    component: asyncLoad({
      loader: () => import('./monitor/MonitorList'),
    }),
  },
  {
    path: '/settings/monitor/edit/:id',
    component: asyncLoad({
      loader: () => import('./monitor/MonitorEdit'),
    }),
  },
  {
    path: '/settings/monitor/add',
    component: asyncLoad({
      loader: () => import('./monitor/MonitorEdit'),
    }),
  },

  {
    path: '/settings/checkin-kiosk/list',
    component: asyncLoad({
      loader: () => import('./checkinKiosk/CheckinKioskList'),
    }),
  },
  {
    path: '/settings/checkin-kiosk/edit/:id',
    component: asyncLoad({
      loader: () => import('./checkinKiosk/CheckinKioskEdit'),
    }),
  },
  {
    path: '/settings/checkin-kiosk/add',
    component: asyncLoad({
      loader: () => import('./checkinKiosk/CheckinKioskEdit'),
    }),
  },

  {
    path: '/settings/checkin-virtual-kiosk/list',
    component: asyncLoad({
      loader: () => import('./checkinVirtualKiosk/CheckinVirtualKioskList'),
    }),
  },
  {
    path: '/settings/checkin-virtual-kiosk/edit/:id',
    component: asyncLoad({
      loader: () => import('./checkinVirtualKiosk/CheckinVirtualKioskEdit'),
    }),
  },
  {
    path: '/settings/checkin-virtual-kiosk/add',
    component: asyncLoad({
      loader: () => import('./checkinVirtualKiosk/CheckinVirtualKioskEdit'),
    }),
  },

  {
    path: '/settings/membership/list',
    component: asyncLoad({
      loader: () => import('./membership/MembershipList'),
    }),
  },
  {
    path: '/settings/user/edit/:id',
    component: asyncLoad({
      loader: () => import('./membership/UserEdit'),
    }),
  },
  {
    path: '/settings/membership/add',
    component: asyncLoad({
      loader: () => import('./membership/UserInvite'),
    }),
  },
  {
    path: '/settings/organization/edit',
    component: asyncLoad({
      loader: () => import('./organization/OrganizationEdit'),
    }),
  },
]
