import React from 'react'
import Loadable, { Options } from 'react-loadable'
import { BgLayout } from '../components/layout/BgLayout'
import { Spin } from 'antd'

export const asyncLoad = (opts: { loader: Options<any, any>['loader'] }) =>
  Loadable({
    loading: (props) => (
      <BgLayout>
        <Spin spinning={true} size="large" />
      </BgLayout>
    ),
    delay: 1000,
    ...opts,
  })
