import React from 'react'
import ErrorBoundary from './ErrorBoundary'

const withErrorBoundary = () => (WrappedComponent: React.FC) => (props: any) => {
  return (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  )
}

export default withErrorBoundary
