import { useInterval, useMethods } from 'react-use'
import moment from 'moment'

const createStartDate = () => moment().startOf('day').toDate()
const createEndDate = () => moment().endOf('day').toDate()

export const useDateInterval = () => {
  const [{ startDate, endDate }, { updateDates }] = useMethods<
    any,
    { startDate?: Date | null; endDate?: Date | null }
  >(
    () => ({
      updateDates: (state) => {
        const newStartDate = createStartDate()
        const newEndDate = createEndDate()
        if (
          newEndDate.getTime() === state?.startDate?.getTime() &&
          newEndDate.getTime() === state?.endDate?.getTime()
        ) {
          return state
        }

        return {
          startDate: newStartDate,
          endDate: newEndDate,
        }
      },
    }),
    { startDate: createStartDate(), endDate: createEndDate() },
  )

  useInterval(() => {
    updateDates()
  }, 30 * 1000)

  return { startDate, endDate }
}
