import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useMedia } from 'react-use'
import routes from './features/routes'
import { MeObserver } from './features/auth/MeObserver'
import { VersionNotification } from './components/VersionNotification/VersionNotification'
import { MobileMenu } from './components/navigation/MobileMenu/MobileMenu'
import { Preloader } from './components/core/Preloader'
import { Updater } from './components/core/Updater'
import { observer } from 'mobx-react-lite'
import { AuthStoreModel, useInject } from './store'
import { AblyProvider } from './components/ably/ably'

const TheSwitch = observer(() => {
  const auth = useInject<AuthStoreModel>(({ authStore }) => authStore)

  return (
    <AblyProvider isAuthenticated={auth?.isAuthenticated}>
      <Switch>
        {routes.map(({ path, component: Component, redirect }: any) => (
          <Route path={path} key={path} exact>
            {redirect && <Redirect to={redirect} />}
            {Component && <Component />}
          </Route>
        ))}
      </Switch>
    </AblyProvider>
  )
})

const Main = () => {
  const isMobile = useMedia('(max-width: 767px)')

  return (
    <>
      <MeObserver />
      <VersionNotification />
      <Preloader />
      <Updater />
      {isMobile && <MobileMenu />}

      <TheSwitch />
    </>
  )
}

export default Main
