import React, { memo, useMemo } from 'react'
import moment from 'moment'
import compact from 'lodash/compact'
import { FormattedDate } from '../FormattedDate'
import { Box } from '../../layout/Box'

interface Props {
  date: Date
  label?: React.ReactNode
  prepositionLabel?: string
}

export const RelativeDate: React.FC<Props> = memo(({ date, label, prepositionLabel }) => {
  const momentDate = moment(date)
  const isToday = momentDate.isSame(moment(), 'd')
  const isYesterday = !isToday && momentDate.isSame(moment().add(-1, 'day'), 'd')

  const dayLabel = useMemo(() => {
    if (isToday) return null
    if (isYesterday) return 'ieri'

    return momentDate.format('D MMM')
  }, [momentDate, isToday, isYesterday])

  const labelSecondPart = useMemo(
    () => compact([dayLabel, prepositionLabel]).join(' '),
    [dayLabel, prepositionLabel],
  )

  return (
    <Box display="inline" style={{ whiteSpace: 'nowrap' }}>
      {label ? (
        <>
          <Box display="inline">{label}</Box>
        </>
      ) : null}
      {labelSecondPart} <FormattedDate date={date} format="HH:mm" />
    </Box>
  )
})

RelativeDate.defaultProps = {
  label: 'Check-in',
  //prepositionLabel: 'alle',
}
