/** @jsxRuntime classic */
/** @jsx jsx */
import React, { memo } from 'react'
import { Layout } from 'antd'
import { css, jsx, ClassNames } from '@emotion/core'
import { ErrorBoundary } from '../core/ErrorBoundary'
import { TopBar } from '../navigation/TopBar'
import { LeftPanel } from '../navigation/LeftPanel'
import { Desktop } from './Responsive'
import logo from '../../assets/images/gaudia-logo.png'

const { Content } = Layout

const style = css`
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  & .DefaultLayout-content {
    // padding: 10px;
    .DefaultLayout-content-inner {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media (min-width: 768px) {
        padding: 15px 15px;
      }
    }
  }
`

const DefaultLayout = memo((props: any) => {
  const { className, children, leftPanel, isLeftPanelVisible = true } = props

  return (
    <ClassNames>
      {({ cx }) => (
        <Layout className={cx('DefaultLayout', className)} css={style}>
          <TopBar logoSrc={logo} />
          <Layout className="ant-layout-has-sider">
            {isLeftPanelVisible && <Desktop>{leftPanel || <LeftPanel />}</Desktop>}
            <Layout className="DefaultLayout-content" id="DefaultLayout-content">
              <Content className="DefaultLayout-content-inner">
                <ErrorBoundary>{children}</ErrorBoundary>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      )}
    </ClassNames>
  )
})

export default DefaultLayout
