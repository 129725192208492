import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { usePrevious } from 'react-use'
import { useInject, VersionNotificationStoreModel } from '../../store'

const AUTOUPDATER_BLACKLIST = ['/monitor/view', '/checkin-kiosk']

export const Updater = observer(() => {
  const history = useHistory()
  const { hasUpdate, updateUI } = useInject<VersionNotificationStoreModel>(
    ({ versionNotificationStore }) => versionNotificationStore,
  )

  const pathname = history.location?.pathname
  const previousPathname = usePrevious(pathname)

  useEffect(() => {
    const changedPath = pathname !== previousPathname
    if (
      hasUpdate &&
      changedPath &&
      !AUTOUPDATER_BLACKLIST.find((b) => history.location.pathname.match(b))
    ) {
      console.log('Performing autoupdate', {
        location: history.location,
      })
      setTimeout(() => updateUI(), 200)
    }
    // eslint-disable-next-line
  }, [hasUpdate, pathname, updateUI])

  return null
})
