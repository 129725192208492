import { init } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const sentryDsn = process.env.REACT_APP_SENTRY_DSN

if (process.env.NODE_ENV === 'production' && sentryDsn) {
  init({
    dsn: sentryDsn,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    ignoreErrors: [/Non-Error exception captured/i, /Non-Error promise rejection captured/i],
  })
}
