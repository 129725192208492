import { GraphQLError } from 'graphql'
import { httpClient } from './httpClient'
import { history } from './history'

const ROUTES_WHITELIST = [/\/activate(.)/, /\/reset-password(.)/, /\/visitor(.)/, /\/v\/(.)/]

export default () => {
  httpClient.addMiddleware('pre', (args) => {})

  httpClient.addMiddleware('post', (args) => {
    const { result } = args

    const errors = (result?.data?.errors || []) as GraphQLError[]

    const hasUnauthenticatedErrors = errors.some((error) => error.extensions?.code === 401)

    const path = window.location.pathname
    const isWhitelisted = !!ROUTES_WHITELIST.find((r) => r.test(path))
    if (hasUnauthenticatedErrors && !isWhitelisted) {
      history.push('/login')
    }
  })
}
