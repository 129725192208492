import React from 'react'
import { Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import itIT from 'antd/lib/locale-provider/it_IT'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query-devtools'
import moment from 'moment'
import 'moment/locale/it'
import GlobalStyles from './style'
import { MenuProvider } from './features/MenuContext'
import { GraphqlProvider } from './lib/graphql'
import { StoreProvider, store } from './store'
import Main from './Main'
import setupMiddlewares from './lib/setupMiddlewares'
import { history } from './lib/history'

moment.locale('it')
setupMiddlewares()

const queryCache = new QueryCache()

const App: React.FC = () => {
  return (
    <>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <>
          <StoreProvider value={store}>
            <ConfigProvider locale={itIT}>
              <Router history={history}>
                <MenuProvider>
                  <GraphqlProvider>
                    <Main />
                  </GraphqlProvider>
                </MenuProvider>
              </Router>
            </ConfigProvider>
          </StoreProvider>

          {/*<ReactQueryDevtools />*/}
        </>
      </ReactQueryCacheProvider>

      <GlobalStyles />
    </>
  )
}

export default App
