import { types, Instance } from 'mobx-state-tree'
import compact from 'lodash/compact'
import trim from 'lodash/trim'
import axios from 'axios'

export const VersionNotificationStore = types
  .model({
    // updateFoundAt: types.maybeNull(types.Date),
    hasUpdate: types.maybeNull(types.boolean),
    latestVersion: types.maybeNull(types.string),
    version: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setLatestVersion(latestVersion: string) {
      self.latestVersion = latestVersion
    },
  }))
  .actions((self) => ({
    setHasUpdate(hasUpdate: boolean) {
      self.hasUpdate = hasUpdate
    },
    async fetchReleaseManifest() {
      const { origin } = window.location
      const pathParts = compact([origin, 'project.json'])
      const url = pathParts.map((p) => trim(p, '/')).join('/')

      const rand = `${Math.random()}-${Math.random()}`

      const {
        data: { version },
      } = await axios.get(url, { params: { rand } })

      self.setLatestVersion(version)

      return version
    },
    async updateUI(ignore?: boolean) {
      self.version = self.latestVersion
      self.hasUpdate = false
      // flush into localStorage

      setTimeout(() => window.location.reload())
    },
  }))

export type VersionNotificationStoreModel = Instance<typeof VersionNotificationStore>
