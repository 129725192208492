import React, { useCallback, useEffect, useState, memo } from 'react'
import { Input, Alert } from 'antd'
import * as yup from 'yup'
import LockOutlined from '@ant-design/icons/LockOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import { Form as FormikForm } from 'formik'
import { useForm } from '../../components/form/useForm'
import { FormInput } from '../../components/form/FormInput'
import { SubmitButton } from '../../components/form/SubmitButton'
import { Box } from '../layout/Box'
import { useMutation } from '../../lib/graphql'
import { CredentialsPayloadMutationInput } from '../../lib/graphql'
import Bluebird from 'bluebird'
import { useFetchIdentity } from '../useFetchIdentity'
import { Link } from 'react-router-dom'
import trim from 'lodash/trim'

const validationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(5).required(),
})

const initialValues = { email: '', password: '' }

interface Props {
  onSuccess: () => void
}

export const LoginForm: React.FC<Props> = memo(({ onSuccess }) => {
  const { Form } = useForm<CredentialsPayloadMutationInput>({
    withSuccessNotification: false,
    withErrorNotification: false,
  })
  const [success, setSuccess] = useState(false)

  const loginMutation = useMutation()
  const handleSubmit = useCallback(
    async (values) => {
      await Bluebird.delay(2000)
      await loginMutation.mutate({
        login: [
          {
            credentials: {
              email: trim(values.email),
              password: values.password,
            },
          },
          { success: true },
        ],
      })
      setSuccess(true)
    },
    [loginMutation.mutate],
  )

  const identityQuery = useFetchIdentity({ enabled: false })

  useEffect(() => {
    if (loginMutation.result?.data?.login?.success) {
      identityQuery.refetch().then(() => onSuccess())
    }
  }, [loginMutation.result?.data, identityQuery, onSuccess])

  return (
    <>
      <>
        {success && <Alert message="Login effettuato" type="success" />}
        {!success && (
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            /*validationSchema={validationSchema}
          isInitialValid={false}*/
          >
            {() => (
              <FormikForm>
                {loginMutation?.result?.errors && (
                  <Box mb={10}>
                    <Alert
                      message="Errore"
                      description="Credenziali non valide"
                      type="error"
                      closable
                    />
                  </Box>
                )}
                <FormInput
                  name="email"
                  label={
                    <div>
                      <UserOutlined /> Email
                    </div>
                  }
                  component={Input}
                />
                <FormInput
                  name="password"
                  componentProps={{ type: 'password' }}
                  label={
                    <div>
                      <LockOutlined /> Password
                    </div>
                  }
                  component={Input}
                />

                <Box mb={10}>
                  <SubmitButton block>Log in</SubmitButton>
                </Box>
                <div>
                  problemi con il login? <Link to="/reset-password-request">recupera password</Link>
                </div>
              </FormikForm>
            )}
          </Form>
        )}
      </>
    </>
  )
})

export default LoginForm
