import { types, Instance } from 'mobx-state-tree'
import customClient from '../customClient'
import { Organization } from '../lib/graphql'

const User = types.model({
  __typename: types.string,
  id: types.string,
  firstname: types.maybeNull(types.string),
  lastname: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  email: types.string,
  memberships: types.array(
    types.model({
      organization: types.model({
        id: types.string,
        name: types.string,
      }),
    }),
  ),
})

const Monitor = types.model({
  __typename: types.string,
  id: types.string,
  name: types.string,
})

const CheckinKiosk = types.model({
  __typename: types.string,
  id: types.string,
  name: types.string,
})

const Visitor = types.model({
  __typename: types.string,
  id: types.string,
  firstname: types.maybeNull(types.string),
  lastname: types.maybeNull(types.string),
})

const Identity = types.union(User, Monitor, CheckinKiosk, Visitor)

export type UserModel = Instance<typeof User>
export type MonitorModel = Instance<typeof Monitor>
export type CheckinKioskModel = Instance<typeof CheckinKiosk>
export type VisitorModel = Instance<typeof Visitor>
export type IdentityModel = UserModel | MonitorModel | CheckinKioskModel | VisitorModel

export const AuthStore = types
  .model({
    identity: types.maybeNull(Identity),
    isAuthenticated: types.optional(types.boolean, false),
    currentOrganizationId: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setAuthenticated(isAuthenticated: boolean) {
      self.isAuthenticated = isAuthenticated
    },
    setCurrentOrganizationId(organizationId: string | null) {
      self.currentOrganizationId = organizationId ?? null
    },
  }))
  .actions((self) => ({
    setIdentity(identity: IdentityModel | null) {
      self.identity = identity
      if (identity) {
        self.setAuthenticated(true)
      }
    },
  }))
  .actions((self) => ({
    async logout() {
      return customClient
        .mutation({
          logout: { success: true },
        })
        .then((result) => {
          if (!result?.errors) {
            self.setIdentity(null)
            self.setAuthenticated(false)
            self.setCurrentOrganizationId(null)
          }
        })
    },
  }))
  .views((self) => ({
    get getIdentity() {
      return self?.identity?.id ? self.identity : null
    },
    get organizations(): Organization[] {
      // @ts-ignore
      return self?.identity?.memberships?.map(({ organization }) => organization)
    },
  }))
  .views((self) => ({
    get currentOrganization() {
      return self?.organizations?.find((o) => o.id === self.currentOrganizationId)
    },
  }))

export type AuthStoreModel = Instance<typeof AuthStore>
