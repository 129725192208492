import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Card as AntdCard, Table, Input, Typography, Button, Select } from 'antd'
import { useParams, useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { EditOutlined, PrinterOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { ColumnType } from 'antd/es/table'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import styled from '@emotion/styled'
import { useClickAway, useMedia } from 'react-use'
import DefaultLayout from '../../components/layout/DefaultLayout'
import { LocationSelect } from '../../components/form/LocationSelect/LocationSelect'
import { useMutation, useRequest, Booking } from '../../lib/graphql'
import { Box } from '../../components/layout/Box'
import { ScrollingBox } from './LocationQueue/LocationQueue.styled'
import Loader from '../../components/Loader/Loader'
import { VisitorDetail } from '../../components/visitor/VisitorDetail'
import { useAblyEvent } from '../../components/ably/ably'

const Card = styled(AntdCard)`
  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .ant-card-head {
      min-height: 65px;
    }

    .ant-card-body {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      flex-grow: 1;
    }
  }
`

const { Paragraph, Text } = Typography

const AutosavingNote: React.FC<{ booking: Booking }> = ({ booking }) => {
  const [isEditing, setIsEditing] = useState(false)

  const handleClick = useCallback(() => {
    if (!isEditing) return setIsEditing(true)
  }, [isEditing])

  const ref = useRef(null)
  useClickAway(ref, () => {
    setIsEditing(false)
  })

  const updateVisitByIdMutation = useMutation()
  const saveIt = useCallback(
    _.debounce((val?: string | null) => {
      if (!booking?.id) return

      const notes = _.trim(val ?? '')

      return updateVisitByIdMutation.mutate({
        updateBookingById: [{ id: booking?.id, data: { notes } }, { id: true }],
      })
    }, 1000),
    [],
  )

  const [inputVal, setInputVal] = useState<string | null>(booking?.notes ?? '')
  const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      setInputVal(e.target.value)
      saveIt(e.target.value)
    },
    [saveIt],
  )

  useEffect(() => {
    setInputVal(booking?.notes)
  }, [booking])

  const renderedVal = useMemo(
    () =>
      inputVal?.split('\n').map(function (item) {
        return (
          <>
            {item}
            <br />
          </>
        )
      }),
    [inputVal],
  )

  return (
    <>
      <Box ref={ref} onClick={handleClick}>
        {isEditing && (
          <>
            <Input.TextArea value={inputVal ?? ''} onChange={handleChange} autoFocus autoSize />
          </>
        )}
        {!isEditing && (
          <Box display="inline">
            {inputVal && renderedVal}{' '}
            <a href="" onClick={(e) => e.preventDefault()}>
              <EditOutlined />
            </a>
          </Box>
        )}
      </Box>

      {/*<Paragraph
        editable={{
          // icon: <HighlightOutlined />,
          // tooltip: 'click to edit text',
          onChange: setInputVal,
        }}
      >
        {inputVal}
      </Paragraph>*/}
    </>
  )
}

const LocationsAgenda = observer(() => {
  const { locationId } = useParams<{ locationId?: string }>()
  const history = useHistory()
  const handleLocationSelect = useCallback(
    (locationId) => {
      history.push(`/queues/agenda/${locationId ?? ''}`)
    },
    [history],
  )

  const [selectedDate, setSelectedDate] = useState('today')
  const handleDateSelect = useCallback((date) => setSelectedDate(date), [])

  const now = useMemo(() => moment().startOf('day'), [])
  const locationQuery = useRequest(
    locationId ? { locationById: [{ id: locationId }, { name: true }] } : {},
    { enabled: locationId },
  )

  const dateCriteria = useMemo(() => {
    const criteria: any = { GTE: now }
    if (selectedDate === 'today') {
      criteria.LTE = moment().endOf('day')
    }

    return criteria
  }, [now, selectedDate])

  const locationsQuery = useRequest(
    { locations: [{ where: { queue: { hasQueue: { EQ: true } } } }, { items: { id: true } }] },
    { enabled: !locationId },
  )

  const channels = useMemo(() => {
    if (locationId) return [{ channelName: `locationQueue:${locationId}` }]

    // @ts-ignore
    return (
      locationsQuery?.data?.locations?.items?.map((location) => ({
        channelName: `locationQueue:${location?.id}`,
      })) ?? []
    )
  }, [locationId, locationsQuery])

  const ably = useAblyEvent(channels, () => {
    ably.ensureRefetch(bookingsQuery)
  })

  const bookingsQuery = useRequest(
    {
      bookings: [
        {
          where: {
            ...(locationId ? { locationId: { EQ: locationId } } : {}),
            date: dateCriteria,
          },
          sort: 'date ASC time ASC',
        },
        {
          items: {
            id: true,
            prescriptionCode: true,
            time: true,
            date: true,
            locationId: true,
            notes: true,
            location: {
              name: true,
            },
            visit: {
              createdAt: true,
              startedAt: true,
              endedAt: true,
            },
            visitor: {
              id: true,
              fullName: true,
              fiscalCode: true,
              contacts: {
                number: true,
              },
            },
          },
        },
      ],
    },
    { refetchInterval: ably.isConnected ? false : 30000 },
  )

  const ref = useRef(null)

  const getFormattedDate = useCallback((stringDate) => {
    if (!stringDate) return null

    return moment(stringDate).calendar({
      sameDay: '[Oggi]',
      nextDay: '[Domani]',
      nextWeek: 'ddd, D MMM',
      lastDay: '[Ieri]',
      lastWeek: 'ddd, D MMM',
    })
  }, [])

  const grouped = useMemo(() => {
    const datetime = _.groupBy(
      bookingsQuery?.data?.bookings?.items as Booking[],
      ({ date, time }) => {
        return `${date}-${time ?? 'notime'}`
      },
    )

    const date = _.groupBy(bookingsQuery?.data?.bookings?.items as Booking[], 'date')

    return { datetime, date }
  }, [bookingsQuery])

  const columns = useMemo<ColumnType<any>[]>(
    () => [
      {
        title: 'Data',
        dataIndex: 'date',
        // align: 'center',
        onCell: () => ({ style: { verticalAlign: 'top', whiteSpace: 'nowrap' } }),
        render(value, row, index) {
          const obj = {
            children: getFormattedDate(value),
            props: {
              width: '40px',
              rowSpan: row._dateRowSpan,
            },
          }
          return obj
        },
      },
      {
        title: 'Orario',
        dataIndex: 'time',
        // align: 'center',
        onCell: () => ({ style: { verticalAlign: 'top' } }),
        render(value, row, index) {
          const obj = {
            children: value ?? '-',
            props: {
              width: '50px',
              rowSpan: row._timeRowSpan,
            },
          }
          return obj
        },
      },
      {
        title: 'Location',
        dataIndex: ['location', 'name'],
        align: 'center',
        width: '10%',
      },
      {
        title: 'Nome',
        dataIndex: 'detail',
        // align: 'center',
        // width: '10%',
        render(value, row: Booking) {
          if (!row.visitor) return
          return <VisitorDetail visitor={row.visitor} />
        },
      },
      {
        title: 'Stato',
        dataIndex: 'visit',
        // align: 'center',
        // width: '15%',
        render(value, row: Booking) {
          const visit = row.visit
          if (!visit) return

          if (visit.endedAt) return 'Visita completata'

          if (visit.startedAt) return 'In visita'

          if (visit.createdAt) return 'In coda'
        },
      },
      {
        title: 'CF',
        dataIndex: ['visitor', 'fiscalCode'],
        // align: 'center',
      },
      {
        title: 'Telefono',
        dataIndex: ['visitor', 'contacts', 0, 'number'],
        // align: 'center',
      },
      {
        title: 'Note',
        dataIndex: 'notes',
        // // align: 'center',
        // width: '20%',
        render(value, booking: Booking) {
          return <AutosavingNote booking={booking} />
        },
      },
    ],
    [getFormattedDate],
  )

  const dataSource = useMemo(() => {
    const dateToSpan: Record<string, boolean> = {}
    const datetimeToSpan: Record<string, boolean> = {}

    return bookingsQuery.data?.bookings?.items?.map((visit) => {
      let _dateRowSpan = 0
      let _timeRowSpan = 0

      if (!dateToSpan[visit?.date]) {
        _dateRowSpan = grouped.date?.[visit?.date]?.length
        dateToSpan[visit?.date] = true
      }

      const visitDateTime = `${visit?.date}-${visit?.time ?? 'notime'}`
      if (!datetimeToSpan[visitDateTime]) {
        _timeRowSpan = grouped.datetime?.[visitDateTime]?.length
        datetimeToSpan[visitDateTime] = true
      }

      return { ...visit, _dateRowSpan, _timeRowSpan }
    })
  }, [grouped, bookingsQuery.data])

  const isMobile = useMedia('(max-width: 991px)')

  return (
    <DefaultLayout>
      <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden" p={[12, 0]}>
        {isMobile && (
          <>
            <Box>
              <Form.Item label="Location" style={{ marginBottom: 10 }} labelCol={{ span: 24 }}>
                <LocationSelect
                  style={{ width: 200 }}
                  value={locationId}
                  onChange={handleLocationSelect}
                  where={{ queue: { hasQueue: { EQ: true } } }}
                  allowClear
                />
              </Form.Item>
              <Form.Item label="Data" style={{ marginBottom: 20 }} labelCol={{ span: 24 }}>
                <Select style={{ width: 200 }} value={selectedDate} onChange={handleDateSelect}>
                  <Select.Option value="today">Oggi</Select.Option>
                  <Select.Option value="all">Tutte</Select.Option>
                </Select>
              </Form.Item>
            </Box>
          </>
        )}

        <Box display="flex" flexGrow={1} overflow="hidden">
          <Card
            title={`Agenda${
              locationQuery.data?.locationById?.name
                ? `: ${locationQuery.data?.locationById?.name}`
                : ''
            }`}
            style={{ flexGrow: 1, overflow: 'hidden' }}
            bodyStyle={{ padding: 0, display: 'flex', flexGrow: 1, height: '100%' }}
            extra={
              <Box display="flex">
                {!isMobile && (
                  <>
                    <Form.Item label="Location" style={{ marginBottom: 0, marginRight: 15 }}>
                      <LocationSelect
                        style={{ width: 200 }}
                        value={locationId}
                        onChange={handleLocationSelect}
                        where={{ queue: { hasQueue: { EQ: true } } }}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item label="Data" style={{ marginBottom: 0 }}>
                      <Select
                        style={{ width: 200 }}
                        value={selectedDate}
                        onChange={handleDateSelect}
                      >
                        <Select.Option value="today">Visite di Oggi</Select.Option>
                        <Select.Option value="all">Tutte le visite</Select.Option>
                      </Select>
                    </Form.Item>
                  </>
                )}
                <Box ml={10}>
                  <ReactToPrint
                    trigger={() => <Button icon={<PrinterOutlined />}>Stampa</Button>}
                    content={() => ref.current}
                  />
                </Box>
              </Box>
            }
          >
            <Loader isLoading={bookingsQuery?.isLoading}>
              <ScrollingBox>
                <div ref={ref}>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    size="small"
                    bordered
                  />
                </div>
              </ScrollingBox>
            </Loader>
          </Card>
        </Box>
      </Box>
    </DefaultLayout>
  )
})

export default LocationsAgenda
