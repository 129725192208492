import React, { useCallback, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Action } from '@gaudia/shared'
import { Menu, TopItem } from './menuStructure'
import _ from 'lodash'
import menuStructure from './menuStructure'
import { useUserHasPolicy } from '../components/role/userHasPolicy'

const MenuContext = React.createContext<{
  topItem: TopItem | null
  menu: Menu
  path: string | null
  isMobileMenuOpen: boolean
  setMobileMenuOpen: Function
}>({
  topItem: null,
  menu: [],
  path: null,
  isMobileMenuOpen: false,
  setMobileMenuOpen: () => {},
})

export const MenuConsumer = MenuContext.Consumer

const useMenu = () => {
  const location = useLocation()
  let activeItemKey = null
  const { verify } = useUserHasPolicy()

  const isVisible = useCallback(
    (item) => {
      return !item.policies?.length || item.policies?.some((p: Action) => verify(p))
    },
    [verify],
  )

  const menu = useMemo<Menu>(() => {
    return menuStructure.reduce((menuAcc, topItem) => {
      if (!isVisible(topItem)) return menuAcc

      // @ts-ignore
      const topItemItems = topItem.items.reduce((topItemAcc, subItem) => {
        if (!isVisible(subItem)) return topItemAcc

        // @ts-ignore
        const subItemItems = subItem.items.filter((subItemItem) => {
          return isVisible(subItemItem)
        })

        if (subItemItems.length) {
          topItemAcc.push({ ...subItem, items: subItemItems })
        }

        return topItemAcc
      }, [])

      if (topItemItems.length) {
        // @ts-ignore
        menuAcc.push({ ...topItem, items: topItemItems })
      }

      return menuAcc
    }, [])
  }, [isVisible])

  const { topItem, path } = useMemo(() => {
    // start reducer
    const result = menu.reduce<{ topItem: TopItem; path: string } | null>((acc, topItem) => {
      if (acc) return acc

      const addedMatches = _.map(topItem.matches, (path) => ({ path }))
      const allItems = _.flatMap([..._.map(topItem.items, 'items'), addedMatches])

      const hasMatch = _.find(allItems, ({ path }) =>
        matchPath(location.pathname, {
          path,
          exact: true,
        }),
      )

      if (hasMatch) {
        // @ts-ignore
        const { path } = hasMatch
        return { topItem, path }
      }

      return null
    }, null)
    // end reducer

    return result || { topItem: null, path: null }
  }, [location.pathname, menu])

  return { topItem, menu, path }
}

export const MenuProvider: React.FC<{}> = (props) => {
  let { topItem, menu, path } = useMenu()
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  let value = { topItem, menu, path, isMobileMenuOpen, setMobileMenuOpen }

  return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>
}

export default MenuContext
