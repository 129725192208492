import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { Alert, Input, Row, Col, notification } from 'antd'
import { PasswordValidator } from '@gaudia/shared'
import * as yup from 'yup'
import { Wrapper, Inner } from './Auth.styled'
import logo from '../../assets/images/gaudia-logo.png'
import { useForm } from '../../components/form/useForm'
import { FormInput } from '../../components/form/FormInput'
import { SubmitButton } from '../../components/form/SubmitButton'
import { Box } from '../../components/layout/Box'
import { useMutation } from '../../lib/graphql'

const LogoWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
  img {
    max-width: 300px;
  }
`

const validationSchema = yup.object({
  firstname: yup.string().min(2).required(),
  lastname: yup.string().min(2).required(),
  password: yup
    .string()
    .required()
    .test(
      'passwordComplexity',
      "The password doesn't fulfill the complexity requirements",
      async function (this, value) {
        const [, isValid] = PasswordValidator.validate(value)
        return isValid
      },
    ),

  passwordConfirm: yup.mixed().test({
    name: 'passwordConfirm',
    exclusive: false,
    message: 'The password confirmation must match the password',
    test: function (value: any) {
      return value === this.parent.password
    },
  }),
})

interface FormFields {
  firstname: string
  lastname: string
  password: string
  passwordConfirm: string
}

export const ActivateUser = () => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()

  const { Form } = useForm<FormFields>({ withSuccessNotification: false })

  const activateUserMutation = useMutation()
  const handleSubmit = useCallback(
    async (values: FormFields) => {
      const { password, firstname, lastname } = values
      await activateUserMutation.mutate({
        activateUser: [{ data: { password, firstname, lastname, token } }, { success: true }],
      })
      notification.success({ message: 'Account attivato con successo' })
      history.push('/')
    },
    [history, activateUserMutation.mutate, token],
  )

  return (
    <Wrapper>
      <Inner>
        <LogoWrapper>
          <img src={logo} />
        </LogoWrapper>
        <h3>Attivazione account</h3>

        <Form
          initialValues={{ firstname: '', lastname: '', password: '', passwordConfirm: '' }}
          validationSchema={validationSchema}
          isInitialValid={false}
          onSubmit={handleSubmit}
        >
          <FormInput name="firstname" label="Nome" component={Input} />
          <FormInput name="lastname" label="Cognome" component={Input} />

          <p>
            <Alert
              message={
                <div>
                  La password deve avere is seguenti requisiti: <br />
                  <ul>
                    <li>Lunghezza minima 6 caratteri</li>
                    <li>
                      Almeno 3 tra i seguenti: lettera minuscola o maiuscola, numero, carattere
                      speciale
                    </li>
                  </ul>
                </div>
              }
              type="info"
              showIcon
            />
          </p>
          <FormInput
            name="password"
            label="Nuova password"
            component={Input}
            componentProps={{ type: 'password' }}
          />
          <FormInput
            name="passwordConfirm"
            label="Reinserisci la nuova password"
            component={Input}
            componentProps={{ type: 'password' }}
          />

          <Box mb={10}>
            <SubmitButton block>Salva</SubmitButton>
          </Box>
        </Form>
      </Inner>
    </Wrapper>
  )
}

export default ActivateUser
