import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { Action } from '@gaudia/shared'
import { useFetchIdentity } from '../useFetchIdentity'
import { Policy } from '../../lib/graphql'

export const useUserHasPolicy = function () {
  const identityQuery = useFetchIdentity()
  const policies = useMemo(() => {
    if (identityQuery?.data?.identity?.__typename === 'User') {
      return _.flatMap(identityQuery?.data?.identity?.roles, (r) => r?.policies ?? []) || []
    }

    return []
  }, [identityQuery]) as Policy[]

  const verify = useCallback(
    (policyAction: Action | Action[], resourceId?: string | null) => {
      const policyActions = Array.isArray(policyAction) ? policyAction : [policyAction]
      return policies?.find(({ actions, resources, effect }) => {
        const matchingAction = actions.find(
          (action) => policyActions.includes(action as Action) || action === '*',
        )

        if (matchingAction) {
          if (resourceId) {
            return resources?.includes(resourceId) || resources?.length === 0
          }

          return effect === 'allow'
        }

        return false
      })
    },

    [policies],
  )

  return { verify }
}
