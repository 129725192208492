import { store } from '../store'

type swRegistration = any

/**
 * UpdateChecker is responsible of the checking for new versions of the UIs
 */
export default class UpdateChecker {
  store = store
  pollingInterval: number
  swRegistration: any
  isPollingRegistered: boolean
  constructor({ pollingInterval }: { pollingInterval?: number }) {
    this.pollingInterval = pollingInterval || 3600 * 1000
    this.isPollingRegistered = false
  }

  setSwRegistration(swRegistration: swRegistration) {
    this.swRegistration = swRegistration
  }

  /**
   * Method to register the interval that regularly checks for UI updates
   * preferred method is service worker, it fall back to `release.json` file checking
   */
  setupPolling() {
    if (this.isPollingRegistered) return false

    const check = () => {
      if (this.swRegistration) {
        console.log('checking for service worker update')
        return this.swRegistration.update()
      }

      console.log('checking for release.json update')
      return this.performLegacyCheck()
    }

    console.log(`setting up ${this.swRegistration ? 'Service worker polling' : 'Legacy polling'}`)
    setInterval(() => check(), this.pollingInterval)
    this.isPollingRegistered = true
  }

  async performLegacyCheck(forceReload = false) {
    const currentVersion = this.store.versionNotificationStore.version
    try {
      const version = await this.store.versionNotificationStore.fetchReleaseManifest()

      if (version && version !== currentVersion) {
        if (forceReload) {
          return this.store.versionNotificationStore.updateUI()
        }

        return this.setHasUpdate(true)
      }

      return null
    } catch (err) {
      console.error('Error while checking for new version:', err)
      throw err
    }
  }

  setHasUpdate(visibility: boolean) {
    return this.store.versionNotificationStore.setHasUpdate(visibility)
  }
}
