/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext, memo, useCallback } from 'react'
import { Layout, Menu, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import UserOutlined from '@ant-design/icons/UserOutlined'
import { ClassNames, css, jsx } from '@emotion/core'
import Hamburger from '../Hamburger/index'
import style from './style'
import { UserDropdown } from '../UserDropdown/UserDropdown'
import MenuContext from '../../../features/MenuContext'
import { TopItem } from '../../../features/menuStructure'
import { useDeviceType } from '../../mobileDetectionHooks'

const UserDropdownClass = css`
  & .UserDropdown-userLink {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const { Header } = Layout

const TopBar = memo((props: any) => {
  const { logoSrc, accountName } = props
  const { isDesktop, isMobile } = useDeviceType()

  const { topItem, menu, isMobileMenuOpen, setMobileMenuOpen } = useContext(MenuContext)
  // @ts-ignore
  const activeTopItemIndex = String(menu.indexOf(topItem))

  const handleMobileMenuClick = useCallback(() => setMobileMenuOpen(!isMobileMenuOpen), [
    isMobileMenuOpen,
    setMobileMenuOpen,
  ])

  return (
    <ClassNames>
      {({ cx }) => (
        <Header
          className={cx({
            'is-mobileMenuOpen': isMobileMenuOpen,
          })}
          css={style}
        >
          <div className="TopBar-wrapper">
            <section className="TopBar-logo">
              <Link to="/">{logoSrc && <img src={logoSrc} />}</Link>
            </section>
            <section className="TopBar-items">
              <section className="TopBar-items-left">
                {isDesktop && (
                  <Menu
                    selectable={false}
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[activeTopItemIndex || '']}
                  >
                    {menu.map((item: TopItem, i: number) => (
                      <Menu.Item key={i}>
                        {item.path ? <Link to={item.path}>{item.title}</Link> : item.title}
                      </Menu.Item>
                    ))}
                  </Menu>
                )}
              </section>
              <section className="TopBar-items-right">
                {isDesktop && (
                  <UserDropdown>
                    {({ Dropdown, username }) => (
                      <Menu css={UserDropdownClass} theme="dark" mode="horizontal">
                        <Menu.Item key={0} style={{ background: 'none !important' }}>
                          <Dropdown>
                            <a className="UserDropdown-userLink ant-dropdown-link">
                              <UserOutlined /> {username}
                            </a>
                          </Dropdown>
                        </Menu.Item>
                      </Menu>
                    )}
                  </UserDropdown>
                )}
                {isMobile && (
                  <div className="TopBar-mobileMenu" onClick={handleMobileMenuClick}>
                    <Hamburger isOpen={isMobileMenuOpen} />
                  </div>
                )}
              </section>
            </section>
          </div>
        </Header>
      )}
    </ClassNames>
  )
})

export default TopBar
