import React, { useCallback, useState } from 'react'

export const useModal = (initialState = false): [boolean, Function, Function] => {
  const [modalOpen, setModalOpen] = useState(initialState)
  const toggle = () => setModalOpen(!modalOpen)
  return [modalOpen, setModalOpen, toggle]
}

export const useModal2 = (initialState = false) => {
  const [isOpen, setOpen] = useState(initialState)
  const toggle = useCallback(() => setOpen(!isOpen), [isOpen])
  const open = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])

  return { isOpen, setOpen, toggle, open, close }
}

export default useModal
