import { Query } from 'react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import _fpSet from 'lodash/fp/set'
import { useInject } from '../../../store'
import { useDateInterval } from '../../../components/useDateInterval'
import { useQueryCache, useRequest, Visit } from '../../../lib/graphql'
import { useAblyEvent } from '../../../components/ably/ably'
import { usePreference } from '../../../components/preferencesHooks'
import { LocationQueuePreferences } from '../../../types'

export type DisabledQueue = 'waiting' | 'visited' | 'visiting'

export const useLocationQueues = ({
  locationId,
  disabledQueues,
  whitelistedTags,
  blacklistedTags,
  onVisitWaiting,
}: {
  locationId: string
  disabledQueues?: DisabledQueue[]
  whitelistedTags?: string[]
  blacklistedTags?: string[]
  onVisitWaiting?: (visit: Visit) => void
}) => {
  const { authStore } = useInject(({ authStore }) => ({ authStore }))
  const locationQueuePreferencesQuery = usePreference<LocationQueuePreferences>('locationQueue', [
    locationId,
  ])
  const uiPreferences = locationQueuePreferencesQuery.data?.fields

  const queryCache = useQueryCache()

  const [, setRefetchInterval] = useState<number | false>(2000)
  const { startDate } = useDateInterval()

  const tagsCondition = useMemo(
    () =>
      (whitelistedTags && whitelistedTags?.length > 0) ||
      (blacklistedTags && blacklistedTags?.length > 0)
        ? {
            tags: {
              text: {
                ...(whitelistedTags?.length && { IN: whitelistedTags }),
                ...(blacklistedTags?.length && { NIN: blacklistedTags }),
              },
            },
          }
        : {},
    [whitelistedTags, blacklistedTags],
  )
  /**
   * Waiting
   */

  const waiting = useRequest(
    !!locationId && [
      'locationQueueWaiting',
      {
        visits: [
          {
            where: {
              locationId: { EQ: locationId },
              status: { EQ: 'waiting' },
              OR: [{ canVisit: { EQ: true } }, { selfCheckin: { EQ: false } }],
              ...tagsCondition,
            },
            sort: 'order ASC',
          },
          {
            items: {
              id: true,
              ticketCode: true,
              order: true,
              locationId: true,
              tags: {
                text: true,
                color: true,
              },
              visitor: {
                firstname: true,
                lastname: true,
                fiscalCode: true,
                contacts: { number: true },
              },
              createdAt: true,
              updatedAt: true,
            },
          },
        ],
      },
    ],
    { enabled: true, refetchOnWindowFocus: 'always' },
  )

  const getWaitingCache = () =>
    queryCache
      .getQueries()
      .find((q: any) => q.queryKey[0] === 'locationQueueWaiting') as unknown as Query<any, any>

  /**
   * Visiting
   */

  const visiting = useRequest(
    !!locationId &&
      !!authStore?.identity?.id && [
        'locationQueueVisiting',
        {
          visits: [
            {
              where: {
                locationId: { EQ: locationId },
                status: { EQ: 'started' },
                ...(uiPreferences?.startedList?.showOnlyStartedByMyself
                  ? { startedById: { EQ: authStore?.identity?.id } }
                  : {}),
                ...tagsCondition,
              },
              sort: 'startedAt DESC',
            },
            {
              items: {
                id: true,
                ticketCode: true,
                startedAt: true,
                locationId: true,
                tags: {
                  text: true,
                  color: true,
                },
                visitor: { firstname: true, lastname: true, fiscalCode: true },
                startedBy: { id: true, firstname: true, lastname: true },
                updatedAt: true,
              },
            },
          ],
        },
      ],
    { enabled: true, refetchOnWindowFocus: 'always' },
  )

  const getVisitingCache = () =>
    queryCache
      .getQueries()
      .find((q: any) => q.queryKey[0] === 'locationQueueVisiting') as unknown as Query<any, any>

  /**
   * Visited
   */

  const visited = useRequest(
    !!locationId && [
      'locationQueueVisited',
      {
        visits: [
          {
            where: {
              status: { EQ: 'ended' },
              locationId: { EQ: locationId },
              endedAt: {
                GTE: startDate,
              },
              ...tagsCondition,
            },
            sort: 'endedAt DESC',
          },
          {
            items: {
              id: true,
              ticketCode: true,
              startedAt: true,
              endedAt: true,
              locationId: true,
              tags: {
                text: true,
                color: true,
              },
              visitor: { firstname: true, lastname: true, fiscalCode: true },
              startedBy: { id: true, firstname: true, lastname: true },
              updatedAt: true,
            },
          },
        ],
      },
    ],
    { enabled: !disabledQueues?.includes('visited'), refetchOnWindowFocus: 'always' },
  )

  const getVisitedCache = () =>
    queryCache
      .getQueries()
      .find((q: any) => q.queryKey[0] === 'locationQueueVisited') as unknown as Query<any, any>

  const upsertInCache = (
    visit: Visit,
    cache: Query<any, any>,
    orderByCriteria?: [string, 'asc' | 'desc'],
  ) => {
    const items = cache.state.data?.data.visits.items

    const existingItem = find(cache.state.data.data.visits.items, {
      id: visit?.id,
    })

    let newItems = [...items]

    if (existingItem && existingItem?.updatedAt) {
      const itemIndex = cache?.state?.data?.data?.visits?.items?.indexOf(existingItem)

      const existingDate = existingItem?.updatedAt ? new Date(existingItem?.updatedAt) : 0
      const newDate = visit?.updatedAt ? new Date(visit?.updatedAt) : 0

      if (newDate > existingDate) {
        newItems[itemIndex] = visit
      }
    } else {
      newItems.push(visit)
    }

    newItems = orderByCriteria
      ? orderBy(newItems, orderByCriteria[0], orderByCriteria[1])
      : newItems

    const updatedData = _fpSet('data.visits.items', newItems, cache.state.data ?? {})

    cache.setData(updatedData)
  }

  const removeFromCache = (visit: Visit, cache: Query<any, any>) => {
    const items = cache.state.data?.data?.visits?.items ?? []

    const newItems = items.filter((item: Visit) => item?.id !== visit?.id)

    const updatedData = _fpSet('data.visits.items', newItems, cache.state?.data ?? {})

    cache.setData(updatedData)
  }

  const refetch = useCallback(() => {
    ably.ensureRefetch(waiting)
    ably.ensureRefetch(visiting)

    if (!disabledQueues?.includes('visited')) {
      ably.ensureRefetch(visited)
    }
  }, [])

  const ably = useAblyEvent(
    [{ channelName: `locationQueue:${locationId}` }],
    useCallback((message: any) => {
      const visit: Visit = message.data?.visit
      if (!visit) {
        return refetch()
      }

      const result = (() => {
        // if visits has tags not in the whitelist => early return
        if (
          whitelistedTags &&
          whitelistedTags?.length > 0 &&
          !visit?.tags?.find((tag) => tag?.text && whitelistedTags?.includes(tag?.text))
        ) {
          return null
        }

        // if visits has tags in the blacklist => early return
        if (
          blacklistedTags &&
          blacklistedTags?.length > 0 &&
          visit?.tags?.find((tag) => tag?.text && blacklistedTags?.includes(tag?.text))
        ) {
          return null
        }

        // new waiting visit
        if (!visit.startedAt && !visit.endedAt && (visit?.canVisit || visit.selfCheckin)) {
          onVisitWaiting?.(visit)

          return {
            matchingCache: getWaitingCache(),
            notMatchingCaches: [getVisitingCache(), getVisitedCache()],
            sort: ['order', 'asc'] as [string, 'asc' | 'desc'],
          }
        }

        if (visit.startedAt && !visit.endedAt) {
          const cache = (() => {
            const showOnlyStartedByMyself = uiPreferences?.startedList?.showOnlyStartedByMyself
            const startedByMe = visit.startedById === authStore?.identity?.id

            if (!showOnlyStartedByMyself || (showOnlyStartedByMyself && startedByMe)) {
              return getVisitingCache()
            }
          })()

          return {
            matchingCache: cache,
            notMatchingCaches: [getWaitingCache(), getVisitedCache()],
            sort: ['startedAt', 'desc'] as [string, 'asc' | 'desc'],
          }
        }

        if (visit.endedAt) {
          return {
            matchingCache: !disabledQueues?.includes('visited') ? getVisitedCache() : null,
            notMatchingCaches: [getWaitingCache(), getVisitingCache()],
            sort: ['endedAt', 'desc'] as [string, 'asc' | 'desc'],
          }
        }
      })()

      if (result?.matchingCache) {
        upsertInCache(visit, result?.matchingCache, result?.sort)
      }

      if (result?.notMatchingCaches?.length) {
        result?.notMatchingCaches?.forEach((cache) => cache && removeFromCache(visit, cache))
      }
    }, []),
    { onRecover: refetch },
  )

  useAblyEvent(
    [{ channelName: `locationQueue:${locationId}`, eventName: 'refresh' }],
    useCallback(() => refetch(), []),
  )

  useEffect(() => {
    if (ably.isConnected) {
      setRefetchInterval(false)
    } else {
      setRefetchInterval(2000)
    }
  }, [ably.isConnected])

  return { waiting, visited, visiting, refetch, ably }
}
