import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import Loadable from 'react-loadable'

export const Preloader = observer(() => {
  const history = useHistory()
  const preloadedRef = useRef<boolean | null>()
  useEffect(() => {
    if (preloadedRef.current) return
    if (history.location?.pathname?.includes('/settings')) {
      Loadable.preloadAll()
      preloadedRef.current = true
    }
    // eslint-disable-next-line
  }, [history?.location])

  return null
})
