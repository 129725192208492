import Login from './Login'
import ActivateUser from './ActivateUser'
import ResetPassword from './ResetPassword'
import ResetPasswordRequest from './ResetPasswordRequest'
import SelectOrganization from './SelectOrganization'

export default [
  { path: '/login', component: Login },
  { path: '/activate/:token', component: ActivateUser },
  { path: '/reset-password/:token', component: ResetPassword },
  { path: '/reset-password-request', component: ResetPasswordRequest },
  { path: '/select-organization', component: SelectOrganization },
]
