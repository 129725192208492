import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import { Input, notification } from 'antd'
import * as yup from 'yup'
import { Wrapper, Inner } from './Auth.styled'
import logo from '../../assets/images/gaudia-logo.png'
import { useForm } from '../../components/form/useForm'
import { FormInput } from '../../components/form/FormInput'
import { SubmitButton } from '../../components/form/SubmitButton'
import { Box } from '../../components/layout/Box'
import { useMutation } from '../../lib/graphql'
import { Link } from 'react-router-dom'

const LogoWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
  img {
    max-width: 300px;
  }
`

const validationSchema = yup.object({
  email: yup.string().required().email(),
})

interface FormFields {
  email: string
}

export const ResetPasswordRequest = () => {
  const history = useHistory()

  const { Form } = useForm<FormFields>({ withSuccessNotification: false })

  const resetPasswordRequestMutation = useMutation()
  const handleSubmit = useCallback(
    async (values: FormFields) => {
      const { email } = values
      await resetPasswordRequestMutation.mutate({
        resetPasswordRequest: [{ data: { email } }, { success: true }],
      })
      notification.success({ message: 'Email per il reset password inviata.' })
      history.push('/')
    },
    [history, resetPasswordRequestMutation.mutate],
  )

  return (
    <Wrapper>
      <Inner>
        <LogoWrapper>
          <img src={logo} />
        </LogoWrapper>
        <h3>Reimposta la password</h3>

        <Form
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          isInitialValid={false}
          onSubmit={handleSubmit}
        >
          <FormInput
            name="email"
            label="Inserisci l'indirizzo email di cui non ricordi la password"
            component={Input}
            componentProps={{ type: 'email' }}
          />

          <Box mb={10}>
            <SubmitButton block>Invia</SubmitButton>
          </Box>
          <Box textAlign="center">
            <Link to="/login">torna al login</Link>
          </Box>
        </Form>
      </Inner>
    </Wrapper>
  )
}

export default ResetPasswordRequest
