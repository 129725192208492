import React from 'react'
import styled from '@emotion/styled'
import kioskBg from '../../assets/images/kiosk-bg.svg'

export const Wrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${kioskBg});
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`

export const BgLayout: React.FC = ({ children }) => <Wrapper>{children}</Wrapper>
