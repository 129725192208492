import { Button, List, Modal } from 'antd'
import React, { useCallback } from 'react'
import moment from 'moment'
import { useRequest } from '../../../lib/graphql'
import { useDateInterval } from '../../../components/useDateInterval'
import { Box } from '../../../components/layout/Box'
import { VisitorDetail } from '../../../components/visitor/VisitorDetail'

interface Props {
  locationId: string
  visible: boolean
  onCancel: () => void
}

export const ViewAllBookingsModal: React.FC<Props> = (props) => {
  const { locationId, visible, onCancel } = props
  const { startDate, endDate } = useDateInterval()
  const bookingsQuery = useRequest(
    visible && locationId
      ? {
          bookings: [
            {
              where: {
                locationId: { EQ: locationId },
                date: { GTE: startDate, LTE: endDate },
              },
              sort: 'date ASC time ASC',
            },
            {
              items: {
                id: true,
                prescriptionCode: true,
                time: true,
                date: true,
                visit: {
                  startedAt: true,
                  endedAt: true,
                  location: {
                    name: true,
                  },
                },
                visitor: {
                  id: true,
                  fullName: true,
                },
              },
            },
          ],
        }
      : null,
    { enabled: visible && locationId },
  )

  const getFormattedDate = useCallback((stringDate) => {
    if (!stringDate) return null
    return moment(stringDate).calendar({
      sameDay: '[Oggi]',
      nextDay: '[Domani]',
      nextWeek: 'dddd, DD MMMM',
      lastDay: '[Ieri]',
      lastWeek: '[Lo scorso] dddd, DD MMMM',
      sameElse: 'DD/MM/YYYY',
    })
  }, [])

  return (
    <Modal
      title={'Tutte le visite previste per oggi'}
      destroyOnClose={true}
      visible={visible}
      footer={[<Button onClick={() => onCancel()}>Chiudi</Button>]}
      onCancel={() => onCancel()}
    >
      <List
        dataSource={bookingsQuery.data?.bookings?.items || []}
        locale={{ emptyText: 'Nessuna visita prevista' }}
        loading={bookingsQuery.isFetchingInitial}
        renderItem={(visit) =>
          visit && (
            <List.Item
              actions={
                [
                  /*<Button
                  size="small"
                  type="primary"
                  onClick={() => !!item && handleCompleteVisitClick(item)}
                >
                  Visita completata
                </Button>,
                <Button size="small" icon={<EllipsisOutlined />} />,*/
                ]
              }
            >
              <List.Item.Meta title={<VisitorDetail visitor={visit?.visitor} />} />

              <Box textAlign="right">
                {visit?.date && (
                  <>
                    <Box>Data: {getFormattedDate(visit?.date)}</Box>
                    {!!visit?.time && <Box>Orario: {visit?.time}</Box>}
                  </>
                )}
              </Box>
            </List.Item>
          )
        }
      />
    </Modal>
  )
}

ViewAllBookingsModal.defaultProps = {
  onCancel: () => {},
}
