import React from 'react'
import ReactDOM from 'react-dom'
import { persist } from 'mst-persist'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import './lib/setupMiddlewares'
import App from './App'
import { store } from './store'
import registerServiceWorker from './lib/registerServiceWorker'
import './lib/logrocketInit'
import './lib/sentryInit'
import packageJson from '../package.json'
import { httpClient } from './lib/httpClient'

persist('gaudia', store, {
  whitelist: [
    'queueStore',
    'locationQueueStore',
    'dashboardStore',
    'versionNotificationStore',
    'authStore',
  ],
}).finally(render)

function render() {
  ReactDOM.render(<App />, document.getElementById('root'))
}

httpClient.addMiddleware('pre', ({ headers }) => {
  headers['x-gaudia-ui-version'] = packageJson.version

  if (store.authStore?.currentOrganizationId) {
    headers['x-gaudia-organizationid'] = store.authStore?.currentOrganizationId
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker()
