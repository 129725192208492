import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { Button } from 'antd'
import { Wrapper, Inner } from './Auth.styled'
import logo from '../../assets/images/gaudia-logo.png'
import { AuthStoreModel, useInject } from '../../store'
import { Organization } from '../../lib/graphql'
import { observer } from 'mobx-react-lite'
import { Box } from '../../components/layout/Box'
import { useHistory } from 'react-router'

const LogoWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
  img {
    max-width: 300px;
  }
`

export const SelectOrganization = observer(() => {
  const auth = useInject<AuthStoreModel>(({ authStore }) => authStore)
  const organizations = auth?.organizations as Organization[]
  const history = useHistory()
  const setCurrentOrg = useCallback(
    (organizationId) => {
      auth.setCurrentOrganizationId(organizationId)
      history.push('/')
    },
    [auth, history],
  )

  return (
    <Wrapper>
      <Inner>
        <LogoWrapper>
          <img src={logo} />
        </LogoWrapper>
        <Box mb={5}>
          <h3>Per favore, seleziona l'organizzazione alla quale vuoi accedere</h3>
        </Box>

        <div>
          {organizations?.map((organization) => (
            <Box mb={3}>
              <Button block onClick={() => setCurrentOrg(organization.id)}>
                {organization.name}
              </Button>
            </Box>
          ))}
        </div>
      </Inner>
    </Wrapper>
  )
})

export default SelectOrganization
