import React, { memo, useCallback, useContext } from 'react'
import { Alert, Button, Input, List, Modal, Radio } from 'antd'
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled'
import { useForm } from '../../../components/form/useForm'
import { Form as FormikForm } from 'formik'
import { useMutation, useRequest, Visit } from '../../../lib/graphql'
import { Name } from '../../../components/format/Name'
import { Loader } from '../../../components/Loader/Loader'
import { FormActions } from '../../../components/form/FormActions'
import { SubmitButton } from '../../../components/form/SubmitButton'
import { FormInput } from '../../../components/form/FormInput'
import { Box } from '../../../components/layout/Box'
import { LocationLabelsContext } from './locationLabelsContext'
import Bluebird from 'bluebird'

interface Props {
  visible?: boolean
  inTransferVisits?: Visit[] | null
  onCancel: () => void
  onSuccess?: (inTransferVisits: Visit[]) => void
}

interface FormProps {
  locationId?: string | null
  note?: string | null
}

export const TransferModal: React.FC<Props> = memo((props) => {
  return (
    <Modal
      title="Trasferisci in nuova location"
      footer={null}
      destroyOnClose={true}
      visible={props.visible}
      onCancel={() => props?.onCancel()}
    >
      <TransferModalInner {...props} />
    </Modal>
  )
})

const TransferModalInner: React.FC<Props> = (props) => {
  const labels = useContext(LocationLabelsContext)
  const { inTransferVisits, onCancel, onSuccess, visible } = props
  const locationsSelectionQuery = useRequest(
    !!inTransferVisits && {
      locationsForTransfer: [
        {
          where: {
            _id: { NOT: { IN: [inTransferVisits[0].locationId] } },
            queue: { hasQueue: { EQ: true } },
          },
        },
        { items: { id: true, name: true, color: true, shortName: true } },
      ],
    },
    { enabled: inTransferVisits },
  )

  const { Form } = useForm<FormProps>({ withSuccessNotification: false })

  const transferVisitorMutation = useMutation()
  const handleSubmit = useCallback(
    async ({ locationId, note }: FormProps) => {
      if (!inTransferVisits?.length || !locationId) return

      await Bluebird.map(
        inTransferVisits,
        async (visit) =>
          visit.id &&
          (await transferVisitorMutation.mutate({
            transferVisitor: [
              {
                data: {
                  visitId: visit.id,
                  destinationLocationId: locationId,
                  note,
                },
              },
              { ticketCode: true, location: { name: true } },
            ],
          })),
        { concurrency: 10 },
      )

      await onSuccess?.(inTransferVisits)
    },
    [inTransferVisits, transferVisitorMutation.mutate, onSuccess],
  )

  return (
    <>
      {transferVisitorMutation.result?.data?.transferVisitor?.ticketCode && (
        <>
          <Box mb={20}>
            <Alert message="Transferimento effettuato con successo" type="success" showIcon />
          </Box>

          <p>
            Il {labels?.visitor?.name} é trasferito alla nuova location{' '}
            {transferVisitorMutation.result?.data?.transferVisitor?.location?.name}
          </p>
          <p>
            Il nuovo ticket é{' '}
            <b>{transferVisitorMutation.result?.data?.transferVisitor?.ticketCode}</b>
          </p>

          <FormActions>
            <Button onClick={() => onCancel()}>Chiudi</Button>
          </FormActions>
        </>
      )}

      {!transferVisitorMutation.result?.data?.transferVisitor?.ticketCode && (
        <>
          <p>
            Transferisci gli ospiti{' '}
            {inTransferVisits?.map(
              ({ visitor }) =>
                visitor && (
                  <div>
                    <b>
                      <Name person={visitor} />
                    </b>
                  </div>
                ),
            )}
          </p>
          <p>Seleziona la nuova location:</p>
          <Form initialValues={{ locationId: null, note: null }} onSubmit={handleSubmit}>
            {(formikBag) => (
              <FormikForm>
                <Loader isLoading={locationsSelectionQuery.isFetchingInitial}>
                  <List
                    dataSource={locationsSelectionQuery.data?.locationsForTransfer?.items || []}
                    renderItem={(location) =>
                      location && (
                        <List.Item
                          actions={[
                            /*<Button
                          size="small"
                          onClick={() => formikBag.setValues({ locationId: location.id })}
                        >
                          Seleziona
                        </Button>,*/
                            <Radio.Group
                              defaultValue={null}
                              size="small"
                              value={formikBag.values.locationId}
                            >
                              <Radio.Button
                                value={location.id}
                                onClick={() => formikBag.setValues({ locationId: location.id })}
                              >
                                {location.id === formikBag.values.locationId && (
                                  <CheckCircleFilled />
                                )}{' '}
                                Seleziona
                              </Radio.Button>
                            </Radio.Group>,
                          ]}
                        >
                          <List.Item.Meta title={location?.name || ''} />
                        </List.Item>
                      )
                    }
                  />

                  <FormInput name="note" component={Input.TextArea} label="Nota (opzionale)" />

                  <FormActions textAlign="right">
                    <Button onClick={() => onCancel()}>Annulla</Button>
                    <SubmitButton size="middle" disabled={!formikBag.values.locationId} />
                  </FormActions>
                </Loader>
              </FormikForm>
            )}
          </Form>
        </>
      )}
    </>
  )
}
