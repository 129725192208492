import { useEffect } from 'react'
import { QueryConfig } from 'react-query'
import { ExecutionResult } from 'graphql'
import { useHistory } from 'react-router'
import { AuthStoreModel, IdentityModel, useInject } from '../store'
import { Query, useRequest, User } from '../lib/graphql'

export function useFetchIdentity(options?: QueryConfig<ExecutionResult<Query>>) {
  const history = useHistory()
  const auth = useInject<AuthStoreModel>(({ authStore }) => authStore)

  const identityQuery = useRequest(
    {
      identity: {
        __typename: true,
        on_Monitor: { id: true, name: true },
        on_User: {
          id: true,
          firstname: true,
          lastname: true,
          fullName: true,
          email: true,
          roles: {
            name: true,
            policies: { actions: true, effect: true, resources: true },
          },
          memberships: {
            organization: {
              name: true,
              id: true,
            },
          },
        },
        on_CheckinKiosk: { id: true, name: true },
        on_Visitor: { id: true, firstname: true, lastname: true },
      },
    },
    { refetchOnWindowFocus: false, ...options },
  )

  // as soon as we receive an identity from the server, we store it
  useEffect(() => {
    if (identityQuery.data && !identityQuery.loading) {
      auth.setIdentity(identityQuery.data.identity as IdentityModel)
    }
    // eslint-disable-next-line
  }, [identityQuery.data?.identity?.id, identityQuery.loading])

  // if we don't have the identity stored, let's fetch it
  useEffect(() => {
    if (auth.isAuthenticated && !auth.identity && !identityQuery.errors?.length) {
      identityQuery.refetch()
    }
    // eslint-disable-next-line
  }, [auth.isAuthenticated, auth.identity?.id, identityQuery.errors])

  // test, lets set the currentOrganizationId
  useEffect(() => {
    if (identityQuery?.data?.identity?.__typename === 'User' && !identityQuery.loading) {
      const user = identityQuery.data?.identity as User
      // @ts-ignore
      const hasMultipleOrg = user?.memberships?.length > 1
      const currentOrganizationId = auth?.currentOrganizationId

      if (hasMultipleOrg && !currentOrganizationId) {
        history.push('/select-organization')
      }
    }
    // eslint-disable-next-line
  }, [identityQuery.data?.identity?.id, identityQuery.loading])

  return identityQuery
}
