import React from 'react'
import { observer } from 'mobx-react-lite'
import { Visitor } from '../../../lib/graphql'
import { Box } from '../../../components/layout/Box'
import { usePreference } from '../../../components/preferencesHooks'
import { LocationQueuePreferences } from '../../../types'

export interface ListItemAdditionalFieldsProps {
  visitor?: Visitor | null
  locationId: string
}

export const ListItemAdditionalFields: React.FC<ListItemAdditionalFieldsProps> = observer(
  ({ visitor, locationId }) => {
    const locationQueuePreferencesQuery = usePreference<LocationQueuePreferences>('locationQueue', [
      locationId,
    ])
    const uiPreferences = locationQueuePreferencesQuery.data?.fields

    return (
      <>
        {uiPreferences?.additionalVisibleFields?.map((fieldName) => (
          <Box fontWeight={'normal'}>
            {fieldName === 'fiscalCode' && <div>{visitor?.[fieldName]}</div>}
            {fieldName === 'phoneNumbers' && visitor?.contacts?.map((c) => c?.number)?.join(', ')}
          </Box>
        ))}
      </>
    )
  },
)
