import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import { Wrapper, Inner } from './Auth.styled'
import { LoginForm } from '../../components/auth/LoginForm'
import logo from '../../assets/images/gaudia-logo.png'

const LogoWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
  img {
    max-width: 300px;
  }
`

export const Login = () => {
  const history = useHistory()

  const handleLogin = useCallback(() => history.push('/'), [])

  return (
    <Wrapper>
      <Inner>
        <LogoWrapper>
          <img src={logo} />
        </LogoWrapper>
        <LoginForm onSuccess={handleLogin} />
      </Inner>
    </Wrapper>
  )
}

export default Login
