import { css } from '@emotion/core'

export default css`
  & {
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    padding-bottom: 50px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    z-index: 5;
    display: none;
    @media (min-width: 768px) {
      display: block;
      width: 140px !important;
      min-width: 140px !important;
      flex: 0 0 140px !important;
    }
    @media (min-width: 992px) {
      display: block;
      width: 200px !important;
      min-width: 200px !important;
      flex: 0 0 200px !important;
    }
    > .ant-layout-sider-children {
      height: auto;
      min-height: 100%;
    }
    .ant-menu-item {
      padding: 12px 12px !important;
      margin: 0 !important;
      height: auto !important;
      line-height: 1.2 !important;
      // font-size: 12px;
      &:first-child {
        margin-top: 0;
      }
      a {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ant-menu-item-group {
      .ant-menu-item-group-title {
        display: block;
        // margin-bottom: 5px;
        // padding: 10px 15px;
        border-bottom: 1px solid #dce2e2;
        color: #3a3f51;
        text-transform: uppercase;
        font-weight: 700;
        // font-size: 12px;
        padding: 8px 12px !important;
      }
    }
  }
`
