import React from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { connect, FormikContextType } from 'formik'

interface Props extends ButtonProps {
  formik?: FormikContextType<{}>
  loadingText?: string
  disabled?: boolean
}

export const SubmitButton: React.FC<Props> = props => {
  const { children, formik, disabled, loadingText } = props
  const isValid = formik && formik.isValid

  const content = children || 'Salva'
  return (
    <Button
      type="primary"
      htmlType="submit"
      loading={formik && formik.isSubmitting}
      size="large"
      disabled={!isValid || disabled}
      {...props}
    >
      {formik && formik.isSubmitting ? loadingText || content : content}
    </Button>
  )
}

// @ts-ignore
export default connect(SubmitButton)
