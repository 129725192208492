import React from 'react'
import styled from '@emotion/styled'
import { Box } from '../../layout/Box'

export const FormActions = styled(Box)`
  & {
    margin-top: 30px;
    border-top: 1px solid #e9e9e9;
    padding: 10px 0px 0px 0px;
    //text-align: right;
    border-radius: 0 0 4px 4px;
    text-align: right;
    button {
      margin-left: 10px;
    }
  }
`
export default FormActions
