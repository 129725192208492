import { types, Instance } from 'mobx-state-tree'
export const DashboardStore = types
  .model({
    relativeTime: types.maybeNull(types.string),
  })
  .actions((self) => ({
    selectRelativeTime(selectedRelativeTime: string | null) {
      self.relativeTime = selectedRelativeTime
    },
  }))

export type DashboardStoreModel = Instance<typeof DashboardStore>
