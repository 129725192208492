import React, { useMemo, useState } from 'react'
import { Popover, Descriptions, Skeleton } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useRequest, Visitor } from '../../lib/graphql'
import { Name } from '../format/Name'
import { Box } from '../layout/Box'

export const SkeletonInfo: React.FC<{ isLoading: boolean }> = ({ children, isLoading }) => {
  if (isLoading) return <Skeleton.Input active size="small" style={{ minWidth: 100 }} />

  return <>{children}</>
}

export const VisitorDetail: React.FC<{
  visitor?: Visitor | null
  visitorId?: string
  withDetail?: boolean
}> = ({ visitor, visitorId, withDetail }) => {
  const validVisitorRecord = useMemo(() => {
    if (visitor?.id && visitor?.fullName) return true

    return !!((visitor?.id && visitor?.firstname) || visitor?.lastname)
  }, [visitor])

  const visitorQuery = useRequest(
    {
      visitorById: [{ id: visitor?.id ?? visitorId }, { id: true, fullName: true }],
    },
    { enabled: !validVisitorRecord },
  )

  const visitorData = useMemo(() => {
    if (validVisitorRecord) return visitor

    return visitorQuery?.data?.visitorById
  }, [validVisitorRecord, visitor, visitorQuery])

  const [isPopoverVisible, setPopoverVisible] = useState(false)

  const extendedVisitorQuery = useRequest(
    {
      visitorById: [
        { id: visitor?.id ?? visitorId },
        { id: true, firstname: true, lastname: true, fiscalCode: true, contacts: { number: true } },
      ],
    },
    { enabled: isPopoverVisible, cacheTime: 1000 * 60, staleTime: 1000 * 60 },
  )

  const extendedVisitorData = useMemo(() => extendedVisitorQuery?.data?.visitorById, [
    extendedVisitorQuery,
  ])

  return (
    <>
      {visitorData && (
        <>
          <Name person={visitorData} />{' '}
          {withDetail && (
            <Popover
              content={
                <Box maxWidth={['none', '400px']}>
                  <Descriptions title="Dettagli" size="small" layout="vertical">
                    <Descriptions.Item label="Nome" style={{ wordBreak: 'break-word' }}>
                      <SkeletonInfo isLoading={extendedVisitorQuery.isLoading}>
                        {extendedVisitorData?.firstname}
                      </SkeletonInfo>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cognome" style={{ wordBreak: 'break-word' }}>
                      <SkeletonInfo isLoading={extendedVisitorQuery.isLoading}>
                        {extendedVisitorData?.lastname}
                      </SkeletonInfo>
                    </Descriptions.Item>
                    <Descriptions.Item label="Codice Fiscale" style={{ wordBreak: 'break-word' }}>
                      <SkeletonInfo isLoading={extendedVisitorQuery.isLoading}>
                        {extendedVisitorData?.fiscalCode}
                      </SkeletonInfo>
                    </Descriptions.Item>
                    <Descriptions.Item label="Telefono" style={{ wordBreak: 'break-word' }}>
                      <SkeletonInfo isLoading={extendedVisitorQuery.isLoading}>
                        {extendedVisitorData?.contacts?.[0]?.number}
                      </SkeletonInfo>
                    </Descriptions.Item>
                  </Descriptions>
                </Box>
              }
              onVisibleChange={(visible) => setPopoverVisible(visible)}
              trigger="click"
            >
              <InfoCircleOutlined style={{ fontSize: 10 }} />
            </Popover>
          )}
        </>
      )}
    </>
  )
}

VisitorDetail.defaultProps = {
  withDetail: true,
}
