import React, { useCallback, useContext } from 'react'
import moment from 'moment'
import { Box } from '../../../components/layout/Box'
import { ensureRefetch, useMutation, UseQueryResult, Visit, Visitor } from '../../../lib/graphql'
import { LocationLabelsContext } from './locationLabelsContext'
import { MoveTo } from './AmendVisitModal'
import { RelativeDate } from '../../../components/format/RelativeDate/RelativeDate'
import { VisitsList } from './VisitsList'
import { Modal, notification, Tag } from 'antd'
import { Desktop } from '../../../components/layout/Responsive'
import { ListItemTitle } from './ListItemTitle'
import upperFirst from 'lodash/upperFirst'
import { Name } from '../../../components/format/Name'
import to from 'await-to-js'
import { useCommonActions } from './useCommonActions'
import { useParams } from 'react-router-dom'
import { ListItemAdditionalFields } from './ListItemAdditionalFields'

interface Props {
  visited: UseQueryResult
  onComplete?: Function
  onMoveRequest?: ({
    currentStatus,
    visit,
  }: {
    currentStatus: MoveTo | 'ended'
    visit: Visit
  }) => any
}

export const Visited: React.FC<Props> = ({ visited, onMoveRequest }) => {
  const { locationId } = useParams<{ locationId: string }>()
  const labels = useContext(LocationLabelsContext)
  const commonActions = useCommonActions()
  const getDuration = useCallback((startTime, endTime) => {
    const startMoment = moment(startTime)
    const endMoment = moment(endTime)
    const duration = moment.duration(endMoment.diff(startMoment))
    return duration.humanize()
  }, [])

  const callVisitorMutation = useMutation()
  const handleCallAgainClick = useCallback(
    (visit: Visit) => {
      Modal.confirm({
        title: `Sei sicuro di voler chiamare di nuovo l'utente?`,
        content: (
          <>
            <div>
              {upperFirst(labels?.visitor?.name || '')}:{' '}
              <b>
                <Name person={visit?.visitor as Visitor} />
              </b>
            </div>
            <div>
              Numero: <b>{visit.ticketCode}</b>
            </div>
          </>
        ),
        async onOk() {
          if (!visit.id) return
          const [err] = await to(
            callVisitorMutation.mutate({
              callVisit: [{ visitId: visit.id, isRecall: true }, { id: true }],
            }),
          )

          if (err) {
            return notification.warn({
              message: `Chiamata non effettuata`,
              duration: 8,
              description: (
                <>
                  <Box mb={20}>
                    Non è stato possibile chiamare
                    <div>
                      {upperFirst(labels?.visitor?.name || '')}:{' '}
                      <b>
                        <Name person={visit?.visitor as Visitor} />
                      </b>
                    </div>
                    <div>
                      Numero: <b>{visit.ticketCode}</b>
                    </div>
                  </Box>
                  <div>In quanto è stato appena chiamato da un altro operatore</div>
                </>
              ),
            })
          }

          notification.success({ message: `${upperFirst(labels?.visitor?.name ?? '')} chiamato` })

          // onCall?.()
        },
      })
    },
    [labels?.visitor?.name, callVisitorMutation],
  )

  return (
    <>
      <Desktop>
        <Box mb={10}>
          <h2 style={{ display: 'inline' }}>Completate</h2>{' '}
          {!visited.isFetchingInitial && `(${visited?.data?.visits?.items?.length})`}
        </Box>
      </Desktop>
      <VisitsList
        isLoading={visited?.isFetchingInitial}
        visits={visited?.data?.visits?.items as Visit[]}
        listItemProps={(item) => ({
          content: (
            <Box>
              <ListItemTitle visit={item} />

              <Box fontSize={13} mb={1} style={{ wordWrap: 'break-word' }}>
                {item?.startedAt && (
                  <>
                    <RelativeDate label="chiamato: " date={item?.startedAt} />
                    ,&nbsp;
                  </>
                )}
                <RelativeDate label="check-out: " date={item?.endedAt} />
                {item?.startedAt && item?.endedAt && (
                  <>
                    ,&nbsp;
                    <span style={{ whiteSpace: 'nowrap' }}>
                      durata: {getDuration(item?.startedAt, item?.endedAt)}
                    </span>
                  </>
                )}
              </Box>

              <ListItemAdditionalFields visitor={item.visitor} locationId={item.locationId!} />

              {item?.tags?.map((tag, tagIndex) => (
                <Box display="inline-block" mt={1}>
                  <Tag color={tag?.color ?? 'default'}>{tag?.text}</Tag>
                </Box>
              ))}
            </Box>
          ),
          actions: [
            {
              text: "Sposta in un'altra coda",
              onClick: () => onMoveRequest?.({ visit: item, currentStatus: 'ended' }),
            },
            {
              text: 'Chiama di nuovo',
              onClick: () => handleCallAgainClick(item),
            },
            {
              text: 'Elimina',
              onClick: () =>
                commonActions.deleteVisitById.run([item], () => ensureRefetch(visited)),
            },
          ],
        })}
        emptyList={`Nessuna visita completata`}
      />
    </>
  )
}
