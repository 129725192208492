// Example uses styled-components, but styled-system works with most other css-in-js libraries as well
import styled from '@emotion/styled'
import {
  space,
  layout,
  typography,
  color,
  flex,
  flexGrow,
  flexbox,
  position,
  border,
  background,
  boxShadow,
  FlexboxProps,
  DisplayProps,
  TypographyProps,
  LayoutProps,
  MarginProps,
  SpaceProps,
  /*ColorProps,*/
  FlexProps,
  FlexGrowProps,
  PositionProps,
  BorderProps,
  BackgroundProps,
  BoxShadowProps,
} from 'styled-system'

export type BoxProps = FlexboxProps &
  DisplayProps &
  TypographyProps &
  LayoutProps &
  MarginProps &
  SpaceProps &
  FlexProps &
  FlexGrowProps &
  PositionProps &
  BorderProps &
  BackgroundProps &
  BoxShadowProps

// Add styled-system functions to your component
export const Box = styled.div<BoxProps>`
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${flex}
  ${flexGrow}
  ${flexbox}
  ${position}
  ${border}
  ${background}
  ${boxShadow}
`
