/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import { Spin } from 'antd'
import { css, jsx } from '@emotion/core'

const spinnerContainerClass = css`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`

interface IProps {
  fetchData?: Function
  onError?: Function
  isLoading?: boolean
  spinProps?: any
  minLoadingTime?: number
  loadingLabel?: ReactNode
}

export const Loader: React.FC<IProps> = ({
  children,
  fetchData,
  onError,
  isLoading,
  spinProps,
  minLoadingTime,
  loadingLabel,
}) => {
  const [loaded, setLoaded] = useState(!isLoading)
  const [error, setError] = useState()

  const onMount = async () => {
    if (fetchData) {
      try {
        setLoaded(false)
        await fetchData()
        setLoaded(true)
      } catch (err: any) {
        if (onError) {
          onError(err)
        }
        setError(err)
      }
    }
  }

  useEffect(() => {
    if (minLoadingTime && !isLoading) {
      const timeout = setTimeout(() => setLoaded(!isLoading), minLoadingTime)
      return () => clearTimeout(timeout)
    } else {
      setLoaded(!isLoading)
    }
  }, [isLoading])

  useEffect(() => {
    onMount()
  }, [fetchData, onError])

  return (
    <Fragment>
      {!loaded && !error && (
        <Fragment>
          <div css={spinnerContainerClass}>
            <Spin size="large" {...spinProps} />
            {loadingLabel}
          </div>
        </Fragment>
      )}
      {(loaded || error) &&
        (typeof children === 'function'
          ? children({
              loaded,
              error,
              isLoading: !loaded,
            })
          : children)}
    </Fragment>
  )
}

export default Loader
