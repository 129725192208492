import React, { Fragment, PropsWithChildren } from 'react'
import identity from 'lodash/identity'
import capitalize from 'lodash/capitalize'
import styled from '@emotion/styled'

export const formatName = (person?: Person | null) => {
  const { fullName: f, firstname, lastname, fiscalCode, email } = person || {}
  return (
    capitalize(f ?? '') ||
    [firstname, lastname]
      .filter(identity)
      .map((p) => p && capitalize(p))
      .join(' ') ||
    fiscalCode ||
    email
  )
}

interface Person {
  fullName?: string | null
  firstname?: string | null
  lastname?: string | null
  fiscalCode?: string | null
  email?: string | null
}

interface Props<T> {
  person: T | null
}

const Wrapper = styled.span({
  // textTransform: 'capitalize',
})

const Name = <T extends Person>({ person, children }: PropsWithChildren<Props<T>>) => {
  return (
    <Fragment>
      {children && <Fragment>{children} &nbsp;</Fragment>}
      <Wrapper>{formatName(person)}</Wrapper>
    </Fragment>
  )
}

export default Name
