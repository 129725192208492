/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext, memo } from 'react'
import { jsx } from '@emotion/core'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'

import MenuContext from '../../../features/MenuContext'

import style from './style'

const { Sider } = Layout

export const LeftMenu = React.memo(() => {
  const { topItem, path: activePath } = useContext(MenuContext)
  if (!topItem) return null

  const items =
    topItem?.items?.map((group: any) => {
      const items = (group.items || []).map((item: any) => {
        if (item.render) {
          return item.render(item)
        }

        return (
          <Menu.Item key={item.path}>
            {item.path ? (
              <Link to={item.path} title={item.title}>
                {item.title}
              </Link>
            ) : (
              item.title
            )}
          </Menu.Item>
        )
      })

      const renderGroup = () => {
        if (group.title) {
          return (
            <Menu.ItemGroup title={group.title} key={group.title}>
              {items}
            </Menu.ItemGroup>
          )
        }

        return items
      }

      return renderGroup()
    }) ?? []

  return (
    <Menu
      mode="inline"
      selectedKeys={[activePath || '']}
      style={{ height: '100%', borderRight: 0 }}
    >
      {items}
    </Menu>
  )
})

const LeftPanel: React.FC = memo((props: any) => {
  return (
    <Sider css={style}>
      <LeftMenu />
    </Sider>
  )
})

export default LeftPanel
