import React from 'react'
import { Global, ClassNames } from '@emotion/core'

import style from './style'

interface IProps {
  isOpen: boolean
}

const Hamburger: React.FC<IProps> = ({ isOpen }: IProps) => (
  <>
    <ClassNames>
      {({ cx }) => (
        <button
          className={cx('hamburger', 'hamburger--squeeze', {
            'is-active': isOpen,
          })}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      )}
    </ClassNames>
    <Global styles={style} />
  </>
)

export default Hamburger
