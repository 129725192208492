import React, { useCallback, useContext, useMemo, useRef } from 'react'
import UserOutlined from '@ant-design/icons/UserOutlined'
import { Menu, Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { Action } from '@gaudia/shared'
import { MobileMenuWrapper } from './MobileMenu.style'
import { UserDropdown } from '../UserDropdown/UserDropdown'
import MenuContext from '../../../features/MenuContext'
import { SubItemItem } from '../../../features/menuStructure'

const SubMenu = Menu.SubMenu

interface MobileTopItem {
  title: string
  path: string
  policies?: Action[]
  matches?: string[]
  items: SubItemItem[]
}

type MobileMenu = MobileTopItem[]

export const MobileMenu = () => {
  const { menu: items, isMobileMenuOpen, setMobileMenuOpen } = useContext(MenuContext)

  const closeMobileMenu = useCallback(() => setMobileMenuOpen(false), [setMobileMenuOpen])
  const handleItemClick = useCallback(() => closeMobileMenu(), [closeMobileMenu])

  const groupedItems = useMemo<MobileMenu>(() => {
    return items.map((topItem) => {
      const items = topItem.items?.reduce<SubItemItem[]>(
        (acc, groupItem) => [...acc, ...groupItem.items],
        [],
      )
      return {
        ...topItem,
        items,
      }
    })
  }, [items])

  const mainRef = useRef() as React.MutableRefObject<HTMLInputElement>

  if (!isMobileMenuOpen) return null

  return (
    <MobileMenuWrapper ref={mainRef}>
      <UserDropdown onClick={closeMobileMenu}>
        {({ Dropdown, username }) => (
          <Dropdown
            // @ts-ignore
            getPopupContainer={() => mainRef.current}
          >
            <a className="ant-dropdown-link">
              <section className="MobileMenu-top">
                <Avatar size="large" icon={<UserOutlined />} />
                <h2>{username}</h2>
              </section>
            </a>
          </Dropdown>
        )}
      </UserDropdown>

      {/*<UserDropdown>
        {({ Dropdown, username }) => (
          <Menu css={UserDropdownClass} theme="dark" mode="horizontal">
            <Menu.Item key={0} style={{ background: 'none !important' }}>
              <Dropdown>
                <a className="UserDropdown-userLink ant-dropdown-link">
                  <UserOutlined /> {username}
                </a>
              </Dropdown>
            </Menu.Item>
          </Menu>
        )}
      </UserDropdown>*/}

      <Menu selectable={false} theme="dark" /*selectedKeys={[selectedItemIndex]}*/ mode="inline">
        {groupedItems.map((topItem, i) => (
          <SubMenu key={i} title={topItem.title}>
            {topItem.items.map((item, index) => (
              <Menu.Item>
                {item.path || topItem.path ? (
                  <Link
                    to={{ ...item, pathname: item.path || topItem.path }}
                    onClick={handleItemClick}
                  >
                    {item.title}
                  </Link>
                ) : (
                  item.title
                )}
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </MobileMenuWrapper>
  )
}

/*export default compose(
  setDisplayName('MobileMenu'),
  pure,
  withStateHandlers(() => {}, {
    setRef: () => (ref) => ({
      refs: {
        main: ref,
      },
    }),
  }),
  connect(
    (state) => ({
      items: getMenu(state),
      activeTopItem: getActiveTopItem(state),
      me: select.agents.me(state),
      isMobileMenuOpen: select.layout.isMobileMenuOpen(state),
    }),
    (dispatch) => ({
      logout: dispatch.agents.logout,
      openMobileMenu: dispatch.layout.openMobileMenu,
      closeMobileMenu: dispatch.layout.closeMobileMenu,
    }),
  ),
  withPropsOnChange(['items'], ({ items }) => ({
    items: items.map((topItem) => ({
      ...topItem,
      items: (topItem.items || []).reduce((acc, groupItem) => [...acc, ...groupItem.items], []),
    })),
  })),
  withPropsOnChange(['me'], ({ me }) => ({
    username: _.get(me, 'me.username'),
  })),
  withHandlers({
    handleLogoutClick: (props) => () => {
      props.logout()
    },
    handleItemClick: (props) => () => {
      const { closeMobileMenu } = props
      return closeMobileMenu()
    },
  }),
)(MobileMenu)*/
