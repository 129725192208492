import React, { useCallback, useEffect, useMemo } from 'react'
import { Alert } from 'antd'
import Promise from 'bluebird'
import { Notification, useMutation, useRequest } from '../../../lib/graphql'
import { Box } from '../../../components/layout/Box'
import { UiSound } from '../../../lib/UiSound'
import { useAblyEvent } from '../../../components/ably/ably'
import { RelativeDate } from '../../../components/format/RelativeDate/RelativeDate'

interface Props {
  locationId: string
}

const NotificationEntry: React.FC<{ notification: Notification }> = ({ notification }) => {
  return (
    <>
      <RelativeDate label="" date={notification.createdAt} />
      {' - '}
      <>{notification?.message ?? 'Nessun messaggio'}</>
    </>
  )
}

export const LocationNotification: React.FC<Props> = ({ locationId }) => {
  // @ts-ignore
  const ably = useAblyEvent(
    [{ channelName: `locationQueue:${locationId}:notification` }],
    useCallback(() => ably.ensureRefetch(notificationQuery), []),
  )

  const notificationQuery = useRequest(
    locationId
      ? {
          notifications: [
            {
              where: {
                receivers: { receiverId: { EQ: locationId }, dismissedAt: { EXISTS: false } },
              },
              sort: 'createdAt DESC',
            },
            {
              items: {
                id: true,
                severity: true,
                senderId: true,
                message: true,
                visitor: { fullName: true, fiscalCode: true },
                scan: { date: true, temperature: true, wearMask: true },
                checkinKiosk: { name: true },
                createdAt: true,
              },
            },
          ],
        }
      : null,
    {
      enabled: locationId,
      refetchInterval: !ably?.isConnected && 2000,
      refetchOnWindowFocus: true,
    },
  )
  const notifications = useMemo(
    () => notificationQuery.data?.notifications?.items,
    [notificationQuery.data],
  )

  useEffect(() => {
    if (notifications?.length) {
      UiSound.playBeep()
    }
  }, [notifications])

  const markNotificationAsDismissedMutation = useMutation()
  const handleClose = useCallback(async () => {
    if (!notifications?.length) return

    await Promise.mapSeries(notifications, async (notification) => {
      return (
        notification?.id &&
        markNotificationAsDismissedMutation.mutate({
          markNotificationAsDismissed: [{ notificationId: notification.id }, { id: true }],
        })
      )
    })

    await notificationQuery.refetch()
  }, [markNotificationAsDismissedMutation.mutate, notificationQuery, notifications])

  const multiple = useMemo(
    () => notifications && notifications?.length > 1,
    [notifications?.length],
  )

  if (!notifications?.length) return null

  return (
    <Box margin="0 auto 2rem" width="100%" maxWidth={700}>
      <Alert
        message="È richiesto il tuo intervento"
        description={
          <ul style={{ padding: 0, margin: 0, listStyle: multiple ? 'initial' : 'none' }}>
            {notifications?.map(
              (notification) =>
                notification && (
                  <li>
                    <Box>
                      <NotificationEntry notification={notification} />
                    </Box>
                  </li>
                ),
            )}
          </ul>
        }
        type="warning"
        showIcon
        closable
        closeText="Segna come risolte"
        onClose={handleClose}
      />
    </Box>
  )
}
