import { useIdle } from 'react-use'
import { useEffect } from 'react'
import pino from 'pino'
import LogRocket from 'logrocket'
import { useInject, VersionNotificationStoreModel } from '../store'

const logger = pino({ browser: { asObject: true } }).child({ ns: 'useSmartUpdate' })

interface Props {
  idleTime?: number
}
export const useSmartUpdate = (options?: Props) => {
  const { hasUpdate, updateUI } = useInject<VersionNotificationStoreModel>(
    ({ versionNotificationStore }) => versionNotificationStore,
  )

  const FIVE_MINUTES = 5 * 60 * 1000
  const isIdle = useIdle(options?.idleTime ?? FIVE_MINUTES)

  useEffect(() => {
    if (isIdle && hasUpdate) {
      logger.info('Updating UI')
      LogRocket.captureMessage('Performing automatic refresh')
      updateUI()
    }
  }, [hasUpdate, isIdle, updateUI])
}
