import React from 'react'
import moment from 'moment'

interface Props {
  date: string | Date
  format?: string
}

const FormattedDate: React.FC<Props> = (props) => {
  return <>{moment(props.date).format(props.format || 'DD/MM/YYYY - HH:mm')}</>
}

export default FormattedDate
