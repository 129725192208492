import {
  Preference,
  PreferencePartialMutationInput,
  useMutation,
  useQueryCache,
  useRequest,
  JSON,
  UseQueryResult,
} from '../lib/graphql'

export const usePreference = <T = Record<string, unknown>>(
  name: string,
  entityIds?: Array<string>,
): Omit<UseQueryResult, 'data'> & {
  data?: (Omit<Preference, '__typename' | 'fields'> & { fields?: T }) | null
} => {
  const query = useRequest([
    `preference-${name}`,
    { getPreference: [{ args: { name, entityIds } }, { __scalar: true, fields: true }] },
  ])

  return {
    ...query,
    data: query.data?.getPreference,
  }
}

export const useUpdatePreference = <T = JSON>(
  name: string,
  defaultOpts?: { isDeltaUpdate: boolean },
) => {
  const mutation = useMutation()
  const queryCache = useQueryCache()

  return async (
    data: PreferencePartialMutationInput & { fields?: T },
    opts: { isDeltaUpdate: boolean } = { isDeltaUpdate: true },
  ) => {
    const options = { ...defaultOpts, ...opts }

    const result = await mutation.mutate({
      updatePreference: [
        { name, data, isDeltaUpdate: options.isDeltaUpdate },
        { name: true, fields: true },
      ],
    })

    await queryCache.invalidateQueries(`preference-${name}`)

    return result
  }
}
