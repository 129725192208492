import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Card as AntdCard, Col, Form, Row, Select } from 'antd'
import styled from '@emotion/styled'
import _ from 'lodash'
import moment from 'moment'
import DefaultLayout from '../../../components/layout/DefaultLayout'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useRequest } from '../../../lib/graphql'
import Loader from '../../../components/Loader'
import { Box } from '../../../components/layout/Box'

const timePeriods = [
  { label: 'Oggi', value: 'today' },
  { label: 'Settimana Corrente', value: 'lastWeek' },
  { label: 'Ultimi 7 giorni', value: 'last7Days' },
  { label: 'Mese Corrente', value: 'lastMonth' },
  { label: 'Ultimi 30 giorni', value: 'last30Days' },
  { label: 'Anno corrente', value: 'lastYear' },
] as const

type TimePeriod = (typeof timePeriods)[number]['value']

const defaultFillColor = '#1890ff'

const Card = styled(AntdCard)`
  & .ant-card-body {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    &:after {
      display: block;
      content: '';
      padding-bottom: 56%;
    }
  }
`

const CardCol = styled(Col)`
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    max-width: 50%;
    width: 50%;
  }
`

const CenteredBox: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Box
    width="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </Box>
)

const Analytics: React.FC<{}> = observer(() => {
  const [timePeriod, setTimePeriod] = useState<TimePeriod>('today')
  /**
   * Visits Count
   */
  const [visitCountsGrouping, setVisitCountsGrouping] = useState<Array<string>>(['locationId'])
  const analyticsVisitsCountQuery = useRequest(
    {
      analyticsVisitsCount: [
        { params: { timePeriod, groupBy: visitCountsGrouping } },
        {
          data: {
            count: true,
            date: true,
            location: { name: true, color: true },
            startedBy: { fullName: true, email: true },
          },
        },
      ],
    },
    { refetchInterval: 120 * 1000 },
  )

  const analyticsVisitsCountData = useMemo(
    () => analyticsVisitsCountQuery?.data?.analyticsVisitsCount?.data ?? [],
    [analyticsVisitsCountQuery?.data?.analyticsVisitsCount?.data],
  )
  const analyticsVisitsCountDataFormatted = useMemo(() => {
    const groupedByDate = _.groupBy(analyticsVisitsCountData, 'date')

    // Map over each group and transform to desired format
    return _.map(groupedByDate, (entries, date) => {
      // Reduce each group into a single object with each location as a key
      return _.reduce(
        entries,
        (acc: Record<string, unknown>, item) => {
          let key = 'count'
          if (visitCountsGrouping.includes('locationId')) {
            key = item?.location?.name!
          }

          acc[key] = item?.count

          return acc
        },
        { date: date },
      ) // Initialize with the date
    })
  }, [analyticsVisitsCountData, visitCountsGrouping])

  const tickFormatter = (date: string) => {
    if (timePeriod === 'today') {
      return moment(date).format('HH:mm')
    }

    if (timePeriod === 'lastWeek' || timePeriod === 'last7Days') {
      return moment(date).format('ddd, D MMM')
    }

    if (timePeriod === 'lastMonth' || timePeriod === 'last30Days') {
      // Ensure the date is a moment object
      const startOfWeek = moment(date)

      // Calculate the end of the week by adding 6 days to the start date
      const endOfWeek = moment(startOfWeek).add(6, 'days')

      // Format dates to a readable form, e.g., "December 1, 2022"
      const isDifferentMonth = startOfWeek.get('month') !== endOfWeek.get('month')
      const startDay = `${startOfWeek.format('D')} ${
        isDifferentMonth ? startOfWeek.format('MMM') : ''
      }`
      const endDay = `${endOfWeek.format('D')} ${endOfWeek.format('MMM')}`

      return `${startDay} - ${endDay}`
    }

    return moment(date).format('MMM YY')
  }

  const xAxisLabel = useMemo(() => {
    if (timePeriod === 'today') {
      return 'Ore del giorno'
    }

    if (timePeriod === 'lastWeek' || timePeriod === 'last7Days') {
      return 'Giorni'
    }

    if (timePeriod === 'lastMonth' || timePeriod === 'last30Days') {
      return 'Settimane'
    }

    return 'Mesi'
  }, [timePeriod])

  /**
   * Visits Duration
   */
  const [visitDurationsGrouping, setVisitDurationsGrouping] = useState<Array<string>>([
    'locationId',
  ])
  const analyticsVisitsDurationQuery = useRequest(
    {
      analyticsVisitsDuration: [
        { params: { timePeriod, groupBy: visitDurationsGrouping } },
        {
          data: {
            durationAvgSecs: true,
            durationAvgMins: true,
            location: { name: true, color: true },
            startedBy: { fullName: true, email: true },
          },
        },
      ],
    },
    { refetchInterval: 120 * 1000 },
  )

  const analyticsVisitsDurationQueryData = useMemo(
    () => analyticsVisitsDurationQuery?.data?.analyticsVisitsDuration?.data ?? [],
    [analyticsVisitsDurationQuery?.data?.analyticsVisitsDuration?.data],
  )
  const analyticsVisitsDurationQueryDataFormatted = useMemo(
    () =>
      _.orderBy(
        _.map(analyticsVisitsDurationQueryData, (item) => ({
          name: _.compact([
            item?.location?.name,
            item?.startedBy?.fullName || item?.startedBy?.email,
          ]).join(' - '), // Use location name as the label
          durationAvgMins: item?.durationAvgMins, // Average duration in minutes
          durationAvgSecs: item?.durationAvgSecs,
          color: item?.location?.color!,
        })),
        ['name'],
        ['asc'],
      ),
    [analyticsVisitsDurationQueryData],
  )

  /**
   * Visits Waiting time
   */
  const [visitWaitingTimesGrouping, setVisitWaitingTimesGrouping] = useState<Array<string>>([
    'locationId',
  ])
  const analyticsVisitsWaitingTimeQuery = useRequest(
    {
      analyticsVisitsWaitingTime: [
        { params: { timePeriod, groupBy: visitWaitingTimesGrouping } },
        {
          data: {
            waitingTimeAvgSecs: true,
            waitingTimeAvgMins: true,
            location: { name: true, color: true },
            startedBy: { fullName: true, email: true },
          },
        },
      ],
    },
    { refetchInterval: 120 * 1000 },
  )

  const analyticsVisitsWaitingTimeQueryData = useMemo(
    () => analyticsVisitsWaitingTimeQuery?.data?.analyticsVisitsWaitingTime?.data ?? [],
    [analyticsVisitsWaitingTimeQuery?.data?.analyticsVisitsWaitingTime?.data],
  )
  const analyticsVisitsWaitingTimeQueryDataFormatted = useMemo(
    () =>
      _.orderBy(
        _.map(analyticsVisitsWaitingTimeQueryData, (item) => ({
          name: _.compact([
            item?.location?.name,
            item?.startedBy?.fullName || item?.startedBy?.email,
          ]).join(' - '), // Use location name as the label
          durationAvgMins: item?.waitingTimeAvgMins, // Average duration in minutes
          durationAvgSecs: item?.waitingTimeAvgSecs,
          color: item?.location?.color!,
        })),
        ['name'],
        ['asc'],
      ),
    [analyticsVisitsWaitingTimeQueryData],
  )

  return (
    <DefaultLayout>
      <Box p={[12, 0]}>
        <Box mb={3}>
          <Form layout="inline">
            <Form.Item label="Periodo">
              <Select style={{ minWidth: 200 }} value={timePeriod} onSelect={setTimePeriod}>
                {timePeriods.map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Box>
        {/*analyticsVisitsCountDataFormatted:*/}
        {/*{JSON.stringify(analyticsVisitsCountDataFormatted, null, 4)}*/}
        {/*<br />*/}
        <Row gutter={20}>
          <CardCol>
            <Card
              title={
                <>
                  <h1>Afflusso visite</h1>
                  <Box fontWeight="normal">
                    <Form layout="inline">
                      <Form.Item label="Raggruppa per">
                        <Select
                          value={visitCountsGrouping}
                          onChange={setVisitCountsGrouping}
                          mode="multiple"
                          allowClear
                          style={{ minWidth: 200 }}
                        >
                          <Select.Option value="locationId">Location</Select.Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </Box>
                </>
              }
            >
              {/*{JSON.stringify(analyticsVisitsCountDataFormatted, null, 4)}*/}
              <Loader isLoading={analyticsVisitsCountQuery.isLoading}>
                {analyticsVisitsCountData.length === 0 && (
                  <CenteredBox>
                    <Box fontSize="4">Nessun dato disponibile</Box>
                  </CenteredBox>
                )}
                {analyticsVisitsCountData.length > 0 && (
                  <ResponsiveContainer width="100%" aspect={2}>
                    <BarChart
                      data={analyticsVisitsCountDataFormatted}
                      margin={{
                        top: 0,
                        right: 30,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={tickFormatter}>
                        <Label position="bottom" value={xAxisLabel} />
                      </XAxis>
                      <YAxis>
                        <Label
                          style={{ textAnchor: 'middle', left: -10 }}
                          angle={-90}
                          dx={-15}
                          value="Totale Visite"
                        />
                      </YAxis>
                      <Tooltip cursor={{ fill: 'whiteSmoke' }} />
                      <Legend
                        verticalAlign="top"
                        wrapperStyle={{
                          paddingBottom: 10,
                        }}
                      />

                      {analyticsVisitsCountDataFormatted.length > 0 &&
                        Object.keys(_.reduce(analyticsVisitsCountDataFormatted, _.merge, {}))
                          .filter((key) => key !== 'date')
                          .map((key, index) => (
                            <Bar
                              key={index}
                              dataKey={key}
                              fill={
                                analyticsVisitsCountData.find((d) => d?.location?.name === key)
                                  ?.location?.color ?? defaultFillColor
                              }
                            />
                          ))}
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Loader>
            </Card>
          </CardCol>

          <CardCol>
            <Card
              title={
                <>
                  <h1>Durata media visite</h1>
                  <Box fontWeight="normal">
                    <Form layout="inline">
                      <Form.Item label="Raggruppa per">
                        <Select
                          value={visitDurationsGrouping}
                          onChange={setVisitDurationsGrouping}
                          mode="multiple"
                          allowClear
                          style={{ minWidth: 200 }}
                        >
                          <Select.Option value="locationId">Location</Select.Option>
                          <Select.Option value="startedById">Utente chiamante</Select.Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </Box>
                </>
              }
            >
              <Loader isLoading={analyticsVisitsDurationQuery.isLoading}>
                {analyticsVisitsDurationQueryData.length === 0 && (
                  <CenteredBox>
                    <Box fontSize="4">Nessun dato disponibile</Box>
                  </CenteredBox>
                )}
                {analyticsVisitsDurationQueryData.length > 0 && (
                  <>
                    {visitDurationsGrouping.length > 0 ? (
                      <ResponsiveContainer width="100%" aspect={2}>
                        <BarChart
                          data={analyticsVisitsDurationQueryDataFormatted}
                          margin={{
                            top: 0,
                            right: 30,
                            bottom: 5,
                            left: visitDurationsGrouping.length >= 2 ? 100 : 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="name"
                            interval={0}
                            {...(visitDurationsGrouping.length && {
                              angle: -45, // Rotate labels by -45 degrees
                              textAnchor: 'end', // Anchor text at the end to align properly after rotation
                              height: visitDurationsGrouping.length >= 2 ? 170 : 100, // Increase height to accommodate rotated labels
                            })}
                          />
                          <YAxis dataKey="durationAvgMins">
                            <Label
                              style={{ textAnchor: 'middle', left: -10 }}
                              angle={-90}
                              dx={-15}
                              value="Durata media (minuti)"
                            />
                          </YAxis>
                          <Tooltip cursor={{ fill: 'whiteSmoke' }} />
                          <Legend
                            verticalAlign="top"
                            wrapperStyle={{
                              paddingBottom: 10,
                            }}
                          />

                          <Bar
                            dataKey="durationAvgMins"
                            name="Durata media (minuti)"
                            fill={defaultFillColor}
                          >
                            {analyticsVisitsDurationQueryDataFormatted.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color ?? defaultFillColor} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <CenteredBox>
                        <Box fontSize="6" fontWeight="bold">
                          {analyticsVisitsDurationQueryData?.[0]?.durationAvgMins} minuti
                        </Box>
                        <Box fontSize="3">
                          ({analyticsVisitsDurationQueryData?.[0]?.durationAvgSecs} secondi)
                        </Box>
                      </CenteredBox>
                    )}
                  </>
                )}
              </Loader>
            </Card>
          </CardCol>

          <CardCol>
            <Card
              title={
                <>
                  <h1>Attesa media</h1>
                  <Box fontWeight="normal">
                    <Form layout="inline">
                      <Form.Item label="Raggruppa per">
                        <Select
                          value={visitWaitingTimesGrouping}
                          onChange={setVisitWaitingTimesGrouping}
                          mode="multiple"
                          allowClear
                          style={{ minWidth: 200 }}
                        >
                          <Select.Option value="locationId">Location</Select.Option>
                          {/*<Select.Option value="startedById">Utente chiamante</Select.Option>*/}
                        </Select>
                      </Form.Item>
                    </Form>
                  </Box>
                </>
              }
            >
              <Loader isLoading={analyticsVisitsWaitingTimeQuery.isLoading}>
                {analyticsVisitsWaitingTimeQueryData.length === 0 && (
                  <CenteredBox>
                    <Box fontSize="4">Nessun dato disponibile</Box>
                  </CenteredBox>
                )}
                {analyticsVisitsWaitingTimeQueryData.length > 0 && (
                  <>
                    {visitWaitingTimesGrouping.length > 0 ? (
                      <ResponsiveContainer width="100%" aspect={2}>
                        <BarChart
                          data={analyticsVisitsWaitingTimeQueryDataFormatted}
                          margin={{
                            top: 0,
                            right: 30,
                            bottom: 5,
                            left: visitWaitingTimesGrouping.length >= 2 ? 100 : 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="name"
                            interval={0}
                            {...(visitWaitingTimesGrouping.length && {
                              angle: -45, // Rotate labels by -45 degrees
                              textAnchor: 'end', // Anchor text at the end to align properly after rotation
                              height: visitWaitingTimesGrouping.length >= 2 ? 170 : 100, // Increase height to accommodate rotated labels
                            })}
                          />
                          <YAxis>
                            <Label
                              style={{ textAnchor: 'middle', left: -10 }}
                              angle={-90}
                              dx={-10}
                              value="Attesa media (minuti)"
                            />
                          </YAxis>
                          <Tooltip cursor={{ fill: 'whiteSmoke' }} />
                          <Legend
                            verticalAlign="top"
                            wrapperStyle={{
                              paddingBottom: 10,
                            }}
                          />

                          <Bar
                            dataKey="durationAvgMins"
                            name="Attesa media (minuti)"
                            fill={defaultFillColor}
                          >
                            {analyticsVisitsWaitingTimeQueryDataFormatted.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color ?? defaultFillColor} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <CenteredBox>
                        <Box fontSize="6" fontWeight="bold">
                          {analyticsVisitsWaitingTimeQueryData?.[0]?.waitingTimeAvgMins} minuti
                        </Box>
                        <Box fontSize="3">
                          ({analyticsVisitsWaitingTimeQueryData?.[0]?.waitingTimeAvgSecs} secondi)
                        </Box>
                      </CenteredBox>
                    )}
                  </>
                )}
              </Loader>
            </Card>
          </CardCol>
        </Row>
      </Box>
    </DefaultLayout>
  )
})

export default Analytics
